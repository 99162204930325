// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import{getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


const firebaseConfig = {
    apiKey: "AIzaSyBXYN1egmlIfuPVoTY25pqOJg1jevfo1T4",
    authDomain: "bhartiyaaviationservices-3e814.firebaseapp.com",
    projectId: "bhartiyaaviationservices-3e814",
    storageBucket: "bhartiyaaviationservices-3e814.appspot.com",
    messagingSenderId: "854909605642",
    appId: "1:854909605642:web:18492abc1297dd61430cf3",
    measurementId: "G-QW73SKS41H"
  };


  const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);