import React from 'react'
import { MdDelete } from "react-icons/md";
import axios from 'axios'
import { useEffect } from 'react';
import { useState } from 'react';
import BannerSlider from './Features/BannerSlider'

const DBhomeComp = () => {

  const [postData, setPostDara] = useState([])
  const [latestAlerts, setLatestAlerts] = useState([])
  const [msg, setMsg] = useState('')
  const [alertMsg, setAlertMsg] = useState('')
  const [loading, setLoading] = useState(false)
  

const deletePostHandler = async(e)=>{
  e.preventDefault()
  try{
    const listId = e.currentTarget.id
    const res = await axios.delete(`${process.env.REACT_APP_HOSTURL}/api/v1/post/${listId}`)
    const deletedProfiles = postData.filter((d) => d._id !== listId)
     setPostDara(deletedProfiles)
     setMsg(res.data.message)
  }catch(err){
    //console.log(err);
  }
}


const deleteLatestAlertsHandler = async(e)=>{
  e.preventDefault()
  try{
   const listId = e.currentTarget.id
   const res = await axios.delete(`${process.env.REACT_APP_HOSTURL}/api/v1/latestupdate/${listId}`)
   //console.log(res.data.message);
   setAlertMsg(res.data.message)
   const deletedAlertsArr = latestAlerts.filter((d)=> d._id !== listId)
   setLatestAlerts(deletedAlertsArr)
  }catch(err){
    //console.log(err);
  }
}

  useEffect(()=>{
    const fetchAllPosts = async()=>{
      setLoading(true)
      try{
          const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/post`)
          setPostDara(res.data.body)
          setLoading(false)
      }catch(err){
        //console.log(err);
      }
    }

    const fetchAllAlerts  = async()=>{
      try{
        const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/latestupdate`)
        setLatestAlerts(res.data.latestData.list)
      }catch(err){
        //console.log(err);
      }
    }
    fetchAllAlerts()
    fetchAllPosts()
  },[])

  return (
    <>
    <div className='right-side-page-component restpage-secondary-bg'>
          <div className='container job-post-alert-main-div'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='job-post-inner-db-left-dv shadow-sm m-4' >
                   <div className='d-flex align-items-center justify-content-between'>
                   <h3 className='text-muted'>Job Profiles</h3>
                   <p className='fw-bold text-secondary'>{msg}</p>
                   </div>
                   {loading ? (
                    <>
                   <div className='d-flex align-items-center justify-content-center flex-column'>
                    <div className="loader"></div>
                            <p>Loading...</p>
                    </div>
                    </>
                   ) : (
                    <>
                    <table className="table table-bordered" style={{backgroundColor: 'white'}}>
                      <thead>
                        <tr>
                          <th scope="col">S.no</th>
                          <th scope="col">Profile Name</th>
                          <th scope="col">Advt No.</th>
                          <th scope="col">Fee</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          postData?.map((d,i)=>{
                            return(
                              <tr key={i}>
                              <th scope="row">{i}</th>
                              <td>{d.name}</td>
                              <td>{d.advTitle}</td>
                              <td>{`₹${d.price}`}</td>
                              <td className='text-center'><MdDelete style={{cursor: 'pointer'}} className='text-danger fs-3' id={d._id} onClick={deletePostHandler}/></td>
                            </tr>
                            )
                          })
                        }
                       
                      </tbody>
                    </table>
                    </>
                   )}
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='alerts-post-inner-db-right-div m-4'>
                    <div className='d-flex align-items-center justify-content-between'>
                  <h3 className='text-muted'>Update & Alerts</h3>
                  <p className='fw-bold text-secondary'>{alertMsg}</p>
                    </div>
                  <div style={{height: '400px' , overflowY: 'scroll'}}>

              
                    {
                      latestAlerts?.map((d,i)=>{
                        return (
                          <div className="list-group border mb-3  p-2 shadow-sm" key={i} style={{backgroundColor: 'white'}}>
                          <div className="d-flex w-100 justify-content-between">
                            
                            <small className="text-muted">{new Date(d.createdAt).toDateString()}</small>
                          </div>
                          <p className="mb-1">{d.list}</p>
                          <small className="text-muted fs-2"><MdDelete className='text-danger'  style={{cursor: 'pointer'}} id={d._id} onClick={deleteLatestAlertsHandler}/></small>
                        </div>
                        )
                      })
                    }
                          </div>
                  </div>
                </div>
              </div>
          </div>

          <div className='container'>
              <BannerSlider/>
          </div>
    </div>
    </>
  )
}

export default DBhomeComp