import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import '../styles/componentPage.css'

const Tamilnadu = () => {
  return (
    <>
    <Helmet> 
  <title>Best Airline Careers in Tamilnadu | Bhartiya Aviation Services</title>
  <meta charSet='UTF-8'/>
    <meta name="keywords" content="Best Airline Careers in Tamilnadu, Best aviation job in Tamilnadu"/>
    <meta name="description" content="Do you dream to get a job in the aviation industry? Bhartiya Aviation Services offers the Best aviation job in Tamilnadu. Call now at 8447-58-8447"/>
    <meta name="author" content="Bhartiya Aviation Services"/>
    <meta name="refresh" content="50"/>
    <meta property="og:image" content="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <meta http-equi="refresh" content="" url="https://bhartiyaaviation.in/airline-careers-and-airport-job-in-tamilnadu"/>
    <meta property="og:type" content="website"/>
<meta property="og:url" content="https://bhartiyaaviation.in/airline-careers-and-airport-job-in-tamilnadu"/>
<meta property="og:title" content="Best Airline Careers in Tamilnadu |Bhartiya Aviation Services"/>
<meta property="og:description" content="Do you dream to get a job in the aviation industry? Bhartiya Aviation Services offers the Best aviation job in Tamilnadu. Call now at 8447-58-8447"/>
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>


<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://bhartiyaaviation.in/airline-careers-and-airport-job-in-tamilnadu"/>
<meta property="twitter:title" content="Best Airline Careers in Tamilnadu |Bhartiya Aviation Services"/>
<meta property="twitter:description" content="Do you dream to get a job in the aviation industry? Bhartiya Aviation Services offers the Best aviation job in Tamilnadu. Call now at 8447-58-8447"/>
<meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon_package_v0.16/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon_package_v0.16/favicon-16x16.png"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" href="/public/logo192.png" />
    <link rel='canonical' href='https://bhartiyaaviation.in/airline-careers-and-airport-job-in-tamilnadu' />
</Helmet>

<div className='container-fluid  top-banner-pg-div-about-us'>
      <div className='inner-pg-wrapper-div'>
          <div className='container ot-page-banner-heading'>
              <h1>Tamil Nadu</h1>
              <Link to={'/'}>
              <p>
               Home/ tamil nadu
               </p>
              </Link>
          </div>
      </div>
  </div>

  <div className='container'>
     <div className='green-field-img-div'>
         <img src="/images/tamilnadu.png" alt="" />
     </div>
     <div className='green-field-text'>
         
         <h2 className='text-center mt-4 mb-4'><u>How was Chennai International Airport built?</u></h2>

         <p>In 1910, Giacomo D'Angelis, a Corsican hotelier in the city, built an aircraft and tested it on the island grounds in India. Inspired by Frenchman Louis Bleriot's feat of flying across the English Channel in July 1909, he began to build his own plane. The creation of a biplane was a joint effort between Angelis and Simpson, a significant coachbuilder in the city. All of D'Angelis' original designs went into the construction of the biplane—a smaller engine powered it; all three men worked together to build it. On March 10 of that year in Pallavaram—a neighborhood near Chennai's city center—D'Angelis tested his new craft for the first time.</p>

             <br/>

         <p>In 1911, a group of aviators arrived in India to demonstrate their aircraft. One of those who flew was Jules Tyke. He wore an oilskin coat and goggles when eight people shot the aircraft down. The men controlled the aircraft until it rose off the ground and then let go; it moved forward by about 20 yards before taking off into the air. Only about three-quarters of the length of the ground were covered by its takeoff and landing in the air. The young child took to the air once more on February 18; this time reaching a height of 2,400 feet, which was seen by Sir Arthur Lawley, governor at that time.</p>

         <br/>
         <p>The city of Chennai, in southern India, is served by Chennai International Airport, a major international airport. It may be found in Tirusulam, which is 21 kilometers from the city center. The third busiest airport for foreign traffic as well as the sixth busiest overall is Chennai International Airport. It was also among the four largest airports in India in the 2018 Top 50 list, ranking as the 49th busiest airport in Asia. During the fiscal year 2021 alone, 9.53 million people used this airport for its services.</p>
         <br/>
         <p>
         The Chennai suburban railway system's Tirusulam railway station and the airport's metro station both provide access to the airport. Two new terminals, including one with a capacity of 40 million passengers annually, are being built to handle passenger demand. Once finished, it will have a satellite terminal, making it India's first airport to do so. For passenger circulation between the terminals, a four-way underground walker will connect the new satellite terminal. However, with a 40 million passenger capacity by 2035, the Tirusulam airport complex is predicted to become overcrowded by then. Plans for a new greenfield airport in Chennai have been in the works since at least 2007.
         </p>
        
     </div>
 </div>
 
 </>
  )
}

export default Tamilnadu