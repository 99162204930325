import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Sidebar from './sidebar/Sidebar';
import DBhomeComp from './adminComponents/DBhomeComp';
import DBheaderTopbar from './adminComponents/DBheaderTopbar';
import { useSelector } from 'react-redux';

const Admin = () => {
  const adminBasData =  useSelector((state)=> state.basAdminDetail)
  const navigate = useNavigate();

  // console.log(adminBasData)
  // console.log(adminBasData.basAdminDetail)
 
  const getAdminDetails = () =>{
  if(!adminBasData || adminBasData === null || adminBasData.basAdminDetail
    ===  null){
    navigate('/admin/login')
  }else{
  navigate('/basadmincontrol')
  }
}

useEffect(()=>{
  getAdminDetails()
    // eslint-disable-next-line
},[])
  return (
    <>
    <DBheaderTopbar/>
      <div className='container-fluid main-page-container'>
       <Sidebar/>
       <DBhomeComp/>
      </div>
    </>
  )
}

export default Admin