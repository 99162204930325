import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

import '../styles/candidatedb.css'


const CandidateDashboard = () => {

  const loggedInUser  = useSelector((state)=> state.user)
   //console.log(loggedInUser.user.id)

  const [data,setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getSingleFromData = (e)=>{
    e.preventDefault()
    //console.log(e.target.id);

    let listId = e.target.id

    //!encrypt URL
    const encryptedId = encodeURIComponent(CryptoJS.AES.encrypt(listId, 'cvsdv2312v323ffvwerv242v2v').toString());


    if(listId){
            const id = listId
            navigate(`/myapplication/${encryptedId}`,{
                state:{
                id:id
                }
            });
           }
          // console.log('list-ID', listId);
   
}





  const getData = async ()=>{
    setLoading(true)
    await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/userdash/${loggedInUser.user.id}`).then(res=>{
      // console.log(res.data.response);
       setData(res.data.response);
       setLoading(false);
       }).catch(err=>{
           //console.log("error",err);
       }) 
     }
 useEffect(()=>{
   getData()
    // eslint-disable-next-line
},[]);

  return (
    <>
    <div className='candidate-main-div restpage-secondary-bg'>
        <div className='container'>
              <div className='candidate-db-title-div'>
                <h3>Candidate Dashboard</h3>
              </div>
            {
              loading ? (<>
                  <div className='text-center'>
                      <div className="spinner-border text-success" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className='fw-bold text-muted'>Loading...</p>
                      </div>
              </>): (
              <>
              <div className='candidate-inner-db'>
            <table className="table table-hover table-bordered">
                <thead>
                  <tr className='candidate-db-table-heading '>
                    <th scope="col">Reg No.</th>
                    <th scope="col">Candidate Name</th>
                    <th scope="col">Father/Husband Name</th>
                    <th scope="col">Date Of Birth</th>
                    <th scope="col">Apply For</th>
                    <th>Payment Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                {
                  data?.map((d,i)=>{
                    return(
                      <tr key={i}>
                      <th scope="row">{d.registrationNumber}</th>
                      <td>{d.candidatename}</td>
                      <td>{d.fatherhusbandname}</td>
                      <td>{d.dateofbirth}</td>
                      <td>{d.postapply}</td>
                      <td>{d.paymentStatus}</td>
                      <td><button className='btn btn-warning' onClick={getSingleFromData} id={d._id}>View</button></td>
                    </tr>
                    )
                  })
                }
                </tbody>
              </table>
            </div>
              </>)
            }
        </div>
    </div>
    </>
  )
}

export default CandidateDashboard