import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import '../styles/componentPage.css'

const Hyderabad = () => {
  return (
    <>
       <Helmet> 
     <title>Best Air India Careers In hyderabad | Bhartiya Aviation Services</title>
     <meta charSet='UTF-8'/>
       <meta name="keywords" content="best Air India Careers in hyderabad, Top Airport Jobs in Hyderabad"/>
       <meta name="description" content="Make your career in the aviation industry by getting top airport jobs in Hyderabad. Join Bhartiya Aviation Services or call at 8447-58-8447"/>
       <meta name="author" content="Bhartiya Aviation Services"/>
       <meta name="refresh" content="50"/>
       <meta property="og:image" content="/public/favicon_package_v0.16/apple-touch-icon.png"/>
       <meta http-equi="refresh" content="" url="https://bhartiyaaviation.in/air-india-careers-and-airport-jobs-in-hyderabad"/>
       <meta property="og:type" content="website"/>
<meta property="og:url" content="https://bhartiyaaviation.in/air-india-careers-and-airport-jobs-in-hyderabad"/>
<meta property="og:title" content="Best Air India Careers In hyderabad | Bhartiya Aviation Services"/>
<meta property="og:description" content="Make your career in the aviation industry by getting top airport jobs in Hyderabad. Join Bhartiya Aviation Services or call at 8447-58-8447"/>
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>


<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://bhartiyaaviation.in/air-india-careers-and-airport-jobs-in-hyderabad"/>
<meta property="twitter:title" content="Best Air India Careers In hyderabad | Bhartiya Aviation Services"/>
<meta property="twitter:description" content="Make your career in the aviation industry by getting top airport jobs in Hyderabad. Join Bhartiya Aviation Services or call at 8447-58-8447"/>
<meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon_package_v0.16/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon_package_v0.16/favicon-16x16.png"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" href="/public/logo192.png" />
       <link rel='canonical' href='https://bhartiyaaviation.in/air-india-careers-and-airport-jobs-in-hyderabad' />
   </Helmet>

   <div className='container-fluid  top-banner-pg-div-about-us'>
         <div className='inner-pg-wrapper-div'>
             <div className='container ot-page-banner-heading'>
                 <h1>Hyderabad</h1>
                 <Link to={'/'}>
                 <p>
                  Home/ hyderabad
                  </p>
                 </Link>
             </div>
         </div>
     </div>

     <div className='container'>
        <div className='green-field-img-div'>
            <img src="/images/hyderabad.png" alt="" />
        </div>
        <div className='green-field-text'>
            
            <h2 className='text-center mt-4 mb-4'><u>A Journey of Rajiv Gandhi International Airport was established.
</u></h2>

            <p>Begumpet Airport, which is currently serving as a commercial airport, was overwhelmed by the rising passenger volume. Initially contemplating turning the Hakimpet Air Force Station into a civilian facility, the state administration led by the late Chief Minister N. Chandrababu Naidu rejected the idea. The Defense Ministry recommended that the state takes into account locations south of Begumpet airport when the state proposed building a new airport for the Air Force. The state had chosen Bonglur, Nadargul, and Shamshabad as the final three potential sites for the new airport as of October 1998. In December 1998, Shamshabad was chosen due to its practical location close to two motorways and a railway line.</p>

                <br/>

            <p>Hyderabad International Airport Limited (HIAL), later known as GMR Hyderabad International Airport Limited (GHIAL), was established as a special purpose corporation in December 2002 with the participation of the state, the AIRPORTS AUTHORITY OF INDIA, and GMR-MAHB.</p>

            <br/>
            <p>Members of the GHIAL signed a shareholders agreement and a contract for about 4 billion ($50 million) in state subsidies in September 2003. The central government and GHIAL inked a concession agreement in December 2004 that said no airports could be operated within 150 kilometres of Rajiv Gandhi International Airport. The Begumpet airport had to be shut down as a result.</p>
            <br/>
            <p>
            Hyderabad, the state capital of Telangana in India, is served by the international airport known as Rajiv Gandhi International Airport (RGIA). It can be found in Shamshabad, which is 24 kilometers south of Hyderabad. On March 23, 2008, it was opened, taking the position of Begumpet Airport, Hyderabad's sole commercial airport. It bears Rajiv Gandhi's name, a former Indian prime minister. The airport was built on a 5,495-acre plot. It ranks as India's busiest and most modern airport, handling around 11 million passengers per year from 2021 to March 2022.
            </p>
           
        </div>
    </div>
    
    </>
  )
}

export default Hyderabad