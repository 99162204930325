import React from 'react'
import { Link } from 'react-router-dom';

const Howtoapply = () => {
  return (
   <>
     <div className='container-fluid  top-banner-pg-div-about-us'>
         <div className='inner-pg-wrapper-div'>
             <div className='container ot-page-banner-heading'>
                 <h1>How To Apply (Video)</h1>
                 <Link to={'/'}>
                 <p>
                  Home/ how to apply
                  </p>
                 </Link>
             </div>
         </div>
     </div>

    <div className="ratio ratio-16x9">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/3rR2lgqAHMM?si=qyWd8UpC17lyAwGC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>
   

   </>
  )
}

export default Howtoapply