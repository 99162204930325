import React from 'react'
import { lazy, Suspense } from 'react';

//import AboutComponent from '../components/pages/AboutComponent'

const AboutComp = lazy(()=> import('../components/pages/AboutComponent'))

const About = () => {
  return (
   <>
   <Suspense fallback={
   <div className='text-center'>
    <div className="spinner-grow" style={{width: '3rem', height: '3rem', color: 'brown'}} role="status">
    <span className="visually-hidden text-success">Loading...</span>
    
  </div>
   </div>
  }>
   <AboutComp/>
   </Suspense>
   </>
  )
}

export default About