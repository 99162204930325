import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import '../styles/componentPage.css'

const Punjab = () => {
  return (
    <>
       <Helmet> 
     <title>Best Indigo Careers in Punjab | Bhartiya Aviation Services</title>
     <meta charSet='UTF-8'/>
       <meta name="keywords" content="Best Indigo Careers in Punjab, Airport Jobs in Punjab"/>
       <meta name="description" content="Are you worried about your career? then you do not have to worry because  Bhartiya Aviation Services offers Airport Jobs in Punjab. Call now at 8447-79-8447"/>
       <meta name="author" content="Bhartiya Aviation Services"/>
       <meta name="refresh" content="50"/>
       <meta property="og:image" content="/public/favicon_package_v0.16/apple-touch-icon.png"/>
       <meta http-equi="refresh" content="" url="https://bhartiyaaviation.in/indigo-careers-and-airport-job-in-punjab"/>
       <meta property="og:type" content="website"/>
<meta property="og:url" content="https://bhartiyaaviation.in/indigo-careers-and-airport-job-in-punjab"/>
<meta property="og:title" content="Best Indigo Careers in Punjab | Bhartiya Aviation Services"/>
<meta property="og:description" content="Are you worried about your career? then you do not have to worry because  Bhartiya Aviation Services offers Airport Jobs in Punjab. Call now at 8447-79-8447"/>
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>


<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://bhartiyaaviation.in/indigo-careers-and-airport-job-in-punjab"/>
<meta property="twitter:title" content="Best Indigo Careers in Punjab | Bhartiya Aviation Services"/>
<meta property="twitter:description" content="Are you worried about your career? then you do not have to worry because  Bhartiya Aviation Services offers Airport Jobs in Punjab. Call now at 8447-79-8447"/>
<meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon_package_v0.16/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon_package_v0.16/favicon-16x16.png"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" href="/public/logo192.png" />
       <link rel='canonical' href='https://bhartiyaaviation.in/indigo-careers-and-airport-job-in-punjab' />
   </Helmet>

   <div className='container-fluid  top-banner-pg-div-about-us'>
         <div className='inner-pg-wrapper-div'>
             <div className='container ot-page-banner-heading'>
                 <h1>Punjab</h1>
                 <Link to={'/'}>
                 <p>
                  Home/ punjab
                  </p>
                 </Link>
             </div>
         </div>
     </div>

     <div className='container'>
        <div className='green-field-img-div'>
            <img src="/images/punjab.png" alt="" />
        </div>
        <div className='green-field-text'>
            
            <h2 className='text-center mt-4 mb-4'><u>How Chandigarh Airport was established?</u></h2>

            <p>The Indian Air Force Station's civil enclave served as the base for all of Chandigarh Airport's commercial and civil operations. In the 1970s, Indian Airlines began running flights between Chandigarh and Delhi. In the civil enclave, a brand-new airport building was built and inaugurated on April 14, 2011. On August 19, 2011, the airport was designated a customs airport, making it eligible for a select few international flights, but it has never had an international flight terminal.</p>

                <br/>

            <p>For the purpose of constructing a new international terminal, the Punjab government paid 452 crores in 2008 to purchase 304.04 acres of land in the village of Jhiurheri on the south side of the current runway. The terminal's construction, which cost 939 crores to complete, started in 2015. The Airports Authority of India owns the remaining 51% of the new terminal building, with the governments of Punjab and Haryana each owning a 24.5% interest.</p>

            <br/>
            <p>After investing 1,400 crores at the airport, the Mohali Industries Association filed a Public Interest Litigation (PIL) for the operation of foreign flights in the Punjab and Haryana High Court on December 24, 2015. In September 2016, IndiGo and Air India Express began operating flights to Dubai and Sharjah, respectively, following numerous hearings and protracted delays in the Punjab and Haryana High Court. Sector 17 is where the new terminal is situated in relation to the city of Chandigarh. is 14 kilometers away in.</p>
            <br/>
            <p>
            The sole airport servicing the Union Territories of Chandigarh, Mohali, and Punjab is Chandigarh Airport. The runway is in the Chandigarh Union Territory, while the terminal is in the Jhiurheri town on the south side of the runway.. Six domestic airlines use the airport, which links Chandigarh with 17 domestic and 2 international destinations. Airports Council International named the airport the Best Airport by Sanitation Measures in Asia-Pacific in 2021.
            </p>
            <br/>
           
            <p>
            On September 11, 2015, Prime Minister Narendra Modi opened the new terminal. A first of its sort in India, this new environmentally friendly terminal was constructed by Larsen & Toubro. Plants are used within the airport to create the impression of a vegetable garden. It opened on October 19, 2015, and is situated in the Punjabi village of Jhiurheri.
            </p>
            <br/>
           
            <p>
            Two connection taxiways to the terminal and cargo complex were built as part of the new terminal's construction. The capacity was raised to 20 aircraft per hour in April 2022 by the addition of a 900 m taxiway. The terminal contains eight remote aircraft parking stands and five aerobridges enabling five-contact parking. Arrivals take place on the ground floor, while departures happen on the first floor.
            </p>
        </div>
    </div>
    
    </>
  )
}

export default Punjab