import React, { useState } from 'react'
import '../styles/admicard.css'
import { FaDotCircle } from 'react-icons/fa';
import axios from 'axios'

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker } from 'antd';
dayjs.extend(customParseFormat);

/** Manually entering any of the following formats will perform date parsing */
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
//const dateFormat = 'DD/MM/YY'; 


const Result = () => {

    const [selectedDate, setSelectedDate] = useState(null);
    const [dateRequired, setDateRequired] = useState('')

    const handleDateChange = (date, dateString) => {
        // Handle the selected date
        console.log('Selected Date: ', dateString);
        setSelectedDate(dateString);
      };



    const [data,setData] = useState()

    const [loading, setLoading] = useState(false)
    const [msg, setMsg] = useState()
    const [regNum, setRegnum] = useState('')
    const [appId, setAppId] = useState('')

    const getResult = async(e)=>{
        e.preventDefault()

        if(!selectedDate || selectedDate === null || selectedDate === ''){
            setDateRequired('Please select your date of birth')
            return
        }
        setLoading(true)
        try{
            const res = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/getresult`,{
                registrationid: regNum,
                applicationid: appId,
                dob: selectedDate
            })
             
            setData(res.data.admitcard)
            setDateRequired('')
            setMsg('')
            setRegnum('')
            setAppId('')
            setLoading(false)
        }catch(err){
          //  console.log(err)
            setMsg(err.response.data.message)
            setDateRequired('')
            setData()
            setLoading(false)
        }
    }

    const uploadPhoto =  `${process.env.REACT_APP_HOSTURL}/api/v1/${data?.uploadphoto}`
    const uploadSignature =  `${process.env.REACT_APP_HOSTURL}/api/v1/${data?.uploadsignature}`

    let modifiedDPUrl = uploadPhoto.replace(/\s+/g, "%20");
    let modifiedSignUrl = uploadSignature.replace(/\s+/g, "%20");


  return (
    <>
        <div className='result-main-container'>
            <div className='container-fluid'>
                <div className='result-iner-wrapper'>
                    <div className='result-heading'>
                        <h1>Result Available Only For:</h1>
                        <h2>Ground Staff/CSA</h2>
                        <hr />
                    </div>
                    
                    <form onSubmit={getResult} className='d-print-none'>
                    <div className='result-input-details'>
                        <p className='pb-2'><FaDotCircle className='me-2'/>Status as on <b>{new Date().toDateString()}</b></p>
                        <div className='result-input-field'>
                            <label className='pb-1' htmlFor="roll">Enter Roll No:</label>
                            <input className='form-control'  type="number" id='roll'  placeholder='enter roll no' required onChange={(e)=> setRegnum(e.target.value)} value={regNum}/>
                        </div>
                        <div className='result-input-field'>
                            <label className='pb-1' htmlFor="application">Enter Application No:</label>
                            <input className='form-control'  type="number" id='application'  placeholder='enter application no' required onChange={(e)=> setAppId(e.target.value)} value={appId}/>
                        </div>
                        <div className='date-picker-div'>
                        <label className='pb-1' htmlFor="application"><b>Enter Date Of Birth:</b>(DD/MM/YYYY) </label>
                        <DatePicker format={dateFormatList}  onChange={handleDateChange} className='mb-2'/>
                        <p className='mb-2' style={{textAlign: 'center', fontWeight: 'bold', color: 'tomato'}}>{dateRequired}</p>
                        </div>
                        <button type='submit' className='btn btn-warning' disabled={loading ? true : false}>
                       {loading ? ( <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                        <p></p>
                        </div>) : 'Submit'}
                        </button>

                        <h5 style={{color: 'tomato'}} className='mt-4 mb-4'>{msg}</h5>
                    </div>
                    </form>
                    
                   {data &&  <div className='result-section-div'>
                        <div className='result-table-div'>
                            <div className='result-inr-lefttbl-dv'>
                            <table className="table table-bordered border-secondary table-striped">
                                    <thead>
                                       
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <th scope="row">Application No.</th>
                                        <td>{data?.applicationid}</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Roll No.</th>
                                        <td>{data?.registrationid}</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Candidate Name</th>
                                        <td>{data?.firstname} {data?.lastname}</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Father's Name</th>
                                        <td>{data?.fatherhusbandname}</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Date Of Birth</th>
                                        <td>{data?.dob}</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Qualification Status</th>
                                        <td>{data?.qualificationstatus}</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Interview Promotion</th>
                                        <td>{data?.promotion}</td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <div className='res-note-cntr-dv'>
                                        <p><b>* Note: </b> If your interview promotion status shows 'Promoted for interview',  you will receive an official Call Letter in your email inbox within 7 working days. This letter will contain details about the interview date, venue, and timings. Ensure to follow the instructions mentioned in the Call Letter while attending the interview.</p>
                                        <p>यदि आपकी साक्षात्कार पदोन्नति स्थिति <u>'प्रमोटेड फॉर इंटरव्यू'</u> दिखाती है, तो आपको 7 कार्य दिवसों के भीतर अपने ईमेल इनबॉक्स में एक आधिकारिक कॉल लेटर प्राप्त होगा। इस पत्र में साक्षात्कार की तारीख, स्थान और समय के बारे में विवरण होगा। साक्षात्कार में भाग लेने के दौरान कॉल लेटर में उल्लिखित निर्देशों का पालन करना सुनिश्चित करें।</p>
                                    </div>
                            </div>

                            <div className='profile-dpdiv-rhg '>
                                <img className='demodp' src={data?.uploadphoto ? `${modifiedDPUrl}` : "/images/demo.jpeg"} alt="profile" />
                                <img className='demosign' src={data?.uploadsignature ? `${modifiedSignUrl}` : "/images/demosign.png"} alt="profile" />
                            </div> 
                        </div>
                        <div className='result-infotable-div'>
                            <div className='res-rigt-inr-dv-wrapper'>
                                <div className='intru-heading'>
                                    <h1>Instructions</h1>
                                </div>

                                <div className='intruc-point-dv'>
                                   <p>1. &nbsp;Please watch your email inbox for the Call Letter, which you should receive within 7 working days. Reading and following the instructions carefully is important to ensure a successful interview process. Your cooperation is highly valued.</p>
                                   <p>कृपया कॉल लेटर के लिए अपने ईमेल इनबॉक्स पर नज़र रखें, जो आपको 7 कार्य दिवसों के भीतर प्राप्त हो जाना चाहिए। सफल साक्षात्कार प्रक्रिया सुनिश्चित करने के लिए निर्देशों को ध्यान से पढ़ना और उनका पालन करना महत्वपूर्ण है। आपका सहयोग अत्यधिक मूल्यवान है.</p>

                                   <p>2. &nbsp; If your Qualification Status shows '<b>Not Qualified</b>' but your Interview Promotion Status shows ' <b>Promoted for Interview</b> ', you will receive a call letter in your official email within 7 working days.</p>
                                   <p>यदि आपकी योग्यता स्थिति <u>'नॉट क्वालिफाइड'</u> दिखाती है, लेकिन आपके साक्षात्कार पदोन्नति स्थिति <u>'प्रमोटेड फॉर इंटरव्यू'</u> दिखाती है, तो आपको 7 कार्य दिवसों के भीतर अपने आधिकारिक ईमेल में एक कॉल लेटर प्राप्त होगा। </p>

                                   <p>3. &nbsp; If you haven't received the Call Letter within 7 working days, please reach out to us through our official helpline numbers (8447-58-8447 , 8447-79-8447) or by sending us an email info@bhartiyaaviation.in regarding this matter.</p>
                                   <p>यदि आपको 7 कार्य दिवसों के भीतर कॉल लेटर प्राप्त नहीं हुआ है, तो कृपया इस संबंध में हमारे आधिकारिक हेल्पलाइन नंबरों (8447-58-8447, 8447-79-8447) के माध्यम से या हमें info@bhartiyaaviation.in पर एक ईमेल भेजकर हमसे संपर्क करें।</p>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>

        {data && <div className='text-center mt-4 pb-4 pdf-download-div d-print-none'>
        <button className='btn btn-success m-4  d-print-none' onClick={()=> window.print()}>Print</button>
        </div>}
    </>
  )
}

export default Result