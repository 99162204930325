import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import '../styles/componentPage.css'

const Bihar = () => {
  return (
    <>
       <Helmet> 
     <title>Best Indigo Careers in Bihar | Bhartiya Aviation Services</title>
     <meta charSet='UTF-8'/>
       <meta name="keywords" content="Best Indigo Careers in Bihar, Airline Jobs in Bihar"/>
       <meta name="description" content="Searching for an Airline Jobs in Bihar? then you are in the right place because Bhartiya Aviation Services can help you to get a job in the airline industry. "/>
       <meta name="author" content="Bhartiya Aviation Services"/>
       <meta name="refresh" content="50"/>
       <meta property="og:image" content="/public/favicon_package_v0.16/apple-touch-icon.png"/>
       <meta http-equi="refresh" content="" url="https://bhartiyaaviation.in/indigo-careers-and-airline-jobs-in-bihar"/>
       <meta property="og:type" content="website"/>
<meta property="og:url" content="https://bhartiyaaviation.in/indigo-careers-and-airline-jobs-in-bihar"/>
<meta property="og:title" content="Best Indigo Careers in Bihar | Bhartiya Aviation Services"/>
<meta property="og:description" content="Searching for an Airline Jobs in Bihar? then you are in the right place because Bhartiya Aviation Services can help you to get a job in the airline industry."/>
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>


<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://bhartiyaaviation.in/indigo-careers-and-airline-jobs-in-bihar"/>
<meta property="twitter:title" content="Best Indigo Careers in Bihar | Bhartiya Aviation Services"/>
<meta property="twitter:description" content="Searching for an Airline Jobs in Bihar? then you are in the right place because Bhartiya Aviation Services can help you to get a job in the airline industry."/>
<meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon_package_v0.16/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon_package_v0.16/favicon-16x16.png"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" href="/public/logo192.png" />
       <link rel='canonical' href='https://bhartiyaaviation.in/indigo-careers-and-airline-jobs-in-bihar' />
   </Helmet>

   <div className='container-fluid  top-banner-pg-div-about-us'>
         <div className='inner-pg-wrapper-div'>
             <div className='container ot-page-banner-heading'>
                 <h1>Bihar</h1>
                 <Link to={'/'}>
                 <p>
                  Home/ bihar
                  </p>
                 </Link>
             </div>
         </div>
     </div>

     <div className='container'>
        <div className='green-field-img-div'>
            <img src="/images/bihar.png" alt="" />
        </div>
        <div className='green-field-text'>
            
            <h2 className='text-center mt-4 mb-4'><u>How was Jai Prakash Narayan Airport established in Patna, Bihar?</u></h2>

            <p>The Jaiprakash Narayan Airport is a customs airport near the state capital of Bihar, Patna. It bears Jayaprakash Narayan's name, a prominent political and independence activist. Compared to other airports, this one is limited, as it only has a small runway. From April 2021 to March 2022, it ranks as India's 14th busiest airport. The Airports Authority of India (AAI) is trying to upgrade and modernise the airport infrastructure in order to accommodate the demand. A new, two-level passenger terminal is part of the airport's massive expansion project, which will be finished by December 2023. At Bihta Air Force Station, AAI has also recommended establishing a civil enclave. The airport that follows Patna.</p>

                <br/>

            <p>There is currently one asphalt runway at the airport, designated 07/25, and it is 1,954 by 45 metres long. Large aircraft cannot land at the airport's present runway because it is just 6,000 feet long. The airport's terminal building is 12,000 square metres in size and can accommodate over 2.5 million passengers a year. Due to the rising number of travellers flying into the airport, the waiting area, as well as the departure and arrival zones, have recently been increased. A new terminal is being built in response to rising passenger demand, which will relieve pressure on the current terminal.</p>

            <br/>
            <p>The Patna Airport's runway is 7,000 feet long. Between Phulwari Sharif Railway Station and Sanjay Gandhi, Biological Park is where Patna Airport is located. For east landings, the runway is 1,938 metres, whereas for west landings it measures 1,677 metres. When the building completes, the area it takes up will grow from 1.6 hectares to 7.5 hectares The new Patna airport terminal building will be a two-story facility with six aerobridges and a parking area for 14 aircraft at once. Only six aircraft can currently be parked at the airport. The Patna Airport Complex will be expanded by razing a number of existing structures, including the Airport Colony and IAS Bhawan. Several utility structures are also included.</p>
            <br/>
            <p>
            In exchange, the Airports Authority of India would grant the Bihar government 11.35 acres of land in Anisabad, Patna, so that a top-notch two-story terminal building can be constructed on 13.1 acres of ground close to the airport. In order to maintain aviation operations through at least 2035, the Airports Authority of India (AAI) has suggested expanding the terminal to accommodate 6 million passengers annually. There will be a sky bridge built to connect the new terminal building to the current structure. In order to accommodate the needs of heavier aircraft, AAI also proposes to build a civil enclave at Bihta Air Force Station, 20 kilometres from Patna. The Bihar cabinet adopted the in October 2016
            </p>
            
        </div>
    </div>
    
    </>
  )
}

export default Bihar