import './Admin.css'
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdEmail,MdVpnKey,MdPassword} from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { useDispatch, useSelector} from 'react-redux';
import { adminlogin } from '../context/adminSlice';

const AdminLogin = () => {

  const dispatch = useDispatch()

  const adminBasData =  useSelector((state)=> state.basAdminDetail)

  // console.log(adminBasData)
  // console.log(adminBasData.basadmin)

  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [secretkey, setSecretKey] = useState(null);
  const [password, setPassword] = useState(null);
   // eslint-disable-next-line
  const [require, setRequire] = useState(false);
  const [msg, setMsg] = useState('');
  const [loading,setLoading] = useState(false);


  const adminCheck = () =>{
    if(!adminBasData|| adminBasData === null  || adminBasData.basAdminDetail === null){
      navigate('/admin/login')
    }else{
      navigate('/basadmincontrol')
    }
}

const adminLoginHandler = async(e)=>{
  e.preventDefault();
  if(email === "" || !email){
      setRequire(true)
      return
  }else{
    setLoading(true)
      try{
          const res = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/admin/login`,{
              email,
              secretkey,
              password
          });
          setRequire(false)
          setLoading(false)
          if(res.data.status === 200){
            setMsg('')
              const adminObj={
                  email: res.data.response.email,
                  role: res.data.response.role
              }
              dispatch(adminlogin(adminObj))
              navigate('/basadmincontrol');
          }else if(res.data.status === 404){
              setMsg(res.data.message);
          }

      }catch(err){
         // console.log('Admin Backend',err);
      }
  }
}



useEffect(()=>{
    adminCheck()
    // eslint-disable-next-line
},[])


  return (
    <>
      <div className='container admin-login-main-div shadow-sm'>
          <div className='admin-login-heading'>
            <h3>Admin Login Panel</h3>
          </div>

          <div className='login-form-main-div'>
            <div className='form-lock-icon-div'>
                <IoIosLock className='admin-login-icon'/>
            </div>
            <div className='admin-login-main-upper-div'>
              <form onSubmit={adminLoginHandler}>
                <div className='form-inner-div'>
                <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1"><MdEmail className='admin-login-form-icon'/></span>
                <input type="email" className="form-control" placeholder="Enter Email" aria-label="Username" aria-describedby="basic-addon1" onChange={(e)=> setEmail(e.target.value)} required/>
              </div>
                <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1"><MdVpnKey className='admin-login-form-icon'/></span>
                <input type="password" className="form-control" placeholder="Enter Secret Key" aria-label="Username" aria-describedby="basic-addon1" onChange={(e)=> setSecretKey(e.target.value)} required />
              </div>
                <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1"><MdPassword className='admin-login-form-icon'/></span>
                <input type="password" className="form-control" placeholder="Enter Password" aria-label="Username" aria-describedby="basic-addon1" onChange={(e)=>{setPassword(e.target.value)}} required />
              </div>

              <button type='submit' className='btn btn-warning fw-bold admin-log-btn'  disabled={loading ? true :  false}>Login</button>

              <p className='mt-2 mb-2 fw-bold text-muted'>{loading ? 'loading...' : null}</p>
              <p className='mt-2 mb-2 fw-bold text-muted'>{msg}</p>
                </div>
              </form>
            </div>
          </div>
      </div>
    </>
  )
}

export default AdminLogin