import React, { useState } from 'react'
import '../styles/login.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {login} from '../context/userSlice'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Login = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [regNumber, setRegNum] = useState('');
  const [password,setPassword] = useState('');
 
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const loginHandler  = async(e)=>{
    e.preventDefault()

    if(regNumber === '' || regNumber === null || password === '' || password === null){
      return setErrMsg('Please fill the fields!!')
    }

    try{
      setLoading(true)
      const res = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/login`,{
        registrationnumber:regNumber,
        password: password
      });

      if(res.data.status === 200){
        const {_id,registrationnumber,name,email } = res.data.body

        const obj ={
          id:_id,
          regNum: registrationnumber,
          canName: name,
          canEmail: email
        }
        dispatch(login(obj))
        setLoading(false)
        e.target.reset();
        setErrMsg('')
        navigate('/application-form')
      }else if(res.data.status === 404){
        setErrMsg(res.data.message)
        setLoading(false)
      }else{
        setErrMsg('Please Check Your Details !!')
      }
    }catch(err){ 
     // console.log(err)
      //console.log(err.message)
      setLoading(false)
    }
  }

 


  // console.log(userData)

  return (
   <>
    <div className='login-main-div restpage-secondary-bg'>
        <div className='container login-main-inner-div'>
            <div className='form-login-div'>
            <div className='login-title-div'>
              <h3>Login</h3>
             </div>
                <form onSubmit={loginHandler}>
                  <div className='login-form-inner-el'>
                      <label htmlFor="">Registration Number</label>
                      <input className='form-control' type="number" placeholder='enter registration number' required onChange={(e)=> setRegNum(e.target.value)}/>
                  </div>
                  <div className='login-form-inner-el'>
                      <p className='fw-bold text-danger'><em>Please Fill Password DD/MM/YYYY</em></p>
                      <label htmlFor="passfield">Password</label>
                      <input className='form-control' id='passfield'  type="date" placeholder='DD/MM/YYYY' required onChange={(e)=> setPassword(e.target.value)} />

                  </div>
                  <div>
                    {
                      loading ? (
                        <>
                           <div className='form-login-btn-div'>
                           <button disabled={true}>
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </button>
                        <p>Create New Account ?</p>
                        <p>Forgot your password ?</p>
                      </div>
                        </>
                      ) : (
                        <>
                        <div className='form-login-btn-div'>
                        <button type='submit'>Login</button>
                        <Link to={'/register-now'}>
                        <p>Create New Account ?</p>
                        </Link>
                        <Link to={'/forgotaccount'}>  
                        <p>Forgot your password ?</p>
                        </Link>
                      </div>
                        </>
                        )
                    }
                  </div>
                  <div className='text-center'>
                    <p className='fw-bold text-danger'>{errMsg}</p>
                  </div>
                </form>
            </div>
        </div>
    </div>
   </>
  )
}

export default Login