import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import '../styles/componentPage.css'

const Uttarpradesh = () => {
  return (
    <>
    <Helmet> 
  <title>Best Indigo Careers in Uttarpradesh | Bhartiya Aviation Services</title>
  <meta charSet='UTF-8'/>
    <meta name="keywords" content="Best Indigo Careers in Uttarpradesh, Airline Careers  in Uttarpradesh"/>
    <meta name="description" content="Do you search for Airline Careers in Uttarpradesh? then you are in the ideal place because Bhartiya Aviation Services can help you to make a career in the airline industry."/>
    <meta name="author" content="Bhartiya Aviation Services"/>
    <meta name="refresh" content="50"/>
    <meta property="og:image" content="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <meta http-equi="refresh" content="" url="https://bhartiyaaviation.in/indigo-careers-and-airline-jobs-in-uttarpradesh"/>
    <meta property="og:type" content="website"/>
<meta property="og:url" content="https://bhartiyaaviation.in/indigo-careers-and-airline-jobs-in-uttarpradesh"/>
<meta property="og:title" content="Best Indigo Careers in Uttarpradesh |Bhartiya Aviation Services"/>
<meta property="og:description" content="Do you search for Airline Careers in Uttarpradesh? then you are in the ideal place because Bhartiya Aviation Services can help you to make a career in the airline industry."/>
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>


<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://bhartiyaaviation.in/indigo-careers-and-airline-jobs-in-uttarpradesh"/>
<meta property="twitter:title" content="Best Indigo Careers in Uttarpradesh |Bhartiya Aviation Services"/>
<meta property="twitter:description" content="Do you search for Airline Careers in Uttarpradesh? then you are in the ideal place because Bhartiya Aviation Services can help you to make a career in the airline industry."/>
<meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon_package_v0.16/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon_package_v0.16/favicon-16x16.png"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" href="/public/logo192.png" />
    <link rel='canonical' href='https://bhartiyaaviation.in/indigo-careers-and-airline-jobs-in-uttarpradesh' />
</Helmet>

<div className='container-fluid  top-banner-pg-div-about-us'>
      <div className='inner-pg-wrapper-div'>
          <div className='container ot-page-banner-heading'>
              <h1>Uttar Pradesh</h1>
              <Link to={'/'}>
              <p>
               Home/ uttar pradesh
               </p>
              </Link>
          </div>
      </div>
  </div>

  <div className='container'>
     <div className='green-field-img-div'>
         <img src="/images/uttarpradesh.png" alt="" />
     </div>
     <div className='green-field-text'>
         
         <h2 className='text-center mt-4 mb-4'><u>Journey to Lal Bahadur Shastri Airport established in Varanasi, Uttar Pradesh.</u></h2>

         <p>The Indian city of Babatpur, Uttar Pradesh, is home to Lal Bahadur Shastri International Airport. One of the biggest airports in northern India, it is an important center for both local and international travel. Named for India's second prime minister, it is a significant international airport that serves Varanasi, Uttar Pradesh, India. It is one of the busiest airports in Uttar Pradesh, at number 10 in terms of passenger volume. Airports Council International has recognized the airport as the top airport in Asia-Pacific for 2020.</p>

             <br/>

         <p>Both local and foreign flights are handled by a terminal with a 3,900 square metre floor size. It has 16 check-in counters, four dual immigration counters, two baggage claim belts, and four immigration counters. In addition to upper-level seating for air bridge gates, the terminal has a ground-level gate for access to other aircraft on the apron via foot or shuttle bus. On the apron, five narrow-bodied aircraft can be parked simultaneously. The waiting area provides essential services for travelers apart from breakfast stalls, a traveler convenience store selling books and snacks from around the world as well as local products such as sarees made in Banarasi silk factories, and stores selling goods made locally such as Banarasi silk sarees.</p>

         <br/>
         <p>The airport has an asphalt runway bearing 09/27. It is 9,006 feet long and has turnarounds at both ends and two exits from the main apron. A third, rarely used exit leads to a separate apron for use in emergencies. Due to the increase in passenger traffic and aircraft movement, the Airports Authority of India is planning to extend the runway to 4,075 m from the current 2,750 m. An underpass will be constructed as the extension will connect to National Highway 31.</p>
         <br/>
         <p>
         In November 2019, the Airports Authority of India came out with a proposal for a new second terminal, which will be completed by 2024. The overall size of the terminal will be 58,691 square meters and will have a capacity of 4.5 million passengers per year.
         </p>
       
     </div>
 </div>
 
 </>
  )
}

export default Uttarpradesh