import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import '../Admin.css'
import axios from 'axios'
// eslint-disable-next-line
import {ref, uploadBytes, listAll, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../firebase';

const DBcreatePost = () => {

  const [createPostData, setCreatePostData ] = useState([]);
  const [loading,setLoading] = useState(false);
  const [jobProfileName, setJobProfileName] = useState()
  const [advTitle, setAdvTitle] = useState()
  const [profileFee, setProfileFee] = useState()

  const [latestAlert, setLatestAlerts] = useState('');
  const [alertsMsg, setAlertsMsg] = useState('');

//latest news section

  const [latestImg, setLatestImg] = useState(null);
  const [latestNewsArticle, setLatestNewsArticle] = useState('');
  const [latestNewsLink, setLatestNewsLink] = useState('');
  const [latestNewsTitle, setLatestNewsTitle] = useState('');
  const [latestNewsData, setLatestNewsData] = useState([])
  const [latestnewLoading, setLatestNewsLoading] = useState(false);
  const [newsMsg, setNewsMsg] = useState('')



  const uploadLatestNews = async(e)=>{
    e.preventDefault()
    setLatestNewsLoading(true)
    try{
        if(!latestImg || latestImg === null){
          return
        }
        const imageRef =  ref(storage, `latestNews/${latestImg.name + new Date().getTime()}`)
        const uploadImgResponse  =  await uploadBytesResumable(imageRef, latestImg); 
        const uploadedImgUrl = await getDownloadURL(uploadImgResponse.ref)
        const res = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/latestnewspath`,{
          img: uploadedImgUrl,
          articleTitle: latestNewsTitle,
          article: latestNewsArticle,
          link: latestNewsLink
        })
        //console.log(res.data.statusCode);
        //console.log(res.data.data)
        setLatestNewsData((prev=> [res.data.data, ...prev]));
        setLatestNewsLoading(false)
        e.target.reset();
        setLatestImg(null)
    }catch(err){
      //console.log(err);
    }
  }


  const deleteLatestNewsArticle =  async(e)=>{
    e.preventDefault()
    try{

      const listId =  e.target.id
      const res = await axios.delete(`${process.env.REACT_APP_HOSTURL}/api/v1/latestnewspath/${listId}`);
      //console.log(res.data.message)

      const newArrLatestNews =  latestNewsData.filter((d) => d._id !== listId);
      setLatestNewsData(newArrLatestNews)
      setNewsMsg(res.data.message)

      setTimeout(()=>{
        setNewsMsg('')
      },3000)

    }catch(err){
      //console.log(err)
    }
  }

  const fetchLatestNewsData = async()=>{
    try{

      const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/latestnewspath`)
      //console.log(res.data.data);
      setLatestNewsData(res.data.data);
    }catch(err){
      //console.log(err)
    }
  }



const createPostDetails = async(e)=>{
  e.preventDefault()
  e.target.reset()
    try{
      const res  = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/post`,{
        name: jobProfileName,
        advTitle: advTitle,
        price: profileFee
      });
      setCreatePostData((prev)=> [res.data.body, ...prev])
    }catch(err){
      //console.log(err);
    }
}


const createLatestAlerts  = async(e)=>{
  e.preventDefault()
  if(latestAlert === '' || latestAlert === null || latestAlert === undefined){
    return setAlertsMsg('Field can\'t be empty !!')
  }
  try{
    const res  = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/latestupdate`,{
      list: latestAlert
    })
   // console.log(res.data.message)
   //  e.target.reset()
   setAlertsMsg(res.data.message)
   setLatestAlerts('')
    
  }catch(err){
    //console.log(err);
  }
}

  useEffect(()=>{
    setLoading(true)
      const fetchAllJobPosts =  async()=>{
        try{
          const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/post`)
          setCreatePostData(res.data.body)
          setLoading(false)
        }catch(err){
         // console.log(err);
        }
      }
      fetchLatestNewsData()
      fetchAllJobPosts()
  },[])


  //console.log(latestAlert)

  return (
    <>
    <div className='right-side-page-component'>
        <div className='container restpage-secondary-bg'>
          <div className='row'>
           {
            loading ? (
              <>
               <div className='d-flex align-items-center justify-content-center flex-column'>
                    <div className="loader"></div>
                            <p>Loading...</p>
                    </div>
              </>
            ) :( 
              <>
              <div className='col-lg-6'>
              <div className='create-post-form-main-div m-4 p-3 shadow-sm' style={{backgroundColor: 'white'}}>
              <h3 className='mb-4 p-2 fw-bold' style={{backgroundColor: ' #816500', color: 'white'}}>Create Job Profiles</h3>  
                <form onSubmit={createPostDetails}>
                  <div className='create-post-form-db d-flex align-align-items-end flex-column mb-2'>
                    <label className='fw-bold text-muted'>Job Profile Name</label>
                    <input className='form-control'  type="text" placeholder='enter profile name' onChange={(e)=> setJobProfileName(e.target.value)} required />
                  </div>
                  <div className='create-post-form-db d-flex align-align-items-end flex-column  mb-2'>
                    <label className='fw-bold text-muted'>Advertisment Title</label>
                    <input className='form-control'  type="text" placeholder='enter advertisment title' onChange={(e)=> setAdvTitle(e.target.value)} required/>
                  </div>
                  <div className='create-post-form-db d-flex align-align-items-end flex-column  mb-2'>
                    <label className='fw-bold text-muted'>Fee ₹</label>
                    <input className='form-control'  type="number" placeholder='enter fee' onChange={(e)=> setProfileFee(e.target.value)} required/>
                  </div>
                  <button type='submit'  className='btn btn-warning'>Create Post</button>
                </form>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='uploaded-profilepost-div m-4 p-3 shadow-sm' style={{backgroundColor: 'white'}}>
              <h3 className='mb-4 p-2 fw-bold' style={{backgroundColor: ' #816500', color: 'white'}}>Uploaded Job Profiles Posts</h3>  
              <ul className="list-group">
                {
                  createPostData?.map((d,i)=>{
                    return (
                      <li className="list-group-item" key={i}>{`${d.name} --- ₹ ${d.price}`}</li>
                    )
                  })
                }
               
              </ul>
              </div>
            </div>
              </>
            )
           }
          </div>
        </div>

        <div className='container create-alert-notification-div'>
            <div className='create-alert-notification-inner-div text-center p-2' style={{backgroundColor: ' #816500', color: 'white'}}>
              <h4>Upload Latest Updates & Alerts</h4>
            </div>
            <div className='mt-3 mb-3 p-3'>
              <div className='input-alert-form d-flex align-items-start flex-column '>
                <label htmlFor=""><b className='text-muted'>Enter Alert Notification</b></label>
               <textarea className='form-control mt-2 mb-2' name="" id="" cols="30" rows="4" placeholder='type here'  onChange={(e)=> setLatestAlerts(e.target.value)} value={latestAlert}></textarea>
                <div className='d-flex align-items-center'>
                     <button className='btn btn-warning' onClick={createLatestAlerts} >Upload</button>
                     <p className='ms-4 text-success fw-bold'>{alertsMsg}</p>
                </div>
              </div>
            </div>
        </div>

           <div className='createLatestNews-main-div'>
           <div className='container'>
            <div className='createlatest-inner-div'>
            <div className='latestnews-title-div'>
              <h3>Latest News Article Section</h3>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='latest-post-left-form-div'>
                  <form onSubmit={uploadLatestNews}>
                    <div className='news-form-el'>
                      <label htmlFor="">Select Image</label>
                      <div className='news-img-upload-div'>
                      <input type="file" required  onChange={(e)=> setLatestImg(e.target.files[0])}/>
                      <img src={latestImg ? URL.createObjectURL(latestImg) : '/images/dummyimage.jpeg' } width={100} height={100}  alt=""  />
                      </div>
                    </div>
                    <div className='news-form-el'>
                      <label htmlFor="">Enter News Title</label>
                     <input className='form-control' type="text" maxLength={'30'}  required onChange={(e)=> setLatestNewsTitle(e.target.value)}/>
                    </div>
                    <div className='news-form-el'>
                      <label htmlFor="">Enter News Article</label>
                      <textarea className='form-control' cols="30" rows="5" required onChange={(e)=> setLatestNewsArticle(e.target.value)}></textarea>
                    </div>
                    <div className='news-form-el'>
                      <label htmlFor="">Enter News Article Link</label>
                     <input className='form-control' type="text"  required onChange={(e)=> setLatestNewsLink(e.target.value)}/>
                    </div>
                    <div className='news-upload-btn'>
                      <button type='submit'  className='btn btn-warning' disabled={latestnewLoading ? true : false}>Post News Article</button>
                        <br/>
                        {
                          latestnewLoading ? (
                            <>
                            <div className="spinner-border text-danger" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                            </>
                          ):(null)
                        }
                    </div>
                  </form>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='uploaded-news-article-right-div'>
                  <h3>Uploaded News Articles</h3>
                  <p className='p-2 fw-bold text-danger'><em>{newsMsg}</em></p>
                  <div className='upload-news-art-lists'>
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                  {
                    latestNewsData?.map((d,i)=>{
                      return(
                        <div className="accordion-item shadow-sm mb-3" key={i}>
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseThree${i}`} aria-expanded="false" aria-controls="flush-collapseThree">
                        <div className='d-flex align-items-center  justify-content-between  w-100'>
                        <p className='text-muted'> {d.articleTitle}</p>
                         <p><em className='text-muted'>{new Date(d?.createdAt).toDateString()}</em></p>
                        </div>
                    </button>
                  </h2>
                  <div id={`flush-collapseThree${i}`} className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <p>{d.article}</p>
                        <br/>
                      <a href={d.link}><em>{d.link}</em></a>
                      <br/>
                      <br/>
                      <button className='btn btn-danger'id={d._id} onClick={deleteLatestNewsArticle}>Delete</button>
                    </div>
                  </div>
                </div>
                      )
                    })
                  }
                </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
           </div>
           </div>
    </>
  )
}

export default DBcreatePost