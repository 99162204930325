import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import '../styles/componentPage.css'
import axios from 'axios'

const LatestNewALLIndia = () => {

    const [latestNewsData, setLatestNewsData] = useState([]);



    useEffect(()=>{
        const fetchNewsData  = async()=>{
            try{    
                const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/latestnewspath`)
                setLatestNewsData(res.data.data)
            }catch(err){
               // console.log(err)
            }
        }
        fetchNewsData()
    },[])

  return (
    <>
          <Helmet> 
     <title>Latest news of All India Airport || Bhartiya Aviation Services</title>
     <meta charSet='UTF-8'/>
       <meta name="keywords" content="Bhartiya Aviation Services,Aviation, Aviation Jobs, airport jobs, graound staff jobs"/>
       <meta name="description" content="Bhartiya Aviation Services is an ISO 9001:2015 Certified company & professional team managed by professionals with a combined experience of more than 40 years in aviation"/>
       <meta name="author" content="Bhartiya Aviation Services"/>
       <meta name="refresh" content="50"/>
       <meta property="og:image" content="/public/favicon_package_v0.16/apple-touch-icon.png"/>
       <meta http-equi="refresh" content="" url="https://bhartiyaaviation.in/"/>
       <meta property="og:type" content="website"/>
<meta property="og:url" content="https://bhartiyaaviation.in/"/>
<meta property="og:title" content="Bhartiya Aviation Services"/>
<meta property="og:description" content="Bhartiya Aviation Services is an ISO 9001:2015 Certified company & professional team managed by professionals with a combined experience of more than 40 years in aviation"/>
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>


<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://bhartiyaaviation.in/"/>
<meta property="twitter:title" content="Bhartiya Aviation Services"/>
<meta property="twitter:description" content="Bhartiya Aviation Services is an ISO 9001:2015 Certified company & professional team managed by professionals with a combined experience of more than 40 years in aviation"/>
<meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon_package_v0.16/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon_package_v0.16/favicon-16x16.png"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" href="/public/logo192.png" />
       <link rel='canonical' href='/services' />
   </Helmet>

   <div className='container-fluid  top-banner-pg-div-about-us'>
         <div className='inner-pg-wrapper-div'>
             <div className='container ot-page-banner-heading'>
                 <h1>Latest news of All India Airport</h1>
                 <Link to={'/'}>
                 <p>
                  Home/ latest news
                  </p>
                 </Link>
             </div>
         </div>
     </div>

    <div className='letest-news-main-div restpage-secondary-bg'>
        <div className='container latast-news-height-container'>
            {
                latestNewsData?.map((d,i)=>{
                    return(
                        <div className='latest-new-inner-div' key={i}>
                        <div className='row'>
                            <div className='col-sm-3'>
                                <div className='latest-news-img-div shadow-sm'>
                                <img src={d.img} alt="" />
                                </div>
                            </div>
                            <div className='col-sm-9'>
                            <div className='latestnews-para-link shadow-sm'>
                                <p>{d.article}</p>
                                <a href={d.link} className='fs-6'>{d.link}</a>
                            </div>
        
                            </div>
                        </div>
                    </div>
                    )
                })
            }
           
        </div>
        </div>
    </>
  )
}

export default LatestNewALLIndia