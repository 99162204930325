import React from 'react'
import ContactComp from '../components/ContactComp'
import { MdCall,MdEmail,MdLocationOn } from "react-icons/md";


const Contact = () => {
  return (
    <>
    <div className='contact-pg-main-div restpage-secondary-bg'>
        <div className='container'>
            <div className='cont-pg-title-div'>
                <h3>Contact Us</h3>
            </div>
            <div className='contact-pg-inner-div'>
               <div className='row'>
                <div className='col-lg-6'>
                    <div className='contact-left-detail-div'>
                        <div className='contact-upper-left-div'>
                           <MdCall className='cont-icon-pg'/>
                           <div className='contact-detail-upr'>
                            <em>for Hindi</em>
                            <h3>8447-58-8447</h3>
                           </div>
                           <div className='contact-detail-upr'>
                            <em>for English</em>
                            <h3>8447-79-8447</h3>
                           </div>
                        </div>
                        <div className='contact-upper-left-div'>
                           <MdEmail className='cont-icon-pg'/>
                           <div className='contact-detail-upr'>
                            <h3>info@bhartiyaaviation.in</h3>
                           </div>
                        </div>
                        <div className='contact-upper-left-div'>
                           <MdLocationOn className='cont-icon-pg'/>
                           <div className='contact-detail-upr'>
                            <h3>Head-Office:</h3>
                           </div>
                           <div className='contact-detail-upr'>
                            <h5>Plot no 18, Top floor. Sewak park gram sabha Dwarka Mor near metro pillar no 773. New Delhi 110059</h5>
                           </div>
                        </div>
                        <div className='contact-upper-left-div'>
                           <MdLocationOn className='cont-icon-pg'/>
                           <div className='contact-detail-upr'>
                            <h3>Operational-Office</h3>
                           </div>
                           <div className='contact-detail-upr'>
                            <h5>H-136,3rd Floor, Mohan Garden, Uttam Nagar, West Delhi, Delhi-110059</h5>
                           </div>
                        </div>
                       
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='contact-right-form-div'>
                    <ContactComp/>
                    </div>
                </div>
               </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Contact