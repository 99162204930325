import React from 'react'
import { Link } from 'react-router-dom';

const Noticeinfo = () => {
  return (
    <>

<div className='container-fluid  top-banner-pg-div-about-us'>
         <div className='inner-pg-wrapper-div'>
             <div className='container ot-page-banner-heading'>
                 <h1>Warning ! !</h1>
                 <Link to={'/'}>
                 <p>
                  Home/ warning
                  </p>
                 </Link>
             </div>
         </div>
     </div>

        <div className='notice-main-bg-div'>
            <div className='container'>
                <div className='hindi-notice-div p-4'>
                    <h3 className='pb-2 text-danger'>सावधान !!</h3>
                    <p style={{fontSize: '15px', color: '#816500'}}>सभी अभ्यर्थियों को सूचित किया जाता है कि कुछ कंपनियां भारतीय एविएशन सर्विसेज (बी ए एस ) से मिलता जुलता नाम, वेबसाइट, होमपेज व सामग्रियों का प्रयोग कर रही है | कृपया आप उससे दूर रहें अन्यथा भारतीय एविएशन सर्विसेस (बी ए एस ) की कोई जिम्मेदारी नहीं होगी | पूरे भारत में भारतीय एविएशन सर्विसेज (बी ए एस ) की कोई भी ब्रांच नहीं है | भारतीय एविएशन सर्विसेज (बी ए एस ) का रजिस्टर्ड ऑफिस इस पते पर है :-</p>
                    <br/>
                    <p style={{color: '#816500'}}> <b>पता: प्लॉट नंबर 18,  टॉप फ्लोर, सेवक पार्क ग्राम सभा द्वारका मोड़ मेट्रो पिलर नंबर 773 के पास नई दिल्ली 110058</b></p>
                    <br/>
                    <ul style={{color: '#816500'}}>
                        <li>अधिक जानकारी के लिए हमसे संपर्क करें:-</li>
                        <li className='pt-2 pb-2'> <a href="mailto:info@bhartiyaaviation.in">ईमेल: info@bhartiyaaviation.in</a></li>
                        <li>फ़ोन नं: 8447588447, 8447798447</li>
                    </ul>
                </div>
                <div className='english-notice-div p-4'>
                <h3 className='pb-2 text-danger'>CAUTION !!</h3>
                    <p style={{fontSize: '15px', color: '#816500'}}>All the candidates are informed that some companies are using name, website, homepage and materials similar to Bhartiya Aviation Services (BAS). Please stay away from them, otherwise Bhartiya Aviation Services (BAS) will not have any responsibility. Bhartiya Aviation Services (BAS) does not have any branch in all over India. The registered office of Bhartiya Aviation Services (BAS) is at:-</p>
                    <br/>
                    <p style={{color: '#816500'}}> <b>Address:- Plot No 18, Top Floor, Sevak Park, Near Gram Sabha Dwarka Mor Metro Pillar No 773. New Delhi 110058</b></p>
                    <br/>
                    <ul style={{color: '#816500'}}>
                        <li>Contact us for more information:-</li>
                        <li className='pt-2 pb-2'> <a href="mailto:info@bhartiyaaviation.in">E-mail: info@bhartiyaaviation.in</a></li>
                        <li>Phone No: 8447588447, 8447798447</li>
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default Noticeinfo