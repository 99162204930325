import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import '../styles/componentPage.css'

const Mumbai = () => {
  return (
    <>
       <Helmet> 
     <title>Top Indigo Careers in Mumbai | Bhartiya Aviation Services</title>
     <meta charSet='UTF-8'/>
       <meta name="keywords" content="Top Indigo Careers in Mumbai, Airport Jobs in Mumbai"/>
       <meta name="description" content="Bhartiya Aviation Services train you and make you capable to get an Airport Jobs in Mumbai. Join Bhartiya Aviation Services and make your career now. Call  at 8447-79-8447"/>
       <meta name="author" content="Bhartiya Aviation Services"/>
       <meta name="refresh" content="50"/>
       <meta property="og:image" content="/public/favicon_package_v0.16/apple-touch-icon.png"/>
       <meta http-equi="refresh" content="" url="https://bhartiyaaviation.in/indigo-careers-and-airport-job-in-mumbai"/>
       <meta property="og:type" content="website"/>
<meta property="og:url" content="https://bhartiyaaviation.in/indigo-careers-and-airport-job-in-mumbai"/>
<meta property="og:title" content="Top Indigo Careers in Mumbai | Bhartiya Aviation Services"/>
<meta property="og:description" content="Bhartiya Aviation Services train you and make you capable to get an Airport Jobs in Mumbai. Join Bhartiya Aviation Services and make your career now. Call  at 8447-79-8447"/>
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>


<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://bhartiyaaviation.in/indigo-careers-and-airport-job-in-mumbai"/>
<meta property="twitter:title" content="Top Indigo Careers in Mumbai | Bhartiya Aviation Services"/>
<meta property="twitter:description" content="Bhartiya Aviation Services train you and make you capable to get an Airport Jobs in Mumbai. Join Bhartiya Aviation Services and make your career now. Call  at 8447-79-8447"/>
<meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon_package_v0.16/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon_package_v0.16/favicon-16x16.png"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" href="/public/logo192.png" />
       <link rel='canonical' href='https://bhartiyaaviation.in/indigo-careers-and-airport-job-in-mumbai' />
   </Helmet>

   <div className='container-fluid  top-banner-pg-div-about-us'>
         <div className='inner-pg-wrapper-div'>
             <div className='container ot-page-banner-heading'>
                 <h1>Mumbai</h1>
                 <Link to={'/'}>
                 <p>
                  Home/ mumbai
                  </p>
                 </Link>
             </div>
         </div>
     </div>

     <div className='container'>
        <div className='green-field-img-div'>
            <img src="/images/mumbai.png" alt="" />
        </div>
        <div className='green-field-text'>
            
            <h2 className='text-center mt-4 mb-4'><u>A Brief History of Chhatrapati Shivaji Maharaj International Airport Of India.</u></h2>

            <p>The Chhatrapati Shivaji Maharaj International Airport was built in 1930 and it was known as RAF Santacruz. and was originally used for military flying. After the completion of many expansion projects, the airport now has three runways: 16/34, 09/27, and 119/07. The apron on the southern side of runway 09/27 is home to Air India's maintenance facilities as well as the Air India terminal, while Indamer Aviation Pvt Ltd and Air Works India have their facilities on the apron to the south of runway 16/34.</p>

                <br/>

            <p>Two Royal Air Force hangars, which had been abandoned by the RAF, were transformed into a passenger terminal by the time the RAF started the process of turning over the airfield to the Director General of Civil Aviation in 1946 for civil operations. For internal and foreign traffic, respectively, each hangar served as a terminal. In the middle was a lounge, and on either side were counters for customs and immigration checks. Air India took care of passenger service in its first year. There were six civic services per day in its first year.</p>

            <br/>
            <p>The modernization associated with the partitioning of 1948 and the following creation of Pakistan led to an increased amount of air traffic at the airport, which quickly became too crowded and disparate. This led Indian officials to begin reconstructing the airport once more. With the growth of aviation services in Karachi, the responsibility for operating a new terminal was handed down from the Public Works Department to the Civil Aviation Ministry in 1958. The building you see today was built in 1962, named after Gandhi due to his influence on air travel as a lawyer and negotiator.</p>
            <br/>
            <p>
            A half-kilometer-long boardwalk connects the two terminals to the new terminal. The Airports Authority of India (AAI) will construct a new technological block that will improve the effectiveness of aircraft operations and offer improved flight control.
            </p>
            <br/>
            <h2 className='text-center mt-4 mb-4'><u>Chhatrapati Shivaji Maharaj International Airport</u></h2>
            <br/>
            <p>
            The main airport serving the greater Mumbai area is Chhatrapati Shivaji Maharaj Airport. Its previous name was Sahar International Airport.
            </p>
            <br/>
            <p>
            This airport comes in at number two on the list of busiest airports in terms of both total and international passenger traffic, just after Delhi. It is listed as the 28th busiest airport in the world and is ranked 14th in Asia. Even having just one runway open in 2017, it outperformed London Gatwick Airport in terms of passenger volume.
            </p>
            <br/>
            <p>
            Along with Delhi International Airport, this airport has been recognized by the Airports Council International as the best airport in the world. It has also been recognized as the best airport in India and Central Asia during the 2016 Skytrax World Airport Awards.
            </p>
            <br/>
            <p>
            It is one of the three airports in India that has implemented A-CDM (Airport Collaborative Decision Making) to ensure that flights land and take off on time. The airport won the Best Airport Award in 2019. Dimensions in ACI World.
            </p>
        </div>
    </div>
    
    </>
  )
}

export default Mumbai