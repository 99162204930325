import React from 'react'
import { useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const[ msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false)


  const [number, setNumber] = useState('')

  const foregetFunc = async (e)=>{
    e.preventDefault()
    setEmail('')

        if(email === '' || !email){
            setError(true);

        }else{

        try {
          setMsg('')
          setLoading(true)

            const res = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/forget`,{
                email: email.toLowerCase()
            });
           // console.log(res.data);
            setError(false);
            setMsg(res.data)
            setLoading(false)
            setEmail('')

            if(res.data.status === 200){
              setTimeout(()=>{
               navigate('/login')
              },1500)
             }
        
        }catch(err){
           // console.log('forget password error: ', err);
           setLoading(false)
          }
        }   
}

//! send details on mobile number

const sendUserDetailsHandler = async(e) =>{
  e.preventDefault()
  try{
    setMsg('')
    setLoading(true)

    if(number === '' || !number){
      setError(true);

  }else{
    const res  = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/sendsmsdetails`,{
      number
    })

    //console.log(res.data)
    setError(false);
    setMsg(res.data)
    setLoading(false)
    setEmail('')

    if(res.data.status === 200){
     setTimeout(()=>{
      navigate('/login')
     },1500)
    }

  }

  }catch(err){
    console.log(err)
  }
}

  return (
    <>
        <div className='forgot-main-div'>
            <div className='container'>
               <div className='forgot-pass-inner-div'>
                <div className='forgot-pass-title-div'>
                  <h3>Forgot Password ?</h3>
                </div>
                <div className='pb-3'>
                  <h6 className='fw-bold fs-5 text-muted'>Enter Your E-mail</h6>
                </div>
                <form onSubmit={foregetFunc}>
                <div className="input-group mb-3">
                  <input type="text" className="form-control " placeholder="Enter your registered email" aria-label="Enter your registered email" aria-describedby="button-addon2" onChange={(e)=> setEmail(e.target.value)} value={email}   required />
                  <button className="btn btn-success" type="submit" id="button-addon2" disabled={loading ? true : false}>Submit</button>
                </div>
                </form>
                <h5 className='text-center'>OR</h5>

                <form onSubmit={sendUserDetailsHandler}>
                <div className='pb-3'>
                  <h6 className='fw-bold fs-5 text-muted'>Enter Your Number</h6>
                </div>

                <div className="input-group mb-3">
                  <input type="number" className="form-control px-4" placeholder="Enter your registered mobile number" aria-label="Enter your registered mobile number" aria-describedby="button-addon2" onChange={(e)=> setNumber(e.target.value)} value={number} required />
                  <button className="btn btn-success" type="submit" id="button-addon2" disabled={loading ? true : false}>Submit</button>
                </div>
                </form>

                <div  style={{color: 'red', marginBottom: '10px'}}>{error && <span>Input field can not be empty</span>}</div>
                <div  style={{color: 'green', marginBottom: '10px'}}>{loading && <span><b>Please wait we'll try to send your details to your registered email address!</b></span>}</div>
                <div>
                {msg.status === 200 ? (
                <span style={{color: 'gray', marginTop: '10px'}}><b>{msg.message}</b></span>
                ) : (
                    <span style={{color: 'red', marginTop: '10px'}}><b>{msg.message}</b></span>
                )}
               </div>
               </div>
            </div>
        </div>
    </>
  )
}

export default ForgotPassword