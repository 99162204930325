import React from 'react'
import '../../styles/componentPage.css'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';



const GreenfieldsInfo = () => {
  return (
    <>
        <Helmet> 
     <title>Greenfield Airports || Bhartiya Aviation Services</title>
     <meta charSet='UTF-8'/>
       <meta name="keywords" content="Bhartiya Aviation Services,Aviation, Aviation Jobs, airport jobs, graound staff jobs"/>
       <meta name="description" content="Bhartiya Aviation Services is an ISO 9001:2015 Certified company & professional team managed by professionals with a combined experience of more than 40 years in aviation"/>
       <meta name="author" content="Bhartiya Aviation Services"/>
       <meta name="refresh" content="50"/>
       <meta property="og:image" content="/public/favicon_package_v0.16/apple-touch-icon.png"/>
       <meta http-equi="refresh" content="" url="https://bhartiyaaviation.in/"/>

       <meta property="og:type" content="website"/>
<meta property="og:url" content="https://bhartiyaaviation.in/"/>
<meta property="og:title" content="Bhartiya Aviation Services"/>
<meta property="og:description" content="Bhartiya Aviation Services is an ISO 9001:2015 Certified company & professional team managed by professionals with a combined experience of more than 40 years in aviation"/>
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>


<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://bhartiyaaviation.in/"/>
<meta property="twitter:title" content="Bhartiya Aviation Services"/>
<meta property="twitter:description" content="Bhartiya Aviation Services is an ISO 9001:2015 Certified company & professional team managed by professionals with a combined experience of more than 40 years in aviation"/>
<meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon_package_v0.16/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon_package_v0.16/favicon-16x16.png"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" href="/public/logo192.png" />
       <link rel='canonical' href='/greenfieldinfo' />
   </Helmet>

   <div className='container-fluid  top-banner-pg-div-about-us'>
         <div className='inner-pg-wrapper-div'>
             <div className='container ot-page-banner-heading'>
                 <h1>Greenfield Airports</h1>
                 <Link to={'/'}>
                 <p>
                  Home/ greenfield airport
                  </p>
                 </Link>
             </div>
         </div>
     </div>

    <div className='container'>
        <div className='green-field-img-div'>
            <img src="/images/airport-terminal.png" alt="" />
        </div>
        <div className='green-field-text'>
            
            <h3><u>Press Information <br/>Construction of Greenfield Airports</u></h3>

            <p>Bhartiya Aviation Services (BAS) is engaged in the aviation recruitment services which seeks to assist the aviation industry. GoI,MoCA has granted ‘in principle’ approval for setting up of 18 Greenfield Airports in the country. The list of these airport along with the estimated cost is as under: Mopa in Goa (approx. Rs. 3100 cr), Navi Mumbai (approx. Rs. 16704 cr), Shirdi (approx. Rs. 320.54 cr) and Sindhudurg (approx. Rs. 520 cr) in Maharashtra, Bijapur (approx. Rs. 150 cr), Gulbarga (approx. Rs. 13.78 cr in initial phase), Hasan (approx. Rs. 592 cr) and Shimoga (approx. Rs. 38.91 cr) in Karnataka, Kannur in Kerala (approx. Rs. 1892 cr), Durgapur in West Bengal (approx. Rs. 670 cr), Dabra in Madhya Pradesh (approx. Rs. 200 cr), Pakyong in Sikkim (approx. Rs. 553.53 cr), Karaikal in Puducherry (approx. Rs.170 cr), Kushinagar in Uttar Pradesh (approx. Rs. 448 cr), Dholera in Gujarat (approx. Rs. 1712 cr) and Dagadarthi Mendal, Nellore Dist. (approx. Rs. 293 cr), Bhogapuram in Vizianagaram District near Visakhapatnam (approx. Rs. 2260 cr) and Oravakallu in Kurnool District (approx. Rs. 200 cr), Andhra Pradesh. The timeline for construction of airport projects depends upon many factors such as land acquisition, availability of mandatory clearances, financial closure, etc. by the individual operator. The construction of airport project depends upon many factors such as land acquisition, availability of mandatory clearances, financial closure, etc. by the individual operator. The State Government of Maharashtra has completed the tendering process and M/s. MIAL is the highest bidder.
            During the last three years and the current year, MoCA, GoI has received the following proposals for setting up of Greenfield Airports: Ankleshwar in Gujarat, Bhiwadi (district Alwar) in Rajasthan, Dagadarthi (Nellore), Bhogapuram (Vizianagaram), Tadepalligudem (West Godavari), Oravakallu (Kurnool) and Kuppam (Chittor) in Andhra Pradesh, Chingleput near Chennai, Gwalior and Singrauli in Madhya Pradesh, Hisar in Haryana, Kothagudem (Khammam) in Telangana, Noida International Airport near Jewar, Saifai (Etawah) in Uttar Pradesh, Shivdaspur in Jaipur, Karwar and Chickmagaluru in Karnataka and Rajkot in Gujarat. GoI has granted ‘site clearance’ approval for setting up of Greenfield Airport at Bhiwadi (district Alwar) in Rajasthan, Kothagudem (Khammam) in Telangana, Rajkot in Gujarat and Noida International Airport near Jewar in Uttar Pradesh and ‘in principle’ approval for setting up of Dagadarthi (Nellore), Bhogapuram (Vizianagaram) and Oravakallu (Kurnool) in Andhra Pradesh.</p>

                <br/>

            <p><b>This information was given by the Minister of State for Civil Aviation Shri Jayant Sinha in a written reply to a question in Lok Sabha today.</b></p>

            <br/>
            <p>Forecasts from the key players in the aviation industry, including Airbus, Boeing, International Civil Aviation Organization (ICAO), International Air Transport Association (IATA), and the BC Aviation Council, is continued growth in all sectors of the aviation industry – both domestically and internationally. In the next ten years, this exciting industry is expected to see a major increase in capacity along with opportunities for airport jobs in entry level airport operations specialists worldwide.</p>
        </div>
    </div>

    </>
  )
}

export default GreenfieldsInfo