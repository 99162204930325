import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import '../styles/componentPage.css'

const Delhi = () => {
  return (
    <>
       <Helmet> 
     <title>Best Airline Careers In Delhi | Bhartiya Aviation Services</title>
     <meta charSet='UTF-8'/>
       <meta name="keywords" content="Best Airline Careers In Delhi, Top Airport job in Delhi"/>
       <meta name="description" content="Bhartiya Aviation Services offers you a top airport job in Delhi, now time to dream come true in the aviation industry. Call now at 8447-79-8447"/>
       <meta name="author" content="Bhartiya Aviation Services"/>
       <meta name="refresh" content="50"/>
       <meta property="og:image" content="/public/favicon_package_v0.16/apple-touch-icon.png"/>
       <meta http-equi="refresh" content="" url="https://bhartiyaaviation.in/airline-careers-and-airport-jobs-in-delhi"/>
       <meta property="og:type" content="website"/>
<meta property="og:url" content="https://bhartiyaaviation.in/airline-careers-and-airport-jobs-in-delhi"/>
<meta property="og:title" content="Best Airline Careers In Delhi | Bhartiya Aviation Services"/>
<meta property="og:description" content="Bhartiya Aviation Services offers you a top airport job in Delhi, now time to dream come true in the aviation industry. Call now at 8447-79-8447"/>
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>


<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://bhartiyaaviation.in/airline-careers-and-airport-jobs-in-delhi"/>
<meta property="twitter:title" content="Best Airline Careers In Delhi | Bhartiya Aviation Services"/>
<meta property="twitter:description" content="Bhartiya Aviation Services offers you a top airport job in Delhi, now time to dream come true in the aviation industry. Call now at 8447-79-8447"/>
<meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon_package_v0.16/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon_package_v0.16/favicon-16x16.png"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" href="/public/logo192.png" />
       <link rel='canonical' href='https://bhartiyaaviation.in/airline-careers-and-airport-jobs-in-delhi' />
   </Helmet>

   <div className='container-fluid  top-banner-pg-div-about-us'>
         <div className='inner-pg-wrapper-div'>
             <div className='container ot-page-banner-heading'>
                 <h1>Delhi</h1>
                 <Link to={'/'}>
                 <p>
                  Home/ delhi
                  </p>
                 </Link>
             </div>
         </div>
     </div>

     <div className='container'>
        <div className='green-field-img-div'>
            <img src="/images/delhi.png" alt="" />
        </div>
        <div className='green-field-text'>
            
            <h2 className='text-center mt-4 mb-4'><u>A History of Indira Gandhi International Airport (IGI)</u></h2>

            <p>Construction on Safdarjung Airport began in 1930, and it served as Delhi's primary airport until 1962. Civil operations were transferred to Palam Airport in 1962 as a result of an increase in passenger traffic at Safdarjung, which had been constructed during World War II. After the British left India, the Indian Air Force used Palam Airport as an air force base.</p>

                <br/>

            <p>Palam Airport has a maximum capacity of 1,300 passengers per hour. It handled three million domestic and foreign passengers from 1979–1980. An expanded terminal with an area almost four times that of the previous Palam terminal was built in the 1970s and 1980s as a result of the rise in aviation traffic. On May 2, 1986 (IGIA), the day this brand-new international facility opened, the airport was called Indira Gandhi International Airport (IGIA).</p>

            <br/>
            <p>To create three distinct buildings, 1A, 1B, and 1C, the existing domestic airport (Palam) was renamed Terminal 1. Domestic operations were handled in Block 1C while overseas operations were handled in Blocks 1A and 1B. Block 1A, which was formerly the only terminal for domestic Air India, has since been dismantled. All other domestic airlines used Block 1B as their departure terminal after it had been demolished. All low-cost airlines now use domestic departure block D instead of block C which had been changed into a domestic arrival terminal for VIP travelers. For VIP travelers there is also a dedicated technical section but Terminal C was replaced with Terminal D which had been rebuilt with a brand-new arrivals hall.</p>
            <br/>
            <p>
            A half-kilometer-long boardwalk connects the two terminals to the new terminal. The Airports Authority of India (AAI) will construct a new technological block that will improve the effectiveness of aircraft operations and offer improved flight control.
            </p>
            <br/>
            <h2 className='text-center mt-4 mb-4'><u>How does Indira Gandhi International Airport manage its operations?</u></h2>
            <br/>
            <p>
            The modern international airport in Delhi, the Indian capital, is called Indira Gandhi International Airport.   The former Indian Prime Minister Indira Gandhi, who ruled from the year of 1947 until 1984, is honored by the name of a company. The airport covers a land area of 5,106 acres in Delhi and has been the busiest airport in India since 2009. Additionally, it has surpassed Mumbai as the busiest airport in the country since the end of 2015. It is one of the busiest freight airports in the world and outperforms Mumbai as an airline hub. With a seating capacity of 3,611,181 seats, it is currently among the busiest airports in the world and Asia's second busiest airport after Beijing Capital International Airport.
            </p>
            <br/>
            <p>Prior to being managed by the Airports Authority of India, the airport was first run by the Indian Air Force. The GMR Group-led consortium, Delhi International Airport Limited (GMR), was given control of the airport's management in May 2006. The airport opened its 4,430-meter runway in September 2008, making it the longest in South Asia and India. With a capacity to hold 34 million passengers a year when it was finished, Terminal 3 was the eighth-largest passenger terminal in the world. To assist maintain takeoffs and landings on schedule and predictable, it employs a cutting-edge technique known as airport collaborative decision making.</p>
        </div>
    </div>
    
    </>
  )
}

export default Delhi