import React from 'react'
import Header from './Header/index';
import Footer from './Footer/index'
//import Whatsapp from '../components/Whatsapp.jsx'
import { FloatingWhatsApp } from 'react-floating-whatsapp'


const index = (props) => {
  return (
    <div className={props.children}>
        <Header/>
        {props.children}
        <FloatingWhatsApp className='d-print-none' phoneNumber="8447798447" accountName="Bhartiya Aviation Services" avatar="/images/logo.jpeg"/>
        {/* <Whatsapp/> */}
        <Footer/>
    </div>
  )
}

export default index