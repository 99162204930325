import React, { useState,useRef } from 'react'
import '../styles/admicard.css'
import { usePDF } from 'react-to-pdf';
import axios from 'axios'

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import QRCode from "react-qr-code";


const AdmitCardLoader = () => {

    
//! take  image screentshot start
    // const captureScreenshot = () => {
    //     const element = document.getElementById('element-to-capture');
    
    //     html2canvas(element).then((canvas) => {
    //       // Convert canvas to image and download
    //       const screenshotImage = canvas.toDataURL('image/png');
    //       const link = document.createElement('a');
    //       link.href = screenshotImage;
    //       link.download = 'admitcard.png';
    //       link.click();
    //     });
    //   };
    
    //   const elementToCaptureRef = useRef(null);

      //! take  image screentshot end

   //* take  image screentshot and convert into pdf start

    const [imagesLoaded, setImagesLoaded] = useState(false);
    const elementToCaptureRef = useRef(null);

  const handleImageLoad = () => {
    setImagesLoaded(true);
  };

  const captureScreenshot = () => {
    setDownload(true)
    if (!imagesLoaded) {
     alert('Images are still loading. Wait for all images to load before downloading the admitcard');
      return;
    }

    const element = elementToCaptureRef.current;

    html2canvas(element, { scrollY: -window.scrollY, useCORS: true, allowTaint: true, autoPaging: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF();
    //   const width = pdf.internal.pageSize.getWidth();
    //   const height = pdf.internal.pageSize.getHeight();
    //   //const height = 390

    //   pdf.addImage(imgData, 'PNG', 0, 0, width, height);
    const pdf = new jsPDF({
        unit: 'mm',
        format: [canvas.width * 0.264583, canvas.height * 0.264583], // 1mm = 0.264583px
        orientation: 'portrait',
        compress: true,
        hotfixes: [], // Add any required hotfixes
      });

      // Add the image to the first page
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width * 0.264583, canvas.height * 0.264583);

    //   pdf.addPage()

    //   const remainingHeight = canvas.height - height;

    //   pdf.addImage(imgData, 'PNG', 0, -remainingHeight, width, canvas.height);

      pdf.save('admitcard.pdf');
      setDownload(false)
    });


  };

 //* take  image screentshot and convert into pdf end
 // eslint-disable-next-line
    const { toPDF, targetRef } = usePDF({filename: 'BAS_admit-card_2023.pdf'});

    const [download, setDownload] = useState(false)

    const [registrationNumber, setRegistrationNumber] = useState('')
    const [candidateName , setCandidateName] = useState('')
    const [dob, setDob] = useState('')
    const [applicationID, seApplicationID] = useState('')
    const [loading, setLoading] = useState(false)
    const [errMsg, setErrMsg] = useState()
    const [data,setData] = useState()


    const fetchAdmitcardHandler = async(e)=>{
        e.preventDefault()
        try{
         
            setLoading(true)
            const res  = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/findloaderadmitcard`,{
                applicationid: applicationID,
                registrationNumber: registrationNumber
            })
           //console.log(res.data.admitcard)
            setData(res.data.admitcard)
            setErrMsg('')
            seApplicationID('')
            setDob('')
            setCandidateName('')
            setRegistrationNumber('')
            setLoading(false)
        }catch(err){
            console.log(err.response.data.message)
            setErrMsg(err.response.data.message)
            setData()
            setLoading(false)
        }
    }

    const uploadPhoto =  `${process.env.REACT_APP_HOSTURL}/api/v1/${data?.uploadphoto}`
    const uploadSignature =  `${process.env.REACT_APP_HOSTURL}/api/v1/${data?.uploadsignature}`

  return (
    <>
    <div className='print-container'>
    <div className='container d-print-none form-admit-ciortainer-div'>
        <div className='candidate-form-container '>
        <form onSubmit={fetchAdmitcardHandler}  style={{width: '60%'}} className='shadow admitcard-form_container-div border '>
            <h6 className='text-muted'>Loader / Housekeeping</h6>
        <div className="card" >
        <ul className="list-group list-group-flush">
            <li className="list-group-item">Registration No: <input className='form-control' type="number"  placeholder='Type here...' onChange={(e)=> setRegistrationNumber(e.target.value)}  required value={registrationNumber} /> </li>
            <li className="list-group-item">Candidate Name: <input className='form-control' type="text"  placeholder='Type here...' onChange={(e)=> setCandidateName(e.target.value)}  required value={candidateName}  /> </li>
            <li className="list-group-item">D.O.B: <input className='form-control' type="date"  placeholder='Type here...'  onChange={(e)=> setDob(e.target.value)}  required value={dob} /> </li>
            <li className="list-group-item">Application No: <input className='form-control' type="number"  placeholder='Type here...' onChange={(e)=> seApplicationID(e.target.value)}  required value={applicationID} /> </li>
        </ul>
        <div className='loading-Cohntaionr-fropm0-dv'>
        {loading && <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
        </div>
        <button className='btn btn-primary admit_submit_btn' type='submit' disabled={loading ? true : false}>{loading ? 'Loading...' : 'Submit'}</button>
        </div>
        </form>
        
        {errMsg && <div style={{margin: '20px 0px', textAlign: 'center'}}>
            <p style={{color: 'red', fontWeight: 'bold'}}>{errMsg}</p>
            </div>}

        </div>
    </div>


    {data && <div className='container print-container-div-full' ref={elementToCaptureRef} id="element-to-capture">
        <div className='admitCard_inr_wraaper'>
            <div className='admit-inrt-pdfcontainer'>
            <div className='admitMainCardContainrt'>
                    <div className='admit-card-top-div'>
                        <div className='admit-top-left-add-div'>
                            <h5><b>भारतीय एविएशन सर्विसेज </b></h5>
                            <p>प्लॉट नंबर 18,  टॉप फ्लोर, सेवक पार्क</p>
                            <p>ग्राम सभा, द्वारका मोड़, मेट्रो पिलर नंबर</p>
                            <p>773 के पास</p>
                            <p>नई दिल्ली 110059</p>
                            <p>वेबसाइट: www.bhartiyaaviation.in</p>
                            <p>ईमेल: info@bhartiyaaviation.in</p>
                            <p>हेल्पलाइन नं: 8447588447, 8447798447</p>
                        </div>
                        <div className='admit-top-mif-logo-div'>
                            <img src="/images/logo.jpeg" width={140}  alt="" />
                        </div>
                        <div className='admit-top-right-add-div'>
                        <h5><b>Bhartiya Aviation Services</b></h5>
                        <p>Plot No 18, Top Floor, Sevak Park,</p>
                        <p>Near Gram Sabha, Dwarka Mod,</p>
                        <p>Metro Pillar No 773. New Delhi</p>
                        <p>110059</p>
                        <p>website:  www.bhartiyaaviation.in</p>
                        <p>E-mail: info@bhartiyaaviation.in</p>
                        <p>Helpline No: 8447588447, 8447798447</p>
                        </div>
                    </div>

                    <div className='admit-mid-heading-ttl-container'>
                        <div className='admitcard-subjetc-cnt-div'>
                        <p><b>ई-एडमिट कार्ड / e-admit card</b></p>
                        <p><b>लोडर / हाउसकीपिंग - 2022-23</b></p>
                        <p><b>Loader / Housekeeping - 2022-23</b></p>
                        </div>
                    </div>

                    <div className='admt-crdrollNumber-Container'>
                        <div className="reg-admtcrd-upr-dv">
                        <div className='admt-crd-left-roln-dv'>
                        <p><b>अनुक्रमांक</b></p>
                        <p><b>Roll No: {data?.rollno ? data?.rollno : '-'}</b></p>
                        </div>
                        <div className='admt-rght-div-cntr'>
                        <p><b>आवेदन संख्या</b></p>
                        <p><b>Application No: {data?.applicationid}</b></p>
                        </div>
                        </div>

                        <div className='admt-crd-mid-btm-dvd'>
                            <div className='admt-crd-examdt-div'>
                                <h6>परीक्षा तिथि:</h6>
                                <p><b>Exam Date : 10-12-2023</b></p>
                            </div>
                            <div className='admt-crd-report-tmmd-div'>
                                <h6>उपस्थित होने का समय:</h6>
                                <p><b>Reporting Time: 11:30 AM in Morning shift</b></p>
                            </div>
                            <div className="entry-cls-time-dv-cntr">
                                <h6>प्रवेश बंद होने का समय: </h6>
                                <p><b>Entry Closing Time: 12:30 PM. in Afternoon shift</b></p>
                            </div>
                        </div>
                    </div>



            </div>
            <div className='admt-crd-candidt-main-container'>
                <div className='admt-letfcondaidate-border-dv'>
                   <div className='main-rght-admt-left-btm-cnf-dc '>
                    <div className='admt-crd-appli-name-dv'>
                    <h6>आवेदक का नाम </h6>
                    <h6>Applicant Name : {data?.firstname} {data?.lastname}</h6>
                    </div>

                    <div className='applicadmt-ling-dv'>
                    <h6>लिंग</h6>
                        <h6>Sex: {data?.gender}</h6>
                    </div>
                   </div>
                   <div className='main-rght-admt-left-btm-cnf-dc '>
                    <div className='admt-crd-appli-name-dv'>
                    <h6>जन्म तिथि </h6>
                    <h6>Date Of Birth : {data?.dob}</h6>
                    </div>

                    <div className='applicadmt-ling-dv'>
                    <h6>श्रेणी</h6>
                        <h6>Category: {data?.category}</h6>
                    </div>
                   </div>

                   <div className='adtm-card-addddsdres-int-rdv '>
                        <p><b>पता</b></p> 
                        <p><b>Address: </b>{`${data?.addresspresentstreet} ${data?.addresspresenthouse} ${data?.addresspresentvillage} ${data?.addresspresentcity} ${data?.addresspresentpostoffice}, ${data?.addresspresentstate} ${data?.addresspresentdistrict}, ${data?.Addresspresentpincode} `}</p> 
                        
                   </div>
                </div>

                <div className='admt-rght-cnt-img-sign-dv'>
                   <div className='admit-crd-image-photo-dv'>
                    <img style={{objectFit: 'cover'}} src={data?.uploadphoto ? uploadPhoto : '/images/demodp.png'} width={120} onLoad={handleImageLoad}  alt="profile" />  

                    <div className='admt-card-sign-btm-dv'>
                    <img  src={data?.uploadsignature ? uploadSignature : '/images/demosign.png'} alt="sign" width={150} height={50} onLoad={handleImageLoad}  style={{objectFit: 'contain'}} />
                    </div>   
                   </div>

                </div>
            </div>

            <div className='admt-card-exam-timing-container-dv'>
                    <div className='afternoot-atiming-date'>
                        <p><u><b>Time: (12:30 PM to 02:00 PM)</b></u></p>
                        <img className='basstamp-img' src="/images/basstamp.png" width={100} alt="" />
                    </div>

                    <div className='admt-card-examniation-cenrt-dv'>
                       <div className='exm-hall-cnt-dv-inert-cntr'>
                       <p><b>परीक्षा केंद्र / Examination Venue:</b></p>
                        <p className='vanue-address-text'><b>{`${data?.vanuename}, ${data?.vanueaddress}, ${data?.vanuepincode}`}</b></p>
                        <a className='googlemap-link' href={`${data?.googlemaplink ? data?.googlemaplink : "#"}`}>Venue Location (click here to get location)</a>

                        <div className='my-3' style={{ height: "auto", margin: "0 auto", maxWidth: 100, width: "100%" }}>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={data?.googlemaplink}
                            viewBox={`0 0 256 256`}
                        />
                        </div>
                        <p>Scan to get venue location</p>

                       </div>
                    </div>
                </div>
 

                <div className='admt-card-instruction'>
                    <p className='text-center'><u><b>IMPORTANT INSTRUCTION FOR CANDIDATES</b></u></p>
                    <ul className='instructionlistadmrtcard-div-ul'>
                        <li><p className=' m-2'><b>Candidates must bring two copies of the admit card with them at the time of examination, one copy will be deposited at the examination venue and the other copy should be taken with them after the examination.( अभ्यर्थियों को परीक्षा के समय एडमिट कार्ड की दो फोटोकॉपी अपने साथ लानी होंगी, एक कॉपी परीक्षा स्थल पर जमा कर दी जाएगी और दूसरी कॉपी परीक्षा के बाद अपने साथ ले जानी होगी।)</b></p>
                        </li>

                        <li><p>No candidate shall be permitted to enter after the Gate Closing Time.(  गेट बंद होने के समय के बाद किसी भी उम्मीदवार को प्रवेश की अनुमति नहीं दी जाएगी)</p></li>
                        <li><p>No candidate shall be permitted to leave the Examination Room/ Hall before the end of the examination.( किसी भी अभ्यर्थी को परीक्षा समाप्त होने से पहले परीक्षा कक्ष/हॉल छोड़ने की अनुमति नहीं दी जाएगी)</p></li>
                        <li><p>On completion of the examination, please wait for instructions from Invigilator and do not get up from your seat until advised.( परीक्षा पूरी होने पर, कृपया पर्यवेक्षक के निर्देशों की प्रतीक्षा करें और सलाह दिए जाने तक अपनी सीट से न उठें।)</p></li>
                        <li><p>No Candidate would be allowed to enter the Examination Centre, without Admit Card, Valid ID Proof.(  किसी भी उम्मीदवार को एडमिट कार्ड, वैध आईडी प्रमाण के बिना परीक्षा केंद्र में प्रवेश की अनुमति नहीं दी जाएगी)</p></li>
                        <li><p>Candidates are advised to carry only the following items with them into the examination venue:( अभ्यर्थियों को सलाह दी जाती है कि वे परीक्षा स्थल पर अपने साथ केवल निम्नलिखित वस्तुएं ही ले जाएं)</p>
                        <ul className='admt-crd-six-pint-inr'>
                            <li>Admit Card downloaded from the Bhartiya Aviation Services website (a clear printout on A4 size paper) duly filled in. (भारतीय एविएशन सर्विसेज वेबसाइट से डाउनलोड किया गया प्रवेश पत्र (ए4 आकार के कागज पर एक स्पष्ट प्रिंटआउट) विधिवत भरा हुआ)</li>
                            <li> A simple transparent Ball Pen (एक साधारण  पारदर्शी बॉल  पेन)</li>
                            <li>Additional photograph (अतिरिक्त फोटो)</li>
                        </ul>

                        </li>
                        <li>Candidate must carry “Any one of the original and valid Photo Identification Proof issued by the government” ( उम्मीदवार को  सरकार द्वारा जारी मूल और वैध फोटो पहचान प्रमाण में से कोई एक" लाना होगा।)

                            <ul className='admrt-seven-list-twg-dv'>
                                <li>Passport. (पासपोर्ट)</li>
                                <li>Driving license. (ड्राइविंग लाइसेंस)</li>
                                <li> Voter ID Card. (वोटर आई कार्ड)</li>
                                <li>Pan Card (पैन कार्ड)</li>
                                <li>AADHAR Card (UID) (आधार कार्ड (यूआईडी))</li>
                            </ul>
                        </li>

                        <li>Candidates are <b>NOT allowed</b> to carry any personal belongings including electronic devices, mobile phone and other banned/ prohibited items listed in the Information Bulletin to the Examination Centre. Examination Officials will not be responsible for safe keep of personal belongings and there will be no such facility.(उम्मीदवारों को परीक्षा केंद्र में इलेक्ट्रॉनिक उपकरण, मोबाइल फोन और सूचना बुलेटिन में सूचीबद्ध अन्य प्रतिबंधित/निषिद्ध वस्तुओं सहित कोई भी व्यक्तिगत सामान ले जाने की अनुमति नहीं है। परीक्षा अधिकारी व्यक्तिगत सामान को सुरक्षित रखने के लिए जिम्मेदार नहीं होंगे और ऐसी कोई सुविधा नहीं होगी)</li>

                        <li>Blank paper sheets for rough work will be provided in the examination Hall/Room. Candidates must write their name and Roll Number at the top of the sheet and must drop in the designated drop box without fail, before leaving the examination Hall/Room. Failure to do so may result in non-evaluation of your answers.( रफ कार्य के लिए खाली पेपर शीट परीक्षा हॉल/कक्ष में उपलब्ध कराई जाएंगी। उम्मीदवारों को अपना नाम और रोल नंबर शीट के शीर्ष पर लिखना होगा और परीक्षा हॉल/कक्ष छोड़ने से पहले इसे निर्धारित ड्रॉप बॉक्स में डालना होगा। ऐसा न करने पर आपके उत्तरों का मूल्यांकन नहीं हो सकेगा)</li>

                        <li>The ink should not be spread outside the circle and neither the OMR. Make any mark on the sheet.(स्याही को गोले से बाहर नहीं फैलाना चाहिए और न ही ओएमआर शीट पर कोई भी निशान बनाओ)</li>
                        <li>Unfair means such as talking among themselves, keeping copycat material together is a punishable offence. If you are caught cheating in the exam, your candidature will be cancelled. (अनुचित साधन जैसे आपस में बातचीत करना, नकल सामग्री एक साथ रखना दंडनीय अपराध है। अगर आप परीक्षा में नकल करते हुए पकड़े गए तो आपकी उम्मीदवारी रद्द कर दी जाएगी)</li>
                        <li>Candidates are advised to check updates on Bhartiya Aviation Services website regularly. They should also check their mailbox on the registered E-mail and SMS in their registered Mobile No. for latest updates and information. (उम्मीदवारों को सलाह दी जाती है कि वे भारतीय  एविएशन सर्विसेज वेबसाइट पर नियमित रूप से अपडेट जांचते रहें। उन्हें नवीनतम अपडेट और जानकारी के लिए पंजीकृत ई-मेल  पर अपना मेलबॉक्स और अपने पंजीकृत मोबाइल नंबर पर एसएमएस भी जांचना चाहिए)</li>
                    </ul>
                </div>


            </div>
            

        </div>

    </div>}

   {data &&  <div className='text-center mt-4 pb-4 pdf-download-div d-print-none'>
        <button className='btn btn-success m-4  d-print-none' onClick={()=> window.print()}>Print Admit Card</button>
        <button className='btn btn-warning m-4 d-print-none' onClick={captureScreenshot}>{download? 'Downloading...': 'Download PDF'}</button>
     
        </div>}
    </div>
    </>
  )
}

export default AdmitCardLoader