import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

 // eslint-disable-next-line
import { FaBoxes, FaUsers, FaUserTimes, FaUserCheck,FaBoxOpen,FaShoppingBag, FaBox, FaHome,FaUserShield,FaBell } from "react-icons/fa";
 // eslint-disable-next-line
import { MdAttachMoney,MdAirplanemodeActive } from "react-icons/md";
import './sidebar.css'

const Sidebar = () => {

  const router = useLocation()

  return (
    <>
        <div className='sidebar-main-container'>
           <div className='container  pb-5'>
            <div className='sidebar-list-heading'>
              <h5>Home</h5>
              <div className={router.pathname === '/basadmincontrol' ? 'inner-sidebarmenu-product active': 'inner-sidebarmenu-product'}>
              <Link  to={'/basadmincontrol'}>
               <p><FaHome className='sidebar-list-icon'/>Home</p>
              </Link>
              </div>
            </div>
            <div className='sidebar-list-heading'>
              <h5>Forms</h5>
              <div className={router.pathname === '/dbappforms' ? 'inner-sidebarmenu-product active': 'inner-sidebarmenu-product'}>
              <Link to={'/dbappforms'}>
               <p><FaBoxes className='sidebar-list-icon'/>Application Forms</p>
              </Link>
              </div>
            </div>
            <div className='sidebar-list-heading'>
              <div className={router.pathname === '/dbcreatepost' ? 'inner-sidebarmenu-product active': 'inner-sidebarmenu-product'}>
              <Link to={'/dbcreatepost'}>
               <p><MdAirplanemodeActive className='sidebar-list-icon'/>Create Posts</p>
              </Link>
              </div>
            </div>
            <div className='sidebar-list-heading'>
            <h5>Users</h5>
              <div className='sidebar-menu-vendors'>
                {/* <p>Show Vendors</p> */}
                <div className={router.pathname === '/allregusers' ? 'inner-sidebarmenu-product active': 'inner-sidebarmenu-product'}>
                <Link to={'/allregusers'}>
                <p><FaUsers className='sidebar-list-icon'/>Registered Users</p>
                </Link>
                </div>
              </div>
            </div>
            <div className='sidebar-list-heading'>
              <h5>Query</h5>
              <div className={router.pathname === '/dbcontactquery' ? 'inner-sidebarmenu-product active': 'inner-sidebarmenu-product'}>
              <Link to={'/dbcontactquery'}>
               <p><FaBox className='sidebar-list-icon'/>Contact Queries</p>
              </Link>
              </div>
            </div>
            <div className='sidebar-list-heading'>
              <h5>Notifications</h5>
              <div className={router.pathname === '/dbnotify' ? 'inner-sidebarmenu-product active': 'inner-sidebarmenu-product'}>
              <Link to={'/dbnotify'}>
               <p><FaBell className='sidebar-list-icon'/>Upload Notification</p>
              </Link>
              </div>
            </div>
           </div> 
        </div>
    </>
  )
}

export default Sidebar