import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { ExportJsonCsv } from "react-export-json-csv";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
const { Search } = Input;

const DBAppForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payLoading,setPayloading] = useState(false)
  const [changeAppFormStatus, setChangeAppFormStatus] = useState();
  const [errMsg, setErrMsg] = useState("");
  const [applicationFormStatusData, setApplicationFormStatus] = useState([]);
  const [data, setData] = useState([]);

  const [totalPaidCount, setTotalPaidCount] = useState("");

  const [formsByState, setFormByState] = useState([]);
  const [formStLoading, setFormStLoading] = useState(false);

  const [csvLoading, setCSVLoading] = useState(false);
  const [allFormsData, setAllFormsData] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [formsPaymentStatus, setFormsPaymentSatus] =
    useState("getonlypaidform");

  const updateFormStatus = (status) => {
    setFormsPaymentSatus(status);
  };

  //!generate compact number
  const formatter = Intl.NumberFormat("en", {
    notation: "compact",
  });

  //const compNum = formatter.format(1234);
  //console.log(compNum);

  const fetchAllFormByState = async () => {
    setFormStLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_HOSTURL}/api/v1/getallformstats`
      );
      //console.log(res.data.data);

      setFormByState(res.data.data);
      setFormStLoading(false);
    } catch (err) {
      //console.log(err)
    }
  };

  //!pagination

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [itemsPerpage, setItemsPerPage] = useState(11);

  const indexOfLastitems = currentPage * itemsPerpage;
  const indexOfFirstItems = indexOfLastitems - itemsPerpage;
  const currentItems = formData?.slice(indexOfFirstItems, indexOfLastitems);
  // eslint-disable-next-line
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const pages = [];

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  };

  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let increamentBtn = null;
  if (pages.length > maxPageNumberLimit) {
    increamentBtn = <li onClick={handleNextBtn}> &hellip; </li>;
  }

  for (let i = 0; i <= Math.ceil(formData.length / itemsPerpage); i++) {
    pages.push(i);
  }

  const renderPageNumber = pages?.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "pagin-active" : "pagin-li"}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  function paginationNextHandler() {
    setPage((page) => page + 1);
    setLimit((limit) => limit + 10);
    // if(page < 1){

    // }else{
    //   return
    // }
  }

  function paginationBackHandler() {
    setPage((page) => page - 1);
    setLimit((limit) => limit - 10);
  }

  //!pagination end

  //! Export To Excel Start

  const headers = [
    {
      key: "formId",
      name: "Form ID",
    },

    {
      key: "userId",
      name: "User ID",
    },
    {
      key: "registerId",
      name: "Registration ID",
    },
    {
      key: "applicationId",
      name: "Application ID",
    },

    {
      key: "fname",
      name: "First Name",
    },
    {
      key: "lastname",
      name: "Last Name",
    },
    {
      key: "fatherHusbandName",
      name: "Father/Husband Name",
    },

    {
      key: "motherName",
      name: "Mother Name",
    },
    {
      key: "dob",
      name: "Date Of Birth",
    },
    {
      key: "gender",
      name: "Gender",
    },
    {
      key: "categoryCast",
      name: "Categories Cast",
    },
    {
      key: "maritalStatus",
      name: "Marital Status",
    },
    {
      key: "postapply",
      name: "Post Apply",
    },
    {
      key: "price",
      name: "Application Fees",
    },
    {
      key: "postId",
      name: "Post ID",
    },
    {
      key: "email",
      name: " E-mail",
    },
    {
      key: "mobile",
      name: " Mobile",
    },
    {
      key: "educationalqualification",
      name: "Educational Qualification",
    },

    {
      key: "educationaldivision",
      name: "Educational Division",
    },
    {
      key: "intermediate",
      name: "Intermediate",
    },
    {
      key: "intermediatedivision",
      name: "Intermediate Divison",
    },
    {
      key: "highschool",
      name: "High School",
    },
    {
      key: "highschooldivision",
      name: "High School Division",
    },
    {
      key: "uploadPhoto",
      name: "Upload Photo",
    },

    {
      key: "firebasestudentprofile",
      name: "Firebase Profile Image",
    },

    {
      key: "uploadSignature",
      name: "Upload Signature",
    },

    {
      key: "firebasestudentsignature",
      name: "Firebase Signature Image",
    },

    {
      key: "add_Present_Street",
      name: "Address Present Street",
    },
    {
      key: "add_Present_houseno",
      name: "Address Present houseno",
    },
    {
      key: "add_Present_village",
      name: "Address Present village",
    },
    {
      key: "add_Present_city",
      name: "Address Present city",
    },
    {
      key: "add_Present_postoffice",
      name: "Address Present postoffice",
    },
    {
      key: "add_Present_state",
      name: "Address Present state",
    },
    {
      key: "add_Present_district",
      name: "Address Present district",
    },
    {
      key: "add_Present_pincode",
      name: "Address Present pincode",
    },

    {
      key: "sameAdd",
      name: "Same Address",
    },
    {
      key: "declaration",
      name: "Declaration",
    },

    {
      key: "addPermanent_PR_Street",
      name: "Address Permanent Street",
    },
    {
      key: "addPermanent_PR_houseno",
      name: "Address Permanent houseno",
    },
    {
      key: "addPermanent_PR_village",
      name: "Address Permanent village",
    },
    {
      key: "addPermanent_PR_city",
      name: "Address Permanent city",
    },
    {
      key: "addPermanent_PR_postoffice",
      name: "Address Permanent postoffice",
    },
    {
      key: "addPermanent_PR_state",
      name: "Address Permanent state",
    },
    {
      key: "addPermanent_PR_district",
      name: "Address Permanent district",
    },
    {
      key: "addPermanent_PR_pincode",
      name: "Address Permanent pincode",
    },
    {
      key: "examCentreState",
      name: "Exam Centre State",
    },
    {
      key: "examCentreCity",
      name: "Exam Centre City",
    },

    {
      key: "paymentId",
      name: "Payment ID",
    },
    {
      key: "paymentStatus",
      name: "Payment Status",
    },

    {
      key: "createdAt",
      name: "Created At",
    },
    {
      key: "updatedAt",
      name: "Updated At",
    },
  ];

  const datas = [{}];

  allFormsData?.map((a, index) => {
    let obj = {
      formId: a._id,
      userId: a.userId,
      registerId: a.registrationNumber,
      applicationId: a.applicationnumber,
      fname: a.candidatename,
      lastname: a.lastname,
      fatherHusbandName: a.fatherhusbandname,
      motherName: a.mothername,
      dob: a.dateofbirth,
      gender: a.gender,
      categoryCast: a.categorycast,
      maritalStatus: a.maritalstatus,
      postapply: a.postapply,
      price: a.price,
      postId: a.postId,
      email: a.email,
      mobile: a.mobile,
      educationalqualification: a.educationalqualification,
      educationaldivision: a.educationdivision,
      intermediate: a.intermediate,
      intermediatedivision: a.intermediatedivision,
      highschool: a.highschool,
      highschooldivision: a.highschooldivision,
      uploadPhoto: a.uploadphoto,
      firebasestudentprofile: a.firebasestudentprofile,
      uploadSignature: a.uploadsignature,
      firebasestudentsignature: a.firebasestudentsignature,
      add_Present_Street: a.address.present.street,
      add_Present_houseno: a.address.present.houseno,
      add_Present_village: a.address.present.village,
      add_Present_city: a.address.present.city,
      add_Present_postoffice: a.address.present.postoffice,
      add_Present_state: a.address.present.state,
      add_Present_district: a.address.present.district,
      add_Present_pincode: a.address.present.pincode,
      sameAdd: a.isPresent,
      declaration: a.isDeclaration,
      addPermanent_PR_Street: a.address.permanent.pr_street,
      addPermanent_PR_houseno: a.address.permanent.pr_houseno,
      addPermanent_PR_village: a.address.permanent.pr_village,
      addPermanent_PR_city: a.address.permanent.pr_city,
      addPermanent_PR_postoffice: a.address.permanent.pr_postoffice,
      addPermanent_PR_state: a.address.permanent.pr_state,
      addPermanent_PR_district: a.address.permanent.pr_district,
      addPermanent_PR_pincode: a.address.permanent.pr_pincode,
      paymentId: a.paymentId,
      paymentStatus: a.paymentStatus,
      examCentreState: a.examCentreState,
      examCentreCity: a.examCentreCity,
      createdAt: a.createdAt,
      updatedAt: a.updatedAt,
    };
    datas.push(obj);
    return a;
  });
  //! Export To Excel End

  const changeAppFormSta = async (e) => {
    e.preventDefault();

    if (changeAppFormStatus === "none" || changeAppFormStatus === undefined) {
      return setErrMsg("Please Select an option !!");
    } else {
      try {
        const appFormStatusId = applicationFormStatusData[0]._id;
        const res = await axios.patch(
          `${process.env.REACT_APP_HOSTURL}/api/v1/changeappformstattus/${appFormStatusId}`,
          {
            applicationStatus: changeAppFormStatus,
          }
        );
        //  console.log(res.data.message);
        setErrMsg(res.data.message);
        window.location.reload();
      } catch (err) {
        //console.log(err)
      }
    }
  };

  const getSingleFromData = (e) => {
    e.preventDefault();
    //console.log(e.target.id);

    let listId = e.target.id;

    if (listId) {
      const id = listId;
      navigate(`/myapplication/${id}`, {
        state: {
          id: id,
        },
      });
    }
    // console.log('list-ID', listId);
  };

  //!load all CSV Excel Form data

  const fetchAllFormsDataCSV = async () => {
    setCSVLoading(true);
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_HOSTURL
        }/api/v1/formdata?page=${1}&limit=${999999999}`
      );
      setAllFormsData(res.data.body);
    } catch (err) {
      alert(err.response.data.message);
    } finally {
      setCSVLoading(false);
    }
  };

  useEffect(() => {
    const fetchAllFormData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_HOSTURL}/api/v1/${formsPaymentStatus}?page=${page}&limit=${limit}`
        );
        // console.log(res.data.body);
        //  setFormCount(res.data?.body?.length)
        setTotalPaidCount(res.data.total);
        setFormData(res.data.body);
        setLoading(false);
      } catch (err) {
        //console.log(err)
      }
    };

    const getAppFormStatus = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_HOSTURL}/api/v1/changeappformstattus`
        );
        //console.log(res.data.data);
        setApplicationFormStatus(res.data.data);
      } catch (err) {
        //console.log(err)
      }
    };
    fetchAllFormByState();
    getAppFormStatus();
    fetchAllFormData();
  }, [formsPaymentStatus, page, limit]);

  //console.log(applicationFormStatusData)

  const onSearch = async (value) => {
    let newValue;
    if (!isNaN(value)) {
      // If the input is a valid number, convert it to a number
      newValue = Number(value);
    } else {
      // If the input is not a valid number, keep it as a string
      newValue = value;
    }

    if (!newValue) {
      alert("Search field not be empty!");
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_HOSTURL}/api/v1/searchform`,
        {
          title: newValue,
        }
      );
      console.log(res.data.body);
      setFormData(res.data.body);
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
    }
  };

  //!update form

  const updateFormPaymentStatus = async (id, paymentstatus, paymentid) => {
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_HOSTURL}/api/v1/updatepayment`,
        {
          id: id,
          paymentstatus: paymentstatus,
          paymentid: paymentid,
        }
      );
  
      if (res.data.status === "success") {
        const id = res?.data?.body?._id
        navigate(`/applicationpdf/${id}`,{
            state:{
            id:id
            } 
        });
        setPayloading(false);
      }
    } catch (err) {
      console.log(err.response.data.message);
      alert('Something went wrong contact to company support!')
      window.location.reload();
    }
  }

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const makePayment = async (formId, email, phone, fees) => {
    setPayloading(true);

    // console.log("in make payement");

    const res = await initializeRazorpay();
    if (!res) {
      alert("Razorpay SDK Failed to load");
      return;
    }

    const data = await fetch(
      `${process.env.REACT_APP_HOSTURL}/api/v1/api/payment`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ price: fees }),
      }
    ).then((t) => t.json());
    // console.log(data);
    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      name: "Bhartiya Aviation Services",
      currency: data.currency,
      amount: data.amount,
      order_id: data.id,
      description: "Payment for B.A.S",
      image: "https://bhartiyaaviation.in/images/logo.jpeg",
      handler: function (response) {
        // console.log(response.razorpay_payment_id);
        // console.log(response.razorpay_order_id);
        // console.log(response.razorpay_signature);
        // console.log('after payment form id', formId)
        setPayloading(true);
        //! Form payment status update after payment complete
        updateFormPaymentStatus(formId, "paid", response.razorpay_payment_id);

        //! Form submit after payment paid
        //  submitForm(response.razorpay_payment_id, "paid");
      },
      prefill: {
        name: "",
        email: email,
        contact: phone,
      },
      "theme": {
        "color": "#816500"
    }
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };


  return (
    <>
      <div className="right-side-page-component">
        <div className="container">
          <h3 className="text-center text-muted pt-2 pb-2">
            All Submitted Application Form
          </h3>
          <div className="application-form-open m-3 d-flex align-items-start flex-column">
            {applicationFormStatusData?.map((d, i) => {
              return (
                <label htmlFor="" key={i} className="text-muted">
                  Application Form Currently:{" "}
                  <b>{d.applicationStatus ? "Open" : "Close"}</b>
                </label>
              );
            })}
            <p className="mt-3 fw-bold text-danger">{errMsg}</p>
            <select
              className="mt-1 mb-3"
              onChange={(e) => setChangeAppFormStatus(e.target.value)}
            >
              <option value="none">Select an option</option>
              <option value="open">Open</option>
              <option value="close">Close</option>
            </select>
            <button
              className="btn btn-info fw-bold text-light"
              onClick={changeAppFormSta}
            >
              Update
            </button>
          </div>
          <div className="all-state-count-form  border">
            <div className="tstate-title-div shadow-sm p-3">
              <h3>All Submitted Forms Counts By State Name</h3>
            </div>

            {formStLoading ? (
              <>
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <div className="loader"></div>
                  <p>Loading...</p>
                </div>
              </>
            ) : (
              <>
                <div className="inner-cnt-state-div-sdasd">
                  <ul>
                    {/* {
              formsByState?.map((d,i)=>{
                const compNum = formatter.format(d.totalForm);
                return (
                <div  key={i} className='statename-count-upper-div'>
                  <li className='shadow m-3 p-3'>
                    <h3>{d._id}</h3>
                    <p>{compNum}</p>
                  </li>
                </div>
                )
              })
            } */}
                    {formsByState.map((stat, index) => (
                      <div key={index} className="statename-count-upper-div">
                        <li key={index} className='shadow m-3 p-3'>
                          <h3>Year: {stat._id.year}</h3>
                          <h3>{stat._id.examCentreState}</h3>
                      <p>{stat.totalForm}</p>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="form-excel-top  p-3">
              <p>Total Forms: {totalPaidCount}</p>
            </div>

            <div>
              {allFormsData.length === 0 ? (
                <button
                  onClick={fetchAllFormsDataCSV}
                  disabled={csvLoading}
                  style={{
                    padding: "8px",
                    borderRadius: "4px",
                    backgroundColor: "teal",
                    color: "white",
                  }}
                >
                  {csvLoading ? "Processing..." : "Load CSV Data"}
                </button>
              ) : (
                <>
                  <ExportJsonCsv
                    style={{
                      padding: "8px",
                      borderRadius: "4px",
                      backgroundColor: "teal",
                      color: "white",
                    }}
                    headers={headers}
                    items={datas}
                  >
                    Click To Download
                  </ExportJsonCsv>
                </>
              )}
            </div>

            {formsPaymentStatus === "getonlypaidform" ? (
              <button
                className="btn btn-danger"
                onClick={() => updateFormStatus("getonlynotpaidform")}
              >
                Not Paid Candidates
              </button>
            ) : (
              <button
                className="btn btn-success"
                onClick={() => updateFormStatus("getonlypaidform")}
              >
                Paid Candidates
              </button>
            )}
          </div>

          <div className="mb-4">
            <Search
              placeholder="search with only mobile,email,examination centre state"
              enterButton="Search"
              size="large"
              //  suffix={suffix}
              onSearch={onSearch}
            />
          </div>

          <div className="submitted-form-bottom">
            {loading ? (
              <>
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <div className="loader"></div>
                  <p>Loading...</p>
                </div>
              </>
            ) : (
              <>
                <table className="table table-bordered border-danger">
                  <thead>
                    <tr
                      className="text-center"
                      style={{
                        backgroundColor: "#816500",
                        color: "white",
                        fontSize: "13px",
                      }}
                    >
                      <th scope="col">Registration No.</th>
                      <th scope="col">Candidate Name</th>
                      <th scope="col">Father/Husband Name</th>
                      <th scope="col">D.O.B</th>
                      <th scope="col">E-mail</th>
                      <th scope="col">Apply For</th>
                      <th scope="col">Application Fee</th>
                      <th scope="col">Payment Status</th>
                      <th scope="col">Date</th>
                      <th scope="col">More</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems?.map((d, i) => {
                      console.log(d)
                      return (
                        <tr
                          className="text-center"
                          key={i}
                          style={{ fontSize: "13px" }}
                        >
                          <th scope="row">{d?.registrationNumber}</th>
                          <td>{d?.candidatename}</td>
                          <td>{d?.fatherhusbandname}</td>
                          <td>{d?.dateofbirth}</td>
                          <td>{d?.email}</td>
                          <td>{d?.postapply}</td>
                          <td>{d?.price}</td>
                          <td>{d?.paymentStatus}</td>
                          <td>{new Date(d?.createdAt).toDateString()}</td>
                          <td>
                            <button
                              className="btn btn-warning"
                              id={d?._id}
                              onClick={getSingleFromData}
                            >
                              View
                            </button>

                            {d.paymentStatus === "notpaid" && (
                          <button
                            className="btn btn-danger my-2"
                            onClick={() =>
                              makePayment(d._id, d.email, d.mobile, d.price )
                            }

                            disabled={payLoading}
                          >
                           {payLoading ? 'Wait...': 'Pay'}
                          </button>
                        )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </div>
          <div className="pagination-btn-div mb-4 mt-4">
            <button onClick={paginationBackHandler} disabled={page === 1}>
              Prev
            </button>
            {/* {renderPageNumber}
        {increamentBtn}  */}
            {page}
            <button onClick={paginationNextHandler}>Next</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DBAppForm;
