import React from 'react'
import { lazy, Suspense } from 'react';
//import HomeComponent from '../components/HomeComponent'
//import SliderComponent from '../components/SliderComponent'

const HomeComponent = lazy(()=> import('../components/HomeComponent'))
const SliderComponent = lazy(()=> import('../components/SliderComponent'))


const Home = () => {
  return (
   <>
   <Suspense fallback={  <div className='text-center'>
    <div className="spinner-grow" style={{width: '3rem', height: '3rem', color: 'brown'}} role="status">
    <span className="visually-hidden text-success">Loading...</span>
    
  </div>
   </div>}>
   <HomeComponent/>
   <SliderComponent/>
   </Suspense>
   </>
  )
}

export default Home