import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import '../styles/applicationpdf.css'
import { useParams } from "react-router-dom"; 
import FileSaver from 'file-saver';

// eslint-disable-next-line 
import ReactToPrint from 'react-to-print';


const ApplicationPDF = () => {

  const componentRef = useRef();

  const location = useLocation();

    const [data, setData] = useState([]);
    const [pdfDownloadId, setPDFDownloadID] = useState();

    const [loading, setLoading] = useState(false);

    const { id } = useParams()
  //console.log(id)
    const downloadPDFID = id

    const getData = async ()=>{
      await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/formdata/${location.state?.id}`).then(res=>{
          //console.log(res.data);
          setData(res.data.response);
          setPDFDownloadID(res.data.response._id)

           axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/generatepdf`,{
               id: res.data.response._id,
               email: res.data.response.email,
           }).then(d => {
            //   console.log(d);
           })

           axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/generateinvoice`,{
            id: res.data.response._id,
               email: res.data.response.email,
           }).then(d => {
            //   console.log(d);
           })

          }).catch(err=>{
              //console.log("error",err);
          })
         
        } 

        const downloadPdf = async(e)=>{
          e.preventDefault()
          try{
            setLoading(true)
            const instance = axios.create({
              baseURL: `${process.env.REACT_APP_HOSTURL}/api/v1/downloadpdf`,
             // timeout: 1000,
             responseType: 'arraybuffer',
              headers: { Accept: 'application/pdf',}
            });
        const res =    await instance.post(`${process.env.REACT_APP_HOSTURL}/api/v1/downloadpdf`,{
            id: pdfDownloadId
           });
           FileSaver.saveAs(
            new Blob([res.data], { type: 'application/pdf' }),
            `${downloadPDFID}.pdf`
          );
          // console.log(res.data)
           setLoading(false)
          }catch(err){
            //console.log(err)
          }
        }
       // console.log('pdfIDDOWN', pdfDownloadId);

    useEffect(()=>{
      getData()
      // eslint-disable-next-line
    },[])

       // eslint-disable-next-line
       const uploadPhoto =  `${process.env.REACT_APP_HOSTURL}/api/v1/images/${data?.uploadphoto}`
       const uploadSignature =  `${process.env.REACT_APP_HOSTURL}/api/v1/images/${data?.uploadsignature}`
   
       //console.log(uploadPhoto)
       //console.log(uploadSignature)

  return (
  <>
    <div className='application-form-main-div restpage-secondary-bg' ref={componentRef}> 
      <div className='container'>
      {/* <div className='banner-pdf-app-div d-none d-print-block'>
          <img src="/images/BAS-Banner.jpeg"  alt="" />
        </div> */}
            <div className='app-pdf-inner-div'>
              <div className='app-pdf-title-div'>
                <h3>Application PDF</h3>
              </div>
              
              <div className='app-pdf-el-div'>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Registration No:</label>
                             <p>{data?.registrationNumber}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Application No:</label>
                             <p>{data?.applicationnumber}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Candidate's First Name</label>
                             <p>{data?.candidatename}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Last Name</label>
                             <p>{data?.lastname}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Father/Husband Name(Do not add Shri,Late,etc)</label>
                             <p>{data?.fatherhusbandname}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Mother Name(Do not add Shri,Late,etc)</label>
                             <p>{data?.mothername}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Date Of Birth </label>
                             <p>{data?.dateofbirth}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Gender</label>
                             <p>{data?.gender}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Category(Cast)</label>
                             <p>{data?.categorycast}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Marital Status</label>
                             <p>{data?.maritalstatus}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Apply For </label>
                              <p>{data?.postapply}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                            <div className='app-pdf-form-el-div' id='app-form-pdf-email-phone'>
                             <div className=' col-sm-6  inpt-el-inr-div'>
                             <label htmlFor="">Email</label>
                             <p>{data?.email}</p>
                             </div>
                             <div className='col-sm-5  inpt-el-inr-div'>
                             <label htmlFor="">Mobile No. </label>
                               <p>{data?.mobile}</p>
                             </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Educational Qualification</label>
                             <p>{data?.educationalqualification}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Division</label>
                             <p>{data?.educationdivision}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Intermediate</label>
                             <p>{data?.intermediate}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Division</label>
                             <p>{data?.intermediatedivision}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">High School</label>
                             <p>{data?.highschool}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Division</label>
                             <p>{data?.highschooldivision}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Uploaded Photo</label>
                              <img src={uploadPhoto} width={100} height={100} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Uploaded Signature </label>
                              <img src={uploadSignature} width={100} height={100} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='app-form-prsnt-add-upper-div'>
                        <div className='app-form-prsnt-add-title-div'>
                          <h3>Present Address</h3>
                          </div>
                      <div className='row'>
                        <div className='col-sm-3 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Street Name</label>
                             <p>{data?.address?.present?.street}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-3 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">House No</label>
                             <p>{data?.address?.present?.houseno}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-3 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Village</label>
                             <p>{data?.address?.present?.village}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-3 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">City</label>
                             <p>{data?.address?.present?.city}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-3 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Post-Office</label>
                             <p>{data?.address?.present?.postoffice}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-3 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">State</label>
                             <p>{data?.address?.present?.state}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-3 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">District</label>
                             <p>{data?.address?.present?.district}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-3 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Pincode</label>
                             <p>{data?.address?.present?.pincode}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                         </div>

                          <div className='app-pdf-form-same-add'>
                            <h5>{data?.isPresent ? '---' : "Address Same As Above"}</h5>
                          </div>

                      <div className='app-form-prsnt-add-upper-div'>
                        <div className='app-form-prsnt-add-title-div'>
                          <h3>Permanent Address</h3>
                          </div>
                      <div className='row'>
                        <div className='col-sm-3 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Street Name</label>
                             <p>{data?.address?.permanent?.pr_street}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-3 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">House No</label>
                             <p>{data?.address?.permanent?.pr_houseno}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-3 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Village</label>
                             <p>{data?.address?.permanent?.pr_village}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-3 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">City</label>
                             <p>{data?.address?.permanent?.pr_city}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-3 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Post-Office</label>
                             <p>{data?.address?.permanent?.pr_postoffice}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-3 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">State</label>
                             <p>{data?.address?.permanent?.pr_state}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-3 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">District</label>
                             <p>{data?.address?.permanent?.pr_district}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-3 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Pincode</label>
                             <p>{data?.address?.permanent?.pr_pincode}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                         </div>

                         <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Examination Centre State</label>
                             <p>{data?.examCentreState}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Exam Centre City</label>
                             <p>{data?.examCentreCity}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='app-pdf-declaration-div'>
                      <h5>Declaration:</h5>
                 <p>
                 {data?.isDeclaration ? ' I declare that the name, class, date of birth, address and other information given by me in the online application form is correct to the best of my knowledge and belief. Which I declare to be truely correct. If the above information is found incomplete or incorrect, my candidature is liable to be terminated at any time.': 'Not Selected'}
                
                 </p>
                      </div>

                      <div className='app-form-pdf-payment-div'>
                      <h5 className='payment-detail'>Payment Details:</h5>

                      </div>

                      <div className='row'>
                        <div className='col-sm-4 '>
                          <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Application Fee:</label>
                             <p>{`₹ ${data?.price}`}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-4 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Payment ID:</label>
                             <p>{data?.paymentId}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-4 '>
                        <div className='app-pdf-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Payment Status:</label>
                             <p>{data?.paymentStatus}</p>
                            </div>
                          </div>
                        </div>
                      </div>
              </div>


            </div>
      </div>
      <div className='d-print-none text-center pt-3 text-muted'>
        <p className='text-success blink fw-bold'><em> Thank You ! your form successfully submitted </em></p>
        <br />
        <p><em><b>Note: </b>You can print or download this application form & we've also send your application form to your given email !!</em></p>

      </div>
      <div className='text-center mt-4 pb-4 pdf-download-div d-print-none'>
        <button className='btn btn-success d-print-none' onClick={()=> window.print()}>Print Application Form</button>
        {
        loading ? (
        <>
           <button className='btn btn-success ms-4' disabled={loading ? true : false} >Loading...</button>
        </>): (
        <>
        <button className='btn btn-success ms-4' disabled={loading ? true : false}  onClick={downloadPdf}>Download</button>
        </>)
      }
      </div>
    </div>
  </>
  )
}

export default ApplicationPDF