import React from 'react'
import '../styles/componentPage.css'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

import { MdList,MdWarning,MdOutlineChecklist,MdFactCheck } from "react-icons/md";



const ImportantNotice = () => {
  return (
    <>
      <Helmet> 
     <title>Privacy policy || Bhartiya Aviation Services</title>
     <meta charSet='UTF-8'/>
       <meta name="keywords" content="Bhartiya Aviation Services,Aviation, Aviation Jobs, airport jobs, graound staff jobs"/>
       <meta name="description" content="The candidates are informed that the Bhartiya Aviation Services has no relation with the currency demanded by any particular person for passing the examination or passing the interview, if any candidate is in favor of it, then the candidate himself will be responsible for it"/>
       <meta name="author" content="Bhartiya Aviation Services"/>
       <meta name="refresh" content="50"/>
       <meta property="og:image" content="/public/favicon_package_v0.16/apple-touch-icon.png"/>
       <meta http-equi="refresh" content="" url="https://bhartiyaaviation.in/"/>

       <meta property="og:type" content="website"/>
<meta property="og:url" content="https://bhartiyaaviation.in/"/>
<meta property="og:title" content="Bhartiya Aviation Services"/>
<meta property="og:description" content="Bhartiya Aviation Services is an ISO 9001:2015 Certified company & professional team managed by professionals with a combined experience of more than 40 years in aviation"/>
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>


<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://bhartiyaaviation.in/"/>
<meta property="twitter:title" content="Bhartiya Aviation Services"/>
<meta property="twitter:description" content="Bhartiya Aviation Services is an ISO 9001:2015 Certified company & professional team managed by professionals with a combined experience of more than 40 years in aviation"/>
<meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>

    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon_package_v0.16/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon_package_v0.16/favicon-16x16.png"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" href="/public/logo192.png" />
       <link rel='canonical' href='/imporatntnotice' />
   </Helmet>

   <div className='container-fluid  top-banner-pg-div-about-us'>
         <div className='inner-pg-wrapper-div'>
             <div className='container ot-page-banner-heading'>
                 <h1>Privacy/Refund/Cancellation Policy</h1>
                 <Link to={'/'}>
                 <p>
                  Home/ privacy/refund/cancellation policy
                  </p>
                 </Link>
             </div>
         </div>
     </div>


      <div className='privacy-policy-main-div'>
      <div className='container '>
        <div className='title-div-policy'>
        <h1 className='policy-title-heading'>Company Details <MdList/></h1>
        </div>
           <div className="company-detail-div shadow-sm">
              <ul className='policy-company-detail-ul'>
                <li>
                <p><b>Name of the company :- </b><u>Bhartiya Aviation Services</u></p>
                </li>
                <li>
                  
                <p>Bhartiya Aviation Services is basically providing training to the candidates in aviation sector.</p>
                </li>
                <li>
                <p><b>Notice :-</b></p>
                </li>
                <li>
                <p>Legal action will be taken on the use of Bhartiya Aviation Services name, logo or false rumors about Bhartiya Aviation Services by any person or company.</p>
                </li>
                <li>
                
                <p><em>The candidates are informed that the Bhartiya Aviation Services has no relation with the currency demanded by any particular person for passing the examination or passing the interview, if any candidate is in favor of it, then the candidate himself will be responsible for it.</em></p>
                </li>
              </ul>
            
            
          
           

           </div>
           <div className='title-div-policy'>
        <h1 className='policy-title-heading'>Privacy Policy <MdOutlineChecklist/></h1>
        </div>
        <div className='company-detail-div shadow-sm'>
           <p id='policy-info'><u>The Policy informs you of our policies regarding the collection, use and disclosure of your Personal Data as well as all reasonable precautions which we take to keep your Personal Data confidential and secure.</u></p> 

           <ul className='policy-company-detail-ul'>
            <li>
              <p>
              Subject to your rights at law, you agree to be bound by the prevailing terms of this Policy as updated from time to time. We reserve the right to modify our Policy and invite you to check the latest version of this Policy regularly.
              </p>
            </li>
            <li>
              <p>
              This Privacy Policy applies to the Personal Data collected by us, including Personal      Data collected via thewellnessstore.in and all related domains and sub-domains and its     mobile application.
              </p>
            </li>
            <li>
              <p>
              By using our Website and/or providing the Personal Data to order our products and use our services, you hereby acknowledge that you have read this Privacy Policy and agree to the collection, use and disclosure of Personal Data in accordance with this Policy, as amended from time to time by us and to the transfer of the Personal Data to the other companies or entities within the BAS.
              </p>
            </li>
            <li>
              <p>
              We will post any update to the Policy on our Website. If you do not agree to the terms of this Policy, please do not access this Website or provide the Personal Data.
              </p>
            </li>
            <li>
              <p>
              The terms of this Policy are without prejudice to any contractual terms you may enter into with BAS which shall prevail over the terms of this Policy.
              </p>
            </li>
            <li>
              <p>
              ‘Personal data’ means data about a person who can be identified (i) from that data, or (ii) from that data and other information to which BAS has or is likely to have access. Examples of personal data that may be collected by BAS include your name, contact number, email address, residential address, payment information and other personal data that BAS may require in order to provide our services or Website to you.
              </p>
            </li>
            <li>
              <p>
              BAS does not collect, use and process Personal Data except when voluntarily provided by you. We ensure compliance by our staff with strict standards of security and confidentiality and in using, disclosing and processing your Personal Data we pledge to fully comply with industry recognised standards of privacy protection.
              </p>
            </li>
            <li>
              <p>
              Generally, we may collect, use and disclose your Personal Data for any or all of the following reasons:
              </p>
            </li>

            
              <ul className='second-policy-list-ul'>
                <li>
                  <p>To verify your identity.</p>
                </li>
                <li>
                  <p>To communicate with you, including any ongoing promotions or BAS news.</p>
                </li>
                <li>
                  <p>To customize and personalize the contents of the Website.</p>
                </li>
                <li>
                  <p>To evaluate your preferences and facilitate future works on the Website.</p>
                </li>
                <li>
                  <p>To manage our internal infrastructure and business operations, including but not limited to where required for compliance with internal policies and procedures.</p>
                </li>
                <li>
                  <p>To conduct audits, reviews, and analyses of our internal processes.</p>
                </li>
                <li>
                  <p>To prevent, detect and/or investigate error, fraud, crime and criminal activity.</p>
                </li>
                <li>
                  <p>To protect and enforce our contractual and legal rights and obligations.</p>
                </li>
                <li>
                  <p>To respond to requests for information from government or public agencies, regulatory authorities or other similar authorities or non-government agencies authorised to carry out specific Government services or duties.</p>
                </li>
                <li>
                  <p>To ensure our compliance with all relevant laws, regulations, guidelines and codes of practices.</p>
                </li>
                <li>
                  <p>Any other purposes relating to the purposes stated above, or which may be notified and consented by you on or before collection or use of the Personal Data.</p>
                </li>
              </ul>

            <li>
              <p>BAS also automatically receives and records information from your browser on our server logs including but not limited to your Internet protocol address, cookie information and the page you requested. Should you wish to delete any cookies that are already on your computer, please refer to the help and support area on your Internet browser for instructions on how to locate the file or directory that stores cookies.</p>
            </li>
            <li>
              <p>BAS shall never sell, rent, share or otherwise distribute or make available your Personal Data to third parties outside the BAS group of companies or affiliates, except when required to do so for legal or regulatory purposes. However, your Personal Data may occasionally be transferred to third parties who act for and on behalf of BAS, for further processing in accordance with the purposes for which your Personal Data was originally collected. When disclosure of your Personal Data to third parties is likely or necessary for whatever reason, BAS shall ensure that such third parties provide the same level of protection as BAS, and that they are required to process your Personal Data transferred only for the purposes authorized by you and on a confidential and secure basis.</p>
            </li>
            <li>
              <p>Most of the information we collect from our Website is anonymous information. When you visit our Website, we do not collect any personal information from you unless specifically voluntarily and knowingly provided by you. Anonymous information is processed by BAS to help improve the content of our Website, to customize our Website for our visitors and to learn more about them and how they use our Website. In doing so, we may use tracking technology (cookies) to gather anonymous information, such as browser type, operating systems and the date and time of access. To see our Cookies Policy, please refer to our Cookies Policy at Cookies Policy.</p>
            </li>
            <li>
              <p>BAS does not conduct “spamming”. Spamming is defined as sending unsolicited emails, usually of commercially nature, in large numbers and repeatedly to individuals with whom the sender has had no previous contact or who have declined to receive such communications.</p>
            </li>
            <li>
              <p>As a service to our visitors, our Website may provide hyperlinks to other websites or mobile applications that are not operated or controlled by BAS. Therefore we cannot be responsible or liable for the content of such websites or mobile applications or the privacy policies of such other parties. Please note that these privacy practices may differ from our Policy. We encourage you to review and understand their privacy practices before providing them with personal information.</p>
            </li>

              <li>
                <p>This Policy and the use of the Website shall be governed in all respects by the laws of India without giving any effect to the principles of conflicts of law and you agree to submit to the exclusive jurisdiction of the courts in New Delhi.</p>
              </li>
              <li>
                <p>Any dispute, difference, controversy or claim between you and BAS arising out of or in connection with this Policy shall first be submitted to. You agree to participate in the mediation in good faith and undertake to abide by the terms of any settlement reached.</p>
              </li>

            </ul>   
        </div>
        <div className='title-div-policy'>
        <h1 className='policy-title-heading'>Notice of Copyright Violation <MdWarning/></h1>
        </div>
        <div className='company-detail-div shadow-sm'>
          <p id='policy-info'><u>BAS is not responsible for any breach of copyright that may take place from the content transmitted or posted on the website. The company is not accountable for copyright violation of any items that are displayed on the website. If you have any objection related to any content posted on the website, you can let us know through the site.</u></p>

          <p id='policy-info'>You are requested to furnish the following information while registering your complaint with us.</p>

          <ul className='third-policy-list-ul'>
            <li>
              <p>A proof that the copyrighted work has been breached.</p>
            </li>
            <li>
              <p>An electronic or physical signature of a person who is allowed to represent the copyright owner during registering of the complaint.</p>
            </li>
            <li>
              <p>Contact number, email address or address of the complaining party.</p>
            </li>
            <li>
              <p>Identity of the material that is uploaded to the site where it has claimed that the copyrighted work has been violated.</p>
            </li>
            <li>
              <p>A statement related to penalty of perjury that information provided in the draft is correct. The party, which has complained, is empowered by copyright owner to act on his or her behalf.</p>
            </li>
            <li>
              <p>A statement showing that the party, which is complaining, possesses a belief of good faith. The statement should highlight that the material that has been used is not approved by the law, copyright owner or its agent.</p>
            </li>
          </ul>

          <ul className='four-policy-list-ul'>
            <li>
              <u className='topic-heading'>Relationship :</u>
              <p >
              None of the terms and conditions, notices and provisions of this agreement or the approval for using the site by the user contained herein, any pages or other sections of the website or linked sites, will be deemed for constituting a partnership between BAS and the user. A party cannot prove its authority for binding and will be considered as an agent. It should be brought into the notice that if user uses the site and approves BAS and its agents for accessing third party sites that are designated by them or on their behalf to recover the information being requested, the user will be considered to have hired BAS and its agents as his or her agent for this intention
              </p>
            </li>
            <li>
              <u className='topic-heading'>Indemnity :</u>
              <p >
              You have to indemnify and prove that you will not cause harm to the parent company, its affiliates, subsidiaries, third parties, directors, officers, employees and agents from any demand or claim, or actions including reasonable fees of lawyers, made by any third party. You cannot claim from the company if there is a violation of the user agreement.
              </p>
            </li>
            <li>
              <u className='topic-heading'>Disclaimer of Liability :</u>
              <p>
              The Website Owner shall not be responsible for and DISCLAIMS all liability for any loss, liability, damage (whether direct, indirect or consequential), personal injury or expense of any nature whatsoever which may be suffered by you or any third party (including your company), as a result of or which may be attributable, directly or indirectly, to your access and use of the website, any information contained on the website, your or your company’s personal information or material and information transmitted over our system. In PARTICULAR, neither the Website Owner nor any third party or data or content provider shall be liable in any way to you or to any other person, firm or corporation whatsoever for any loss, liability, damage (whether direct or consequential), personal injury or expense of any nature whatsoever arising from any delays, inaccuracies, errors in, or omission of any share price information or the transmission thereof, or for any actions taken in reliance thereon or occasioned thereby or by reason of non-performance or interruption, or termination thereof.
              </p>
            </li>
          </ul>
        </div>
        <div className='title-div-policy'>
        <h1 className='policy-title-heading'>Term And Conditions <MdFactCheck/></h1>
        </div>
        <div className='company-detail-div shadow-sm'>
          <p id='policy-info'>Thank you for visiting our website on your electronic device including computer, tablet or mobile phone. This Website is operated by Bhartiya Aviation Services.</p>
          <p id='policy-info'>The following Terms & Conditions create a legally binding agreement between our customers and BAS.</p>
          <ul className='fith-policy-list-ul'>
            <li>
              <p>By browsing, accessing and/or using any part of this Website and/or using any of the services provided via this Website and/or purchasing or attempting to purchase any product or service on this Website, you shall be deemed to have read, understood and accepted these Terms as modified from time to time by us and thereby be legally bound by these Terms and modifications.</p>
            </li>
            <li>
              <p>The Privacy Policy and Cookie Policy on this Website governs the data processing including collection, use, disclosure and transfer of any personal information we collect from you, or that you provide to us. By using this Website you also agree to be bound by the terms of the Privacy Policy and Cookie Policy.</p>
            </li>
            <li>
              <p>BAS reserves the right to modify, add, vary or amend all or part of these Terms from time to time without prior notice to you. Your use of this Website and any of the services provided here after such changes are posted on this Website shall be deemed an agreement by you to these Terms as modified.</p>
            </li>
            <li>
              <p>In the event that BAS, in its sole discretion, considers that you are making any illegal and/or unauthorised use of the Website, and/or your use of the Website is in breach of these Terms, BAS reserves the right to take any action that it deems necessary, including terminating without notice your use of the Website and, in the case of illegal use, instigating legal proceedings.</p>
            </li>
            <li>
              <p>No data transmissions over the Internet or via wireless networks can be guaranteed to be secure. Consequently, BAS cannot and does not ensure or warrant the security, authenticity, integrity or confidentiality of any information and/or communication you transmit to us and you do so at your own risk.</p>
            </li>
            <li>
              <p>You agree that BAS may use Personal Information provided by you in order to conduct appropriate anti-fraud checks. Personal Information that you provide may be disclosed to a credit reference or fraud prevention agency, which may keep a record of the information.</p>
            </li>
            <li>
              <p>This Website is for your personal, non-commercial use only. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, commercially exploit, create derivative works from, transfer, or sell any content, software, products, or services contained within this site. You may not use this site, or any of its content, to further any commercial purpose, including any advertising or advertising revenue generation activity on your own site.</p>
            </li>
          </ul>
        </div>
        <div className='company-detail-div last-policy-div shadow-sm'>
          <ul className='six-policy-list-ul'>
            <li>
              <p>Fire, accident, any law, order, proclamation, regulation, demand or requirement of the Government of India  or of any of its government agencies, strikes, labour disputes, shortage of labour or lack of skilled labour, shortage or unavailability of products or raw materials, delay in transit or other causes whatsoever (whether similar to the foregoing or not) beyond the reasonable control of BAS</p>
            </li>
            <li>
              <p>Act of God (i.e. extraordinary or unforeseeable manifestation of the forces of nature beyond the powers of human intervention, such as tornadoes, hurricanes, typhoons and earthquakes)</p>
            </li>
            <li>
              <p>War or armed conflict or acts of terrorism, which include disruption of civil authority, transportation and communication services, in which cases, BAS  shall be excused from such performance to the extent of such prevention.</p>
            </li>
          </ul>
          <ul className='seven-policy-list-ul'>
            <li>
              <p>Except for all affiliates of BAS no other person shall have any rights whether under the Indian Contract Act, 1872 or any other law to enforce or enjoy any benefit under these Terms. Notwithstanding anything in these Terms the consent of any third party is not required for any variation (including any release or compromise of any liability under) of these Terms.</p>
            </li>
            <li>
              <p>Your use of this Website and these Terms shall be governed by, and interpreted in accordance with the laws of India.</p>
            </li>
          </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default ImportantNotice