import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { MdDelete } from "react-icons/md";

const DBcontactqueries = () => {

  const [queryData , setQueryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMessage] = useState('')



  //!pagination
const [currentPage, setCurrentPage] =  useState(1);
// eslint-disable-next-line 
const [itemsPerpage, setItemsPerPage] = useState(4);

const indexOfLastitems =  currentPage * itemsPerpage;
const indexOfFirstItems =  indexOfLastitems -  itemsPerpage;
const currentItems = queryData?.slice(indexOfFirstItems, indexOfLastitems);
// eslint-disable-next-line 
const [pageNumberLimit, setPageNumberLimit] = useState(5);
const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
const [minPageNumberLimit, setminPageNumberLimit]  = useState(0);

const pages = []

const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  }

  const handleNextBtn = () => {
    setCurrentPage(currentPage +1);
    if(currentPage +1 > maxPageNumberLimit){
        setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  }
  
  const  handlePrevBtn = () =>{
    setCurrentPage(currentPage - 1);
    if((currentPage -1)% pageNumberLimit ===0){
        setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  }

  let increamentBtn = null;
if(pages.length > maxPageNumberLimit){
  increamentBtn = <li onClick={handleNextBtn}> &hellip;  </li>
}

for (let i =0; i <= Math.ceil(queryData.length/itemsPerpage); i++  ){
    pages.push(i);
}

const renderPageNumber = pages?.map((number)=> {
   if(number <  maxPageNumberLimit +1 && number > minPageNumberLimit){
    return(
        <li  key={number} id={number} onClick={handleClick} className={currentPage === number ? 'pagin-active' : 'pagin-li'}>
       {number}
       </li>
    )  
   }else{
       return null;
   }
})

//!pagination end


const deleteContactquery = async(e)=>{
  e.preventDefault()
  try{
    
    const listId = e.currentTarget.id

    const res = await axios.delete(`${process.env.REACT_APP_HOSTURL}/api/v1/contactform/${listId}`)
    setMessage(res.data.message)
    const deletedqry = queryData.filter((d)=> d._id !== listId)
    setQueryData(deletedqry)


  }catch(err){
   // console.log(err)
  }
}


useEffect(()=>{
    const fetcAllQueries = async()=>{
      setLoading(true)
      try{
        const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/contactform`)
        //console.log(res.data.response)
        setQueryData(res.data.response)
        setLoading(false)
      }catch(err){
        //console.log(err);
      }
    }
    fetcAllQueries()
},[])

  return (
    <>
    <div className='right-side-page-component'>
       <div className='container contact-query-list-main-div restpage-secondary-bg'>
        <div className='contact-query-title text-center m-3 p-2' style={{backgroundColor: '#816500', color: 'white',}}>
          <h3>Query Lists</h3>
        </div>
          {loading ? (
          <>
           <div className='d-flex align-items-center justify-content-center flex-column'>
             <div className="loader"></div>
                    <p>Loading...</p>
            </div>
          </>) : (
            <>
            <div className='all-conact-qry-div'>
            <p className='m-3 text-danger fw-bold' >{msg}</p>
            {
              currentItems?.map((d,i)=>{
                return(
                  <div key={i}>
                   
                  <div className="accordion accordion-flush shadow mt-3 mb-3" id="accordionFlushExample" >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseOne${i}`} aria-expanded="false" aria-controls="flush-collapseOne">
                      <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{d.name}</td>
                                <td>{d.email}</td>
                                <td>{d.phone}</td>
                              </tr>
                            </tbody>
                          </table>
                      </button>
                    </h2>
                    <div id={`flush-collapseOne${i}`} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        <p className='border p-3 m-3 '><b>Message:</b> <br/>
                        {d.message}
                        </p>
                        <button className='m-3 btn btn-danger' id={d._id} onClick={deleteContactquery}><MdDelete className='fs-4'/></button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
                
                )
              })
            }
          </div>
            </>
          )}
          <div className='pagination-btn-div mb-4 mt-4'>
          <button onClick={handlePrevBtn} disabled={currentPage === pages[1] ? true :  false} >Prev</button>
          {renderPageNumber}
          {increamentBtn} 
          <button onClick={handleNextBtn}  disabled={currentPage === pages[pages.length -1 ] ? true :  false} >Next</button>
          </div>
       </div>
       </div>
    </>
  )
}

export default DBcontactqueries