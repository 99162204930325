import React, { useEffect, useState } from 'react'
import '../styles/applyform.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {storage} from '../firebase'
// eslint-disable-next-line
import {ref, uploadBytes, listAll, getDownloadURL } from 'firebase/storage';
import { DatePicker,Select} from 'antd';

import { MdAccountBox,MdBorderColor,MdEmail,MdPhoneInTalk,MdTransgender,MdWorkspaces,MdSchool,MdAirplanemodeActive,MdOutlineAddRoad, MdHome,MdOutlineHolidayVillage,MdOutlineApartment, MdMap,MdLocationCity, MdFormatListNumbered,MdPinDrop,MdCurrencyRupee } from "react-icons/md";

import { BsFillCalendarDateFill,BsPeopleFill,BsFillDiagram2Fill,BsFilePostFill,BsFillPinMapFill } from "react-icons/bs";

import { Progress } from 'antd';
// eslint-disable-next-line
const { Option } = Select;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];



const FormHandler = () => {


  const navigate = useNavigate();

  const loggedInUser  = useSelector((state)=> state.user)
  // console.log(loggedInUser.user.id)
  // console.log(loggedInUser.user.regNum)

  const [state,setState] = useState([]);
  const [city, setCity] = useState([]);

  const [chooseState, setChooseState] = useState();
  const [chooseCity, setChooseCity] = useState();
  const [chooseStateId, setChooseStateId] = useState();
  const [citiesLoading, setCitiesLoading] = useState(false);

  const [errMsg, setErrMsg] = useState('')


  const [razorpayResponse, setRazorpayResponse] = useState(false);


  const sameAddress = (e)=>{
    const value = e.target.checked;
    if(value){
        setPermanentAddressChecked(false);
        setPermanentStreetName('-')
        setPermanentHouseNo('-')
        setPermanentVillage('-')
        setPermanentCity('-')
        setPermanentPostOffice('-')
        setPermanentState('-')
        setPermanentDistrict ('-')
        setPermanentPincode('-')
    }else if(!value){
        setPermanentAddressChecked(true);
        
    }
}
const [declaration, setDeclaration] = useState('false');
const [permanentAddressChecked, setPermanentAddressChecked] = useState(true);
// Form Handling with start
const [data, setData] = useState("");
const [loading, setLoading] =  useState(false);
const [candidatename, setCandidateName] = useState('');
const [lastname, setLastName] = useState('');
const [fathername, setFatherName] = useState('');
const [mothername, setMotherName] = useState('');
const [dob, setDob] = useState('');
const [gender, setGender] = useState('');
const [cast, setCast] = useState('');
const [married, setMarried] = useState('');
const [applyfor, setApplyFor] = useState('');
const [email, setEmail] = useState('');
const [phone, setPhone] = useState('');
const [education, setEducation] = useState('');
const [educationdivision, setEducationDivision] = useState('');
const [intermediate, setIntermediate] = useState('');
const [intermediatedivision, setIntermediateDivision] = useState('');
const [highschool, setHighSchool] = useState('');
const [highschooldivision, setHighSchoolDivision] = useState('');
const [profilePhoto, setProfilePhoto] = useState();
const [signature, setSignature] = useState();

const [examinationFees,setExaminationFees] = useState({})

// const [postName, setPostName] = useState([])

let postApplyName;

//const[dp, setDp] = useState();

//TODO: Present Address Handling Form.
const [presentstreetname, setPresentStreetName] = useState('');
const [presenthouseno, setPresentHouseNo] = useState('');
const [presentvillage, setPresentVillage] = useState('');
const [presentcity, setPresentCity] = useState('');
const [presentpostoffice, setPresentPostOffice] = useState('');
const [presentstate, setPresentState] = useState('');
const [presentdistrict, setPresentDistrict] = useState('');
const [presentpincode, setPresentPincode] = useState('');


//TODO: Permanent Address

const [permanentstreetname, setPermanentStreetName] = useState('');
const [permanenthouseno, setPermanentHouseNo] = useState('');
const [permanentvillage, setPermanentVillage] = useState('');
const [permanentcity, setPermanentCity] = useState('');
const [permanentpostoffice, setPermanentPostOffice] = useState('');
const [permanentstate, setPermanentState] = useState('');
const [permanentdistrict, setPermanentDistrict] = useState('');
const [permanentpincode, setPermanentPincode] = useState('');
// Form Handling with end

const [progress,setProgress]= useState(null)

const declartionFunc = (e)=>{
  const value = e.target.checked;
 if(!value){ 
     setDeclaration(false)
    
 }else{
     setDeclaration(true);
   
 }
}


const fetchData = async ()=>{
  await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/post`).then(res=>{
setData(res.data);
//console.log(res.data);
}).catch(err=>{
   // console.log("error",err);
})
}

const getStateId = (e)=>{
  e.preventDefault()

  const index = e.target.selectedIndex;
  const el = e.target.childNodes[index]
  const option =  el.getAttribute('id'); 
  setChooseStateId(option)

  const stateName = e.target.value
  setChooseState(stateName)
} 

//!submit form and paymentgateway START
const updateFormPaymentStatus = async (id, paymentstatus, paymentid) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_HOSTURL}/api/v1/updatepayment`,
      {
        id: id,
        paymentstatus: paymentstatus,
        paymentid: paymentid,
      }
    );

    if (res.data.status === "success") {
      const id = res?.data?.body?._id
      navigate(`/applicationpdf/${id}`,{
          state:{
          id:id
          }
      });
      setLoading(false);
      setRazorpayResponse(false)
    }
  } catch (err) {
    console.log(err.response.data.message);
    alert('Something went wrong contact to company support!')
    window.location.reload();
  }
};


const initializeRazorpay = () => {
  // console.log("In Initialize");
   return new Promise((resolve) => {
     const script = document.createElement("script");
     script.src = "https://checkout.razorpay.com/v1/checkout.js";

     script.onload = () => {
       resolve(true);
     };
     script.onerror = () => {
       resolve(false);
     };

     document.body.appendChild(script);
   });
 };

const makePayment = async (params, formid) => {

//  console.log('in makePayment ',id);
  const post  = params.filter((data)=>{
      return data._id === applyfor
  })



 // console.log(post[0].price);
 // console.log(params);
  
   //console.log("in make payement");
   //createOrder({customer : ""})

   const res = await initializeRazorpay();

   if (!res) {
     alert("Razorpay SDK Failed to load");
     return;
   }

   // Make API call to the serverless API
   const data = await fetch(`${process.env.REACT_APP_HOSTURL}/api/v1/api/payment`, 
   { method: "POST" , 
       headers : {
       'Accept' : 'application/json',
       'Content-Type' : 'application/json'
       },
       body : JSON.stringify({price: post[0].price})
      }
       
       ).then((t) =>
     t.json());
   //console.log(data);
   var options = {
     key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
     name: "Bhartiya Aviation Services",
     currency: data.currency,
     amount: data.amount,
     order_id: data.id,
     description: "Payment for B.A.S",
     image: "https://bhartiyaaviation.in/images/logo.jpeg",
     handler: async function (response) { 
       // Validate payment at server - using webhooks is a better idea.
       
      // console.log(response.razorpay_payment_id);
      // console.log(response.razorpay_order_id);
      // console.log(response.razorpay_signature);

      if(response){
        setLoading(true);
        setRazorpayResponse(true)
        // console.log('paid form id : ',formid)
        // console.log('RAZORPAY ',response)
       await updateFormPaymentStatus(formid, "paid", response.razorpay_payment_id)
        //console.log('RAZORPAY: ', response);
       }else{
        setRazorpayResponse(false)
        //console.log('RAZORPAY: ', response);
       }
      //  setLoading(true);
      //   submitForm(response.razorpay_payment_id,"paid");
       // console.log('after payment ',id);
      // console.log(response);
     },
     prefill: {
       name: candidatename,
       email: email,
       contact: phone,
     },
     "theme": {
       "color": "#816500"
   }
   };
   const paymentObject = new window.Razorpay(options);
   paymentObject.open();
 };
//!submit form and paymentgateway END

const submitForm = async(payId, payStatus)=>{



  if(!applyfor || applyfor === ''|| applyfor === undefined || applyfor === null){
    setErrMsg('Please choose job profile !!')
    setLoading(false);
    return
  }else if(!dob || dob === ''||dob === undefined|| dob === null){
    setErrMsg('Please choose date of birth !!')
    setLoading(false);
    return
    
  }else if(!gender || gender === ''||gender === undefined || gender === null){
    setErrMsg('Please choose your gender!!')
    setLoading(false);
    return
  }else if(!cast || cast === ''||cast === undefined || cast === null){
    setErrMsg('Please choose category cast!!')
    setLoading(false);
    return
  } else if(!married || married === ''||married === undefined || married === null){
    setErrMsg('Please choose marital status!!')
    setLoading(false);
    return
  }else if(!education || education === ''||education === undefined || education === null){
    setErrMsg('Please choose educational qualification!!')
    setLoading(false);
    return
  }else if(!educationdivision || educationdivision === ''||educationdivision === undefined || educationdivision === null){
    setErrMsg('Please choose educational qualification division !!')
    setLoading(false);
    return
  }else if(!intermediatedivision || intermediatedivision === ''||intermediatedivision === undefined || intermediatedivision === null){
    setErrMsg('Please choose intermediate division !!')
    setLoading(false);
    return
  }else if(!highschooldivision || highschooldivision === ''||highschooldivision === undefined || highschooldivision === null){
    setErrMsg('Please choose high school division !!')
    setLoading(false);
    return
  }else if(!presentstate || presentstate === ''||presentstate === undefined || presentstate === null){
    setErrMsg('Please choose state in present address !!')
    setLoading(false);
    return 
  }else if(!permanentstate || permanentstate === ''||permanentstate === undefined || permanentstate === null){
    setErrMsg('Please choose state in permanent address !!')
    setLoading(false);
    return 
  }else if(!chooseState || chooseState === ''||chooseState === undefined || chooseState === null){
    setErrMsg('Please choose examination center state !!')
    setLoading(false);
    return 
  }else if(!chooseCity || chooseCity === ''||chooseCity === undefined || chooseCity === null){
    setErrMsg('Please choose exam centre city !!')
    setLoading(false);
    return 
  }else{
    setErrMsg('')
  }

  var formData = new FormData();
// Append data to the FormData object
formData.append('userId', loggedInUser.user.id);
formData.append('registrationNumber', loggedInUser.user.regNum);
formData.append('candidatename', candidatename);
formData.append('lastname', lastname);
formData.append('fatherhusbandname', fathername);
formData.append('mothername', mothername);
formData.append('dateofbirth', dob);
formData.append('gender', gender);
formData.append('categorycast', cast);
formData.append('maritalstatus', married);
formData.append('postId', applyfor);
formData.append('email', email);
formData.append('mobile', phone);
formData.append('educationalqualification', education);
formData.append('educationdivision', educationdivision);
formData.append('intermediate', intermediate);
formData.append('intermediatedivision', intermediatedivision);
formData.append('highschool', highschool);
formData.append('highschooldivision', highschooldivision);
formData.append('uploadphoto', profilePhoto);
formData.append('firebasestudentprofile', 'closed');
formData.append('uploadsignature', signature);
formData.append('firebasestudentsignature', 'closed');

// Append address data to the FormData object
formData.append('address[present][street]', presentstreetname);
formData.append('address[present][houseno]', presenthouseno);
formData.append('address[present][village]', presentvillage);
formData.append('address[present][city]', presentcity);
formData.append('address[present][postoffice]', presentpostoffice);
formData.append('address[present][state]', presentstate);
formData.append('address[present][district]', presentdistrict);
formData.append('address[present][pincode]', presentpincode);

formData.append('address[permanent][pr_street]', permanentstreetname);
formData.append('address[permanent][pr_houseno]', permanenthouseno);
formData.append('address[permanent][pr_village]', permanentvillage);
formData.append('address[permanent][pr_city]', permanentcity);
formData.append('address[permanent][pr_postoffice]', permanentpostoffice);
formData.append('address[permanent][pr_state]', permanentstate);
formData.append('address[permanent][pr_district]', permanentdistrict);
formData.append('address[permanent][pr_pincode]', permanentpincode);

formData.append('isPresent', permanentAddressChecked);
formData.append('isDeclaration', declaration);
formData.append('paymentStatus', payStatus);
formData.append('paymentId', payId);
formData.append('examCentreState', chooseState);
formData.append('examCentreCity', chooseCity);

try{
  setLoading(true);
  setRazorpayResponse(true)
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_HOSTURL}`,
    headers: {
     'Content-Type': 'multipart/form-data',
    }
  });
  const res  = await instance.post(`${process.env.REACT_APP_HOSTURL}/api/v1/formdata`,formData, {
    onUploadProgress(progressEvent) {
      setProgress(
        Math.round((progressEvent.loaded / progressEvent.total) * 100)
      );
    },
  })

  console.log(res.data.status === 'success')

  if(res.data.status === 'success'){
    makePayment(data.body, res?.data?.body?._id)
  }


}catch(err){
  console.log('form not submit please try again later')
}

}
  useEffect(()=>{
    const fetchAllStates = async(req,res)=>{
      try{
        setCitiesLoading(true)
        const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/getstates`)
       // console.log(res.data.data);
        setState(res.data.data)
        setCitiesLoading(false)
      }catch(err){
        //console.log(err);
      }
    }
    
    const fetchAllCities = async()=>{
      try{
        setCitiesLoading(true)
        const res = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/getcities`,{
          stateNameId: chooseStateId
        });
        //console.log(res.data.data);
        setCity(res.data.data);
        setCitiesLoading(false)
      }catch(err){
        //console.log(err);
      }
    }
    fetchData()
    fetchAllCities()
    fetchAllStates()
  },[chooseStateId])


  useEffect(() => {
     window.scrollTo({top: 0, behavior: 'smooth'});
   // window.scrollTo(0, 0)
  }, [])

  const handleDatePickerChange = (date, dateString, id) => {
    setDob(dateString)
 }
// eslint-disable-next-line
 const handleJobPostChange = (value)=>{
    //console.log(value.key);
    setApplyFor(value.key)
 }
 

 const applyPostName = data?.body?.filter((d)=>{
   const pstNameApply =  d?._id === applyfor
   return pstNameApply
 });
applyPostName?.map((d,i)=>{  
  return postApplyName = d.name
})

useEffect(()=>{
  const post  = data?.body?.filter((data)=>{
    return data._id === applyfor
})


if(post?.length > 0){
  if(post[0].name === 'Airport Ground Staff / CSA'){
    const obj = {
      fees: `${Number(post[0].price) - 68.4} + GST`
    }
    setExaminationFees(obj)
  }else if(post[0].name === 'Loader / Housekeeping'){
    const obj = {
      fees: `${Number(post[0].price) - 61.2} + GST`
    }
    setExaminationFees(obj)
  }
}else if(post?.length === 0 || !post){
  const obj = {
    fees: 'Select Profile'
  }
  setExaminationFees(obj)
}


},[data?.body, applyfor])


  return (
   <>
    <div className='apply-form-main-div'>
        <div className='container '>
         <div className='inner-form-heading-div'> 
          <h3 id='app-form-title'>Application Form</h3>
          </div>
            <div className='container restpage-secondary-bg'>
                <div className='main-application-form-div'>
                  <div className='inner-main-app-form-head'>
                    <h3>Basic Information</h3>
                  </div>
                  <div className='app-forms-input-divs'>

                    <form onSubmit={(e)=> {submitForm("paymentnotfound", "notpaid"); e.preventDefault();}}>
                    {/* <form onSubmit={(e)=> {makePayment(data.body); e.preventDefault();}}> */}

                    {/* <form onSubmit={(e)=> {submitForm('123', 'demopayment'); e.preventDefault();}}> */}
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Candidate's First Name *</label>
                              {/* <input type="text"  onChange={(e)=> setCandidateName(e.target.value)}  required/> */}
                              <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdBorderColor className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter first name' aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setCandidateName(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Last Name *</label>
                              {/* <input type="text" onChange={(e)=> setLastName(e.target.value)} required/> */}
                              <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdBorderColor className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" aria-label="Username" placeholder='Enter last name'    required aria-describedby="basic-addon1"  onChange={(e)=> setLastName(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Father/Husband Name(Do not add Shri,Late,etc) *</label>
                              {/* <input type="text"  onChange={(e)=> setFatherName(e.target.value)}   required/> */}
                              <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdAccountBox className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" aria-label="Username" placeholder='Enter father/husband name'    required aria-describedby="basic-addon1" onChange={(e)=> setFatherName(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Mother Name(Do not add Shri,Late,etc) *</label>
                              {/* <input type="text"  onChange={(e)=> setMotherName(e.target.value)} required/> */}
                              <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdAccountBox className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter mother name'   aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setMotherName(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Date Of Birth (dd/mm/yyyy) *</label>
                              {/* <input type="date"  onChange={(e)=> setDob(e.target.value)}   required/> */}
                            
                             
                              <div className="input-group mb-3" id='date-picker-top-wrapper'>
                                <span className="input-group-text" id="basic-addon1"><BsFillCalendarDateFill className='input-icon' /></span>
                               <div id='date-pickker-border-div'  className="form-control shadow-none">
                               <DatePicker  format={dateFormatList}  style={{ width: "100%" , height: "38px", borderRadius: "0px"}} onChange={(date, dateString) => handleDatePickerChange(date, dateString, 1)} />
                               </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Gender *</label>
                              {/* <select name="" id="" onChange={(e)=> setGender(e.target.value)} >
                                <option value="false">Select Option</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select> */}
                               <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdTransgender className='input-icon' /></span>
                                <select  className="form-control form-select shadow-none" onChange={(e)=> setGender(e.target.value)} >
                                <option value="false">Select Option</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>

                              
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Category(Cast) *</label>
                              {/* <select name="" id="" onChange={(e)=> setCast(e.target.value)}>
                                <option value="false">Select Option</option>
                                <option value="General">General</option>
                                <option value="ST">ST</option>
                                <option value="SC">SC</option>
                                <option value="OBC">OBC</option>
                              </select> */}
                               <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdWorkspaces className='input-icon' /></span>
                                <select className="form-control form-select  shadow-none" onChange={(e)=> setCast(e.target.value)}>
                                <option value="false">Select Option</option>
                                <option value="General">General</option>
                                <option value="ST">ST</option>
                                <option value="SC">SC</option>
                                <option value="OBC">OBC</option>
                              </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Marital Status *</label>
                              {/* <select name="" id="" onChange={(e)=> setMarried(e.target.value)}>
                                <option value="false">Select Option</option>
                                <option value="Married">Married</option>
                                <option value="Unmarried">Unmarried</option>
                              </select> */}
                               <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><BsPeopleFill className='input-icon' /></span>
                                <select className="form-control form-select shadow-none" onChange={(e)=> setMarried(e.target.value)}>
                                <option value="false">Select Option</option>
                                <option value="Married">Married</option>
                                <option value="Unmarried">Unmarried</option>
                              </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Apply For *</label>
                              {/* <select name="" id="" onChange={(e)=> setApplyFor(e.target.value)}>
                                <option value="false">Select Option</option>
                                {
                                    data?.body?.map(d =>  (
                                        <option value={d._id}  key={d._id} >{d.name}</option>
                                      
                                          ))
                                }
                              </select> */}

                                <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdAirplanemodeActive className='input-icon' /></span>
                                <select className="form-control form-select  shadow-none" onChange={(e)=> setApplyFor(e.target.value)}>
                                <option value="false">Select Option</option>
                                {
                                    data?.body?.map((d,i) => {
                                      return(
                                        <option value={d._id}   key={d._id} >{d.name}</option>
                                      )
                                    })
                                }
                              </select>
                              </div>
                             

                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div-mobile-email'>
                             <div id='form-email-div'>
                             <label htmlFor="">Examination Fees</label>
                              <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1" style={{color: 'white'}}>&#8377;</span>
                                <input type="email" className="form-control shadow-none" placeholder='Enter email'   aria-label="Username" aria-describedby="basic-addon1" disabled value={`${examinationFees.fees}`}/>
                              </div>
                             </div>
                           
                            </div>
                          </div>
                        </div>
                      </div>




                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Email *</label>
                              {/* <input type="text"  onChange={(e)=> setCandidateName(e.target.value)}  required/> */}
                              <div className="input-group mb-3">
                              <span className="input-group-text" id="basic-addon1"><MdEmail className='input-icon' /></span>
                                <input type="email" className="form-control shadow-none" placeholder='Enter email' aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setEmail(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Mobile *</label>
                              {/* <input type="text" onChange={(e)=> setLastName(e.target.value)} required/> */}
                              <div className="input-group mb-3">
                              <span className="input-group-text" id="basic-addon1"><MdPhoneInTalk className='input-icon' /></span>
                                <input type="number" className="form-control shadow-none" aria-label="Username" placeholder='Enter phone number'    required aria-describedby="basic-addon1"  onChange={(e)=> setPhone(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Educational Qualification *</label>
                              {/* <select name="" id="" onChange={(e)=> setEducation(e.target.value)}>
                                <option value="false">Select Option</option>
                                <option value="none">None</option>
                                <option value="Undergraduate">Undergraduate</option>
                                <option value="Graduation">Graduation</option>
                                <option value="Post-Graduation">Post-Graduation</option>
                              </select> */}
                               <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdSchool className='input-icon' /></span>
                                <select className="form-control form-select shadow-none" onChange={(e)=> setEducation(e.target.value)}>
                                <option value="false">Select Option</option>
                                <option value="none">None</option>
                                <option value="Undergraduate">Undergraduate</option>
                                <option value="Graduation">Graduation</option>
                                <option value="Post-Graduation">Post-Graduation</option>
                              </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Division *</label>
                              {/* <select name="" id="" onChange={(e)=>  setEducationDivision(e.target.value)}>
                                <option value="false">Select Option</option>
                                <option value="none">None</option>
                                <option value="First Division">First Division</option>
                                <option value="Second Devision">Second Devision</option>
                                <option value="Third Devision">Third Devision</option>
                              </select> */}
                               <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><BsFillDiagram2Fill className='input-icon' /></span>
                                <select className="form-control form-select  shadow-none" onChange={(e)=>  setEducationDivision(e.target.value)}>
                                <option value="false">Select Option</option>
                                <option value="none">None</option>
                                <option value="First Division">First Division</option>
                                <option value="Second Devision">Second Devision</option>
                                <option value="Third Devision">Third Devision</option>
                              </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Intermediate *</label>
                            {/* <input type="text" placeholder='Enter Your Board Name with School Name'  onChange={(e)=>  setIntermediate(e.target.value)} required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdSchool className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter Your Board Name with School Name' aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=>  setIntermediate(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Division *</label>
                              {/* <select name="" id="" onChange={(e)=>   setIntermediateDivision(e.target.value)}>
                                <option value="false">Select Option</option>
                                <option value="none">None</option>
                                <option value="First Division">First Division</option>
                                <option value="Second Devision">Second Devision</option>
                                <option value="Third Devision">Third Devision</option>
                              </select> */}
                               <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><BsFillDiagram2Fill className='input-icon' /></span>
                                <select className="form-control form-select  shadow-none" onChange={(e)=> setIntermediateDivision(e.target.value)}>
                                <option value="false">Select Option</option>
                                <option value="none">None</option>
                                <option value="Grades">Grades</option>
                                <option value="First Division">First Division</option>
                                <option value="Second Devision">Second Devision</option>
                                <option value="Third Devision">Third Devision</option>
                              </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">High School *</label>
                            {/* <input type="text" placeholder='Enter Your Board Name with School Name'  onChange={(e)=>   setHighSchool(e.target.value)} required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdSchool className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter Your Board Name with School Name' aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=>   setHighSchool(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Division *</label>
                              {/* <select name="" id=""  onChange={(e)=>   setHighSchoolDivision(e.target.value)}>
                                <option value="false">Select Option</option>
                                <option value="none">None</option>
                                <option value="First Division">First Division</option>
                                <option value="Second Devision">Second Devision</option>
                                <option value="Third Devision">Third Devision</option>
                              </select> */}
                               <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><BsFillDiagram2Fill className='input-icon' /></span>
                                <select className="form-control form-select  shadow-none"  onChange={(e)=>   setHighSchoolDivision(e.target.value)}>
                                <option value="false">Select Option</option>
                                <option value="none">None</option>
                                <option value="Grades">Grades</option>
                                <option value="First Division">First Division</option>
                                <option value="Second Devision">Second Devision</option>
                                <option value="Third Devision">Third Devision</option>
                              </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p  className='ps-3 text-danger'>Please upload Image Only <b>.jpg, jpeg, .png</b> File Format Only !</p>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                          
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Upload Photo *</label>
                              <div className='form-upload-photo-div'>
                              <input accept='.jpg, .png, .jpeg'  className='border border-0' type="file" onChange={(e)=> setProfilePhoto(e.target.files[0])}  required/>
                            <img src={profilePhoto ? URL.createObjectURL(profilePhoto): null} width={80} height={80} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Upload Signature *</label>
                              <div className='form-upload-photo-div'>
                              <input accept='.jpg, .png, .jpeg'  className='border border-0' type="file"  onChange={(e)=>  setSignature(e.target.files[0])} required/>
                            <img src={signature ? URL.createObjectURL(signature) : null} width={80} height={80} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='prsnt-add-div'>
                        <h3>Present Address</h3>
                      </div>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <div className='prsnt-add-el-div'>
                            <label htmlFor="">Street Name *</label>
                            {/* <input type="text"  onChange={(e)=> setPresentStreetName(e.target.value)}  required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdOutlineAddRoad className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter street name'   aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setPresentStreetName(e.target.value)} />
                              </div>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">House No *</label>
                            {/* <input type="text" onChange={(e)=> setPresentHouseNo(e.target.value)}  required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdHome className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter house no.'   aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setPresentHouseNo(e.target.value)} />
                              </div>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">Village *</label>
                            {/* <input type="text"  onChange={(e)=> setPresentVillage(e.target.value)} required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdOutlineHolidayVillage className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter village name'   aria-label="Username" required aria-describedby="basic-addon1"  onChange={(e)=> setPresentVillage(e.target.value)} />
                              </div>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">City *</label>
                            {/* <input type="text" onChange={(e)=> setPresentCity(e.target.value)} required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdOutlineApartment className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter city name'   aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setPresentCity(e.target.value)} />
                              </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <div className='prsnt-add-el-div'>
                            <label htmlFor="">Post-Office *</label>
                            {/* <input type="text"  onChange={(e)=> setPresentPostOffice(e.target.value)}   required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><BsFilePostFill className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter post-office '   aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setPresentPostOffice(e.target.value)} />
                              </div>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">State *</label>
                            {/* <select name="" id="" onChange={(e)=> setPresentState(e.target.value)}>
                              <option value="false">State</option>
                              {state?.map((d,i)=>{
                                return(
                                  <option value={d.stateName} id={d._id} key={i}>{d.stateName}</option>
                                )
                              })}
                            </select> */}
                             <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdMap className='input-icon' /></span>
                                <select className="form-control form-select  shadow-none" onChange={(e)=> setPresentState(e.target.value)}>
                              <option value="false">State</option>
                              {state?.map((d,i)=>{
                                return(
                                  <option value={d.stateName} id={d._id} key={i}>{d.stateName}</option>
                                )
                              })}
                            </select>
                              </div>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">District *</label>
                            {/* <input type="text"  onChange={(e)=> setPresentDistrict(e.target.value)} required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdLocationCity className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter district'   aria-label="Username" required aria-describedby="basic-addon1"  onChange={(e)=> setPresentDistrict(e.target.value)} />
                              </div>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">Pincode *</label>
                            {/* <input type="number" onChange={(e)=> setPresentPincode(e.target.value)} required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdFormatListNumbered className='input-icon' /></span>
                                <input type="number" className="form-control shadow-none" placeholder='Enter pincode'   aria-label="Username" required aria-describedby="basic-addon1"  onChange={(e)=> setPresentPincode(e.target.value)} />
                              </div>
                          </div>
                        </div>
                      </div>
                      <div id='same-address-check'>
                        <input type="checkbox"   onChange={sameAddress}/>
                        <p>Same As Present Address</p>
                       </div>
                      <div className='prsnt-add-div'>
                        {
                          permanentAddressChecked ? (
                          <>
                           <div className='prsnt-add-div'>
                        <h3>Permanent Address</h3>
                      </div>
                       <div className='row'>
                        <div className='col-sm-3'>
                          <div className='prsnt-add-el-div'>
                            <label htmlFor="">Street Name *</label>
                            {/* <input type="text"  onChange={(e)=> setPermanentStreetName(e.target.value)} required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdOutlineAddRoad className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter street name'   aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setPermanentStreetName(e.target.value)} />
                              </div>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">House No *</label>
                            {/* <input type="text"  onChange={(e)=> setPermanentHouseNo(e.target.value)} required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdHome className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter house no.'   aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setPermanentHouseNo(e.target.value)} />
                              </div>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">Village *</label>
                            {/* <input type="text" onChange={(e)=> setPermanentVillage(e.target.value)} required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdOutlineHolidayVillage className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter village name'   aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setPermanentVillage(e.target.value)} />
                              </div>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">City *</label>
                            {/* <input type="text"  onChange={(e)=> setPermanentCity(e.target.value)} required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdOutlineApartment className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter city name'   aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setPermanentCity(e.target.value)} />
                              </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <div className='prsnt-add-el-div'>
                            <label htmlFor="">Post-Office *</label>
                            {/* <input type="text" onChange={(e)=> setPermanentPostOffice(e.target.value)} required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><BsFilePostFill className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter post office'   aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setPermanentPostOffice(e.target.value)} />
                              </div>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">State *</label>
                            {/* <select name="" id="" onChange={(e)=> setPermanentState(e.target.value)}>
                              <option value="false">State</option>
                              {state?.map((d,i)=>{
                                return(
                                  <option value={d.stateName} id={d._id} key={i}>{d.stateName}</option>
                                )
                              })}
                            </select> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdMap className='input-icon' /></span>
                                <select className="form-control form-select  shadow-none" onChange={(e)=> setPermanentState(e.target.value)}>
                              <option value="false">State</option>
                              {state?.map((d,i)=>{
                                return(
                                  <option value={d.stateName} id={d._id} key={i}>{d.stateName}</option>
                                )
                              })}
                            </select>
                              </div>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">District *</label>
                            {/* <input type="text" onChange={(e)=> setPermanentDistrict(e.target.value)} required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdLocationCity className='input-icon' /></span>
                                <input type="text" className="form-control shadow-none" placeholder='Enter district'   aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setPermanentDistrict(e.target.value)} />
                              </div>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">Pincode *</label>
                            {/* <input type="number"  onChange={(e)=> setPermanentPincode(e.target.value)}  required/> */}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdFormatListNumbered className='input-icon' /></span>
                                <input type="number" className="form-control shadow-none" placeholder='Enter pincode'   aria-label="Username" required aria-describedby="basic-addon1" onChange={(e)=> setPermanentPincode(e.target.value)} />
                              </div>
                          </div>
                        </div>
                      </div>
                          </>):(
                          <>
                          
                          </>)
                        }
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Examination Centre State *</label>
                              {/* <select name="" id="" onChange={getStateId}>
                               <option value="false" className='text-muted'>State</option>
                              {state?.map((d,i)=>{
                                return(
                                  <option value={d.stateName} id={d._id} key={i}>{d.stateName}</option>
                                )
                              })}
                              </select> */}
                              <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><BsFillPinMapFill className='input-icon' /></span>
                                <select className="form-control form-select  shadow-none" onChange={getStateId}>
                               <option value="false" className='text-muted'>Select Exam State</option>
                              {state?.map((d,i)=>{
                                return(
                                  <option value={d.stateName} id={d._id} key={i}>{d.stateName}</option>
                                )
                              })}
                              </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Exam Centre City *</label>
                              {/* <select name="" id="" onChange={(e)=> setChooseCity(e.target.value)}>
                               <option value="false">City</option>
                              {city?.map((d,i)=>{
                                return(
                                  <option value={d.stateCityName} key={i}>{d.stateCityName}</option>
                                )
                              })}
                              </select> */}
                              <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><MdPinDrop className='input-icon' /></span>
                                <select className="form-control form-select  shadow-none" onChange={(e)=> setChooseCity(e.target.value)}>
                               <option value="false">Select Exam City</option>
                              {city?.map((d,i)=>{
                                return(
                                  <option value={d.stateCityName} key={i}>{d.stateCityName}</option>
                                )
                              })}
                              </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='prsnt-add-div'>
                        <h3>Declaration / घोषणा</h3>
                      </div>
                      <div id='check-declaration-div'>
                        <input type="checkbox" required  onChange={declartionFunc}/>
                        <p>I declare that the name, class, date of birth, address and other information given by me in the online application form is correct to the best of my knowledge and belief. Which I declare to be truely correct. If the above information is found incomplete or incorrect, my candidature is liable to be terminated at any time.</p>
                      </div>
                      {progress && <Progress percent={progress}/>}
                      <div className='form-submit-btn-divs'>
                       {razorpayResponse ? (
                         <>
                       <div className='form-reg-btn-div'>
                        <button disabled={true}>
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </button>
                        <p>Please wait we try to submit your form <br/>
                          Do not go back or refresh this page<br/>
                          You'll be redirect to your application form soon !!
                        </p>
                        </div>
                       </>
                       ):(
                         <>
                         <p className='btn btn-success' data-bs-toggle="modal" data-bs-target="#exampleModal">Preview</p>
                        <button type='submit' className='btn btn-success' disabled={loading ?  true : false}>Submit</button>
                       </>)}
                      </div>
                        <p className='text-center fw-bold text-danger'>{errMsg}</p>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog  modal-xl">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Preview Page</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className='container prev-main-div'>
            <div className='prev-inner-div'>
              <div className='prev-title-div'><h4>Check Your Form Details Before Submit</h4></div>
            <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Candidate's First Name *</label>
                              <p className='w-100'>{candidatename}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Last Name *</label>
                            <p>{lastname}</p>
                            </div>
                          </div>
                        </div>
            </div>
            <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Father/Husband Name(Do not add Shri,Late,etc) *</label>
                              <p className='w-100'>{fathername}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Mother Name(Do not add Shri,Late,etc) *</label>
                            <p>{mothername}</p>
                            </div>
                          </div>
                        </div>
            </div>
            <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Date Of Birth *</label>
                              <p className='w-100'>{dob}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Gender *</label>
                            <p>{gender}</p>
                            </div>
                          </div>
                        </div>
            </div>
            <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Category(Cast) *</label>
                              <p className='w-100'>{cast}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Marital Status *</label>
                            <p>{married}</p>
                            </div>
                          </div>
                        </div>
            </div>
            <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Apply For *</label>
                              <p>{postApplyName}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div p-3'>
                            <div className='inpt-el-inr-div-mobile-email border py-3  prev-emailMobile-div'>
                             <div id='form-email-div'>
                             <label htmlFor="">Examination Fees</label>
                             <p>{examinationFees.fees}</p>
                             </div>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Email *</label>
                              <p className='w-100'>{email}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Mobile Number *</label>
                            <p>{phone}</p>
                            </div>
                          </div>
                        </div>
            </div>



                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Educational Qualification *</label>
                              <p className='w-100'>{education}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Division *</label>
                            <p>{educationdivision}</p>
                            </div>
                          </div>
                        </div>
            </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Intermediate *</label>
                              <p className='w-100'>{intermediate}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Division *</label>
                            <p>{intermediatedivision}</p>
                            </div>
                          </div>
                        </div>
            </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">High School *</label>
                              <p className='w-100'>{highschool}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Division *</label>
                            <p>{highschooldivision}</p>
                            </div>
                          </div>
                        </div>
            </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Upload Photo *</label>
                              <img src={profilePhoto ? URL.createObjectURL(profilePhoto): null} width={80} height={80} alt=""/>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div border p-3'>
                              <label htmlFor="">Upload Signature *</label>
                            <img src={signature ? URL.createObjectURL(signature) : null} width={80} height={80} alt="" />

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='prsnt-add-div'>
                        <h3>Present Address</h3>
                      </div>
                      <div className='row border p-3'>
                        <div className='col-sm-3'>
                          <div className='prsnt-add-el-div'>
                            <label htmlFor="">Street Name *</label>
                          <p className='w-100'>{presentstreetname}</p>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">House No *</label>
                            <p className='w-100'>{presenthouseno}</p>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">Village *</label>
                             <p className='w-100'>{presentvillage}</p>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">City *</label>
                             <p className='w-100'>{presentcity}</p>
                          </div>
                        </div>
                      </div>
                      <div className='row border p-3'>
                        <div className='col-sm-3'>
                          <div className='prsnt-add-el-div'>
                            <label htmlFor="">Post-Office *</label>
                             <p className='w-100'>{presentpostoffice}</p>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">State *</label>
                            <p className='w-100'>{presentstate}</p>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">District *</label>
                             <p className='w-100'>{presentdistrict}</p>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">Pincode *</label>
                             <p className='w-100'>{presentpincode}</p>
                          </div>
                        </div>
                      </div>
                      
                      <div>
                      {
                          permanentAddressChecked ? (
                          <>
                           <div className='prsnt-add-div'>
                        <h3>Permanent Address</h3>
                      </div>
                       <div className='row border p-3'>
                        <div className='col-sm-3'>
                          <div className='prsnt-add-el-div'>
                            <label htmlFor="">Street Name *</label>
                            <p className='w-100'>{permanentstreetname}</p>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">House No *</label>
                            <p className='w-100'>{permanenthouseno}</p>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">Village *</label>
                             <p className='w-100'>{permanenthouseno}</p>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">City *</label>
                            <p className='w-100'>{permanentcity}</p>
                          </div>
                        </div>
                      </div>
                      <div className='row border p-3'>
                        <div className='col-sm-3'>
                          <div className='prsnt-add-el-div'>
                            <label htmlFor="">Post-Office *</label>
                            <p className='w-100'>{permanentpostoffice}</p>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">State *</label>
                            <p className='w-100'>{permanentstate}</p>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">District *</label>
                             <p className='w-100'>{permanentdistrict}</p>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                        <div className='prsnt-add-el-div'>
                            <label htmlFor="">Pincode *</label>
                            <p className='w-100'>{permanentpincode}</p>
                          </div>
                        </div>
                      </div>
                          </>):(
                          <>
                          
                          </>)
                        }
                      </div>
                      <div className='row'>
                        <div className='col-sm-6 '>
                          <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Examination Centre State *</label>
                              <p className='w-100'>{chooseState}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div className='input-form-el-div'>
                            <div className='inpt-el-inr-div'>
                              <label htmlFor="">Exam Centre City *</label>
                              {
                                citiesLoading ? (
                                  <>
                                  <p>Loading...</p>
                                  </>
                                ) : (
                                  <>
                              <p className='w-100'>{chooseCity}</p>
                                  </>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>

            </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
        {/* <button type="button" className="btn btn-warning" onClick={()=> window.print()}>Print</button> */}
      </div>
    </div>
  </div>
</div>
    </div>
   </>
  )
}

export default FormHandler