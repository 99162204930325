import React, { useState } from 'react'
import '../styles/register.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {login} from '../context/userSlice';

import { useDispatch } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NotificationSound from '../audio/Bas1.m4a';

const Register = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectPost, setSelectPost] = useState();
  const [studentName, setStudentName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNum, setMobileNum] = useState('');
  const [password, setPassword] = useState('');
  const [selectErrMsg, setSelectErrMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [resMsg, setResMsg] = useState('')



  const registerUser = async(e)=>{
    
    try{
      e.preventDefault()
      setLoading(true)
      if(selectPost === '' || selectPost === null || selectPost === undefined){
        setLoading(false)
        return setSelectErrMsg('Please Select Profile !! ')
      }

      const res = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/userregistration`,{
        name: studentName,
        email: email,
        jobProfile: selectPost,
        phone: mobileNum,
        password: password,
      });

      
      console.log('reg :', res.data);

      function playAudio() {
        // audioPlayer.current.play();
        const audio = new Audio(NotificationSound)
        audio.play()
      }

      const notify = () => toast.warn("Already Registered With This E-mail !!", {
        position: "top-center",
        autoClose: 18000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });

      //  console.log(res.data.status)
      //console.log(res.data)
      // console.log(res.data.message)

      //console.log(res.data.registrationnumber);

      if(res.data.status === 201){
        const {_id,name,email } = res.data.body
        let regNum =  res.data.registrationnumber
        
        if(!regNum || regNum === undefined || regNum === ''){
          return alert('Your registration number not assigned. please login again your registration number and password has been send to your registered email address !')
        }

        const obj ={
          id:_id,
          regNum: regNum,
          canName: name,
          canEmail: email
        }
        dispatch(login(obj))
        navigate('/application-form')
        setLoading(false)
        setResMsg(res.data.message) 

      }else if(res.data.message){
        setResMsg(res.data.message)
        notify()
        playAudio()
        setTimeout(()=>{
          navigate('/login')
        },18000)

        setSelectErrMsg('')
        setLoading(false)
        e.target.reset();
      }
    }catch(err){

      console.log('Error: ',err)

      const notifyErr = () => toast.warn("Something Error Please Try Again Later !!", {
        position: "top-center",
        autoClose: 18000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
        
        if(err){

          notifyErr()
          setLoading(false)
        }

    }
  }

  return (
    <>
    <div className='register-main-div restpage-secondary-bg'>
        <div className='container register-inner-main-div'>
        <ToastContainer 
        position="top-center"
        autoClose={18000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
                />
            <div className='register-inner-div'>
            <div className='registration-title-div'>
          <h3>Registration</h3>
             </div>
                <form onSubmit={registerUser}>
                  <div className='inner-form-register-elements'>
                   
                    <div className='select-reg-inner-form-el'>
                      <label htmlFor="">Select Profile</label>
                    <select className="form-select" aria-label="Default select example" required onChange={(e)=> setSelectPost(e.target.value)}>
                        <option value='none'>Select Profile</option>
                        <option value="Airport Ground Staff / CSA">Airport Ground Staff / CSA</option>
                        <option value="Loader / Housekeeping">Loader / Housekeeping</option>
                      </select>
                    </div>
                    <div className='form-inner-reg-input-el'>
                        <label htmlFor="">Student Name</label>
                        <input className='form-control' type="text" placeholder='enter your name' required onChange={(e)=> setStudentName(e.target.value)}/>
                    </div>
                    <div className='form-inner-reg-input-el'>
                        <label htmlFor="">E-mail Address</label>
                        <input className='form-control' type="email" placeholder='enter your email' required onChange={(e)=> setEmail(e.target.value)}/>
                    </div>
                    <div className='form-inner-reg-input-el'>
                        <label htmlFor="">Mobile Number</label>
                        <input className='form-control' type="number" placeholder='enter your phone number' required onChange={(e)=> setMobileNum(e.target.value)} />
                    </div>
                    <div className='form-inner-reg-input-el'>
                        <label htmlFor="">Password</label>
                        <input className='form-control' type="date" placeholder='enter your name' required onChange={(e)=> setPassword(e.target.value)}/>
                    </div>
                    <div>
                    {
                      loading ? (
                        <>
                           <div className='form-reg-btn-div'>
                        <button disabled={true}>
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </button>
                        <p>Already have an account ?</p>
                        </div>
                        </>
                      ) : (
                        <>
                        <div className='form-reg-btn-div'>
                        <button type='submit'>Register</button>
                        <Link to={'/login'}>
                        <p>Already have an account ? </p>
                        </Link>
                        <p className='text-danger'>{selectErrMsg}</p>
                        </div>
                        </>
                      )
                    }
                    </div>
                    <p className='text-center mt-5 fw-bold text-success'>{resMsg}</p>
                  </div>
                </form>
            </div>
        </div>
    </div>
    </>
  )
}

export default Register