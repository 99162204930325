import React from 'react'

const Error = () => {

  return (
   <>
   <div className="alert alert-warning text-center mt-4 mb-4" role="alert">
   <h1>404 Page Not Found!!</h1>
  </div>
   </>
  )
}

export default Error