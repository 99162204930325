import React from 'react'
import '../Admin.css'
import { useDispatch,useSelector } from 'react-redux';
import {adminlogoutuser} from '../../context/adminSlice'
import { useNavigate } from 'react-router-dom';


const DBheaderTopbar = () => {
  const adminBasData =  useSelector((state)=> state.basAdminDetail)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const logOutAdmin =  (e) => {
    e.preventDefault()
      dispatch(adminlogoutuser())
      navigate('/')
  }

  return (
    <>
    <div className='container-fluid dashboard-topbar'>
        <div className='container db-header-inner-bar' >
            <h3>Admin Dashboard Panel</h3>
            <div className='d-flex align-items-center'>
              <p className='me-3 fw-bold text-light'>{adminBasData.basAdminDetail?.email}</p>
            <button className='btn btn-light db-hear-lgout-btn' onClick={logOutAdmin}>Logout</button>
            </div>
        </div>
    </div>
    </>
  )
}

export default DBheaderTopbar