import React from 'react'
import { lazy, Suspense } from 'react';

//import VisionValueComp from '../components/pages/VisionValueComp'

const VisionValueComp = lazy(()=> import('../components/pages/VisionValueComp'))

const VisionandValue = () => {
  return (
    <>
      <Suspense fallback={ <div className='text-center'>
    <div className="spinner-grow" style={{width: '3rem', height: '3rem', color: 'brown'}} role="status">
    <span className="visually-hidden text-success">Loading...</span>
    
  </div>
   </div>}>
   <VisionValueComp/>
   </Suspense>
    </>
  )
}

export default VisionandValue