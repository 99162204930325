import React from 'react'
import { lazy, Suspense } from 'react';

//import SyllabusComp from '../components/pages/Syllabus'

const SyllabusComp = lazy(()=> import('../components/pages/Syllabus'))

const Syllabus = () => {
  return (
    <>
     <Suspense fallback={ <div className='text-center'>
    <div className="spinner-grow" style={{width: '3rem', height: '3rem', color: 'brown'}} role="status">
    <span className="visually-hidden text-success">Loading...</span>
    
  </div>
   </div>}>
   <SyllabusComp/>
   </Suspense>
    </>
  )
}

export default Syllabus