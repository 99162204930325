import React from 'react'
import axios from 'axios'
import { useState } from 'react';
import { useEffect } from 'react';

const DBnotification = () => {

  const [hindiNotificationUrl, setHindiNotificationUrl] = useState('');
  const [engNotificationUrl, setEngNotificationUrl] = useState('');
  const [notificationUrl, setNotificationUrl] = useState({});
  const [notificationURLUpdated, setNotificationURLUpdated] = useState({});


  const notificationDownloadUrl = `${process.env.REACT_APP_HOSTURL}/api/v1/notificationdownload`;

let hindiUploadNotificationUrl = notificationUrl?.notification?.url[0]?.notificationurlhindi
 let englishIploadNotificationUrl = notificationUrl?.notification?.url[0]?.notificationurlenglish



  const fetchNotificationUrlDownlaod =  async()=>{
    try{

      const res = await axios.get(notificationDownloadUrl)
      setNotificationUrl(res.data)
      // console.log(res.data.notification.url[0].notificationurlenglish);
      // console.log(res.data.notification.url[0].notificationurlhindi);

    }catch(err){
        //console.log('Error: ', err);
    }
}


const updateHindiNotificationUrl = async(e)=>{
  e.preventDefault()

  const notificationUrlId = notificationUrl.notification.url[0]._id;
    try{
      const res = await axios.patch(`${process.env.REACT_APP_HOSTURL}/api/v1/notificationdownload/${notificationUrlId}`,{
        notificationurlhindi: hindiNotificationUrl
      });
      setNotificationURLUpdated(res.data)
      e.target.reset()
    }catch(err){
     // console.log(err);
    }
}

const updateEnglishNotificationUrl =  async(e)=>{
    e.preventDefault()
    const notificationUrlId = notificationUrl.notification.url[0]._id;
    try{
      const res = await axios.patch(`${process.env.REACT_APP_HOSTURL}/api/v1/notificationdownload/${notificationUrlId}`,{
        notificationurlenglish: engNotificationUrl
      });
      setNotificationURLUpdated(res.data)
      e.target.reset()


    }catch(err){
      //console.log(err);
    }
}


  //syllabus upload
  const [syllabusData, setSyllabusData] = useState([])

  //post syllabus state 
  const [syllabusTitle, setSyllabusTitle] = useState()
  const [syllabusMarks, setSyllabusMarks] = useState()
  const [syllabusContent, setSyllabusContent] = useState()
  const [createSyllabasLoading, setCreateSyllabasLoading] = useState(false);
  const [syllabasPostMsg, setSyllabasPostMesg] = useState('')

  //update syllabus state
  const [updatesyllabusTitle, setupdatesSyllabusTitle] = useState()
  const [updatessyllabusMarks, setupdatesSyllabusMarks] = useState()
  const [updatessyllabusContent, setupdatesSyllabusContent] = useState()
  const [updatedSyllabusMsg, setUpdatedSyllabasMsg] = useState('')
  const [updMSG, setUpdMSG] = useState('')

  const createSyllabus = async(e)=>{
    e.preventDefault()
    try{
      setCreateSyllabasLoading(true)
      const res = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/syllabus`,{
        syllabusTitle: syllabusTitle,
        syllabusSubjectMarks: syllabusMarks,
        syllabusTableOfContent: syllabusContent
      })
      //console.log(res.data.data)
      setSyllabusData((prev => [...prev, res.data.data]))
      setSyllabasPostMesg(res.data.status)
      e.target.reset();
      setCreateSyllabasLoading(false)
    }catch(err){
      //console.log(err);
    }
  }

  
  const fetchSyllabus  = async()=>{
    try{
      const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/syllabus`)
      //console.log(res.data.data)
      setSyllabusData(res.data.data)
    }catch(err){
      //console.log(err);
    }
  }

  const updateSyllabusHandler =  async(e)=>{
    e.preventDefault()
    try{  
      const listId = e.target.id
    
      const res = await axios.patch(`${process.env.REACT_APP_HOSTURL}/api/v1/syllabus/${listId}`,{
        syllabusTitle: updatesyllabusTitle,
        syllabusSubjectMarks: updatessyllabusMarks,
        syllabusTableOfContent: updatessyllabusContent
      });

      //console.log(res.data);
      setUpdatedSyllabasMsg(res.data.status)
    }catch(err){
     // console.log(err);
    }
  }

  const deleteSyllabusHandler = async(e)=>{
    e.preventDefault()
    try{
      const listId = e.target.id
      const res = await axios.delete(`${process.env.REACT_APP_HOSTURL}/api/v1/syllabus/${listId}`);
      //console.log(res.data.message);
      //console.log(res.data.statusCode);

      if(res.data.statusCode){
        const delArrSyllabus = syllabusData.filter((d)=> d._id !== listId)
        setSyllabusData(delArrSyllabus)
        setUpdMSG(res.data.message)
      }

    }catch(err){
      //console.log(err)
    }
  }


useEffect(()=>{
  fetchNotificationUrlDownlaod()
  fetchSyllabus()
  // eslint-disable-next-line 
},[])




  return (
    <>
    <div className='right-side-page-component'>
      <div className='container notification-main-div'>
          <div className='row'>
            <div className='col-lg-7'>
              <div className='notification-upld-div-left-form m-4 p-2 shadow border'>
                <div style={{backgroundColor: ' #816500', color: 'white', padding: '10px', textAlign:'center'}}>
                <h4>Upload Notifications</h4>
                </div>
                <form onSubmit={updateHindiNotificationUrl} className='mt-2 mb-4'>
                <div className='d-flex align-items-start flex-column'>
                    <label className='fw-bold text-muted' htmlFor="">Upload Hindi Notification</label>
                    <input className='form-control mt-3 mb-3' type="url" placeholder='enter link' onChange={(e)=> setHindiNotificationUrl(e.target.value)}  required />
                    <button className='btn btn-warning fw-bold text-success'>Update</button>
                </div>
                </form>
                <form  onSubmit={updateEnglishNotificationUrl}>
                <div className='d-flex align-items-start flex-column'>
                    <label className='fw-bold text-muted' htmlFor="">Upload English Notification</label>
                    <input className='form-control mt-3 mb-3' type="url" placeholder='enter link' onChange={(e)=> setEngNotificationUrl(e.target.value)} required />
                    <button className='btn btn-warning fw-bold text-success'>Update</button>
                </div>
                </form>
                <div className='mt-3 mb-3 fw-bold text-success'>{notificationURLUpdated.message}</div>
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='notification-upld-list-right-div'>
                <div className='m-4 p-2'>
                  <div style={{backgroundColor: ' #816500', color: 'white', padding: '10px', textAlign:'center'}}>
                    <h4>Uploaded Notification Details</h4>
                  </div>
                <table className="table table-bordered">
                  <thead>
                    <tr className='text-muted'>
                     
                      <th scope="col">Hindi</th>
                      <th scope="col">English</th>
                    
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                   
                      <td><a href={hindiUploadNotificationUrl}>Click to open</a></td>
                      <td><a href={englishIploadNotificationUrl}>Click to open</a></td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
      </div>

      <div className='container syllabus-main-div'>
        <div className='db-syllabus-title'>
          <h3>Syllabus Upload Section</h3>
        </div>

        <div className='row'>
          <div className='col-sm-6'>
            <div className='syllabus-inner-left-form shadow'>
              <form onSubmit={createSyllabus}>
              <div className='syllabus-form-inner-el'>
                <label htmlFor="">Enter Syllabus Title</label>
                <input className='form-control' type="text" maxLength={'20'}  required onChange={(e)=> setSyllabusTitle(e.target.value)}/>
              </div>
              <div className='syllabus-form-inner-el'>
                <label htmlFor="">Enter Syllabus Marks</label>
                <input className='form-control' type="number" maxLength={'20'}  onChange={(e)=> setSyllabusMarks(e.target.value)}/>
              </div>
              <div className='syllabus-form-inner-el'>
                <label htmlFor="">Enter Syllabus Content</label>
               <textarea className='form-control' name="" id="" cols="30" rows="4" required onChange={(e)=> setSyllabusContent(e.target.value)}></textarea>
              </div>
              <div className='syllabus-post-btn-div'>
                {createSyllabasLoading ? (<>
                  <button type='submit' className='btn btn-warning' disabled={createSyllabasLoading ? true : false}>Loading...</button>
                </>) : (<>
                <button type='submit' className='btn btn-warning'>Post Syllabus</button>
                <p className='p-3'>{syllabasPostMsg}</p>
                </>)}
              </div>
              </form>
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='syllabus-inner-right-form-div border'>
              <h5>Uploaded Syllabus</h5>
              <p className='fw-bold text-danger'>{updMSG}</p>
            <div className="accordion" id="accordionExample">

 
    {
      syllabusData?.map((d,i)=>{
        return(
         
            <div key={d._id}>
            <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseThree${i}`} aria-expanded="false" aria-controls="collapseThree">
        {d.syllabusTitle}
      </button>
    </h2>
    <div id={`collapseThree${i}`} className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <form>
        <div className='syllabus-update-el'>
          <label htmlFor="">Update Syllabus Title</label>
          <input className='form-control' defaultValue={d.syllabusTitle} onChange={(e)=> setupdatesSyllabusTitle(e.target.value)}  type="text" />
        </div>
        <div className='syllabus-update-el'>
          <label htmlFor="">Update Syllabus Marks</label>
          <input className='form-control' defaultValue={d.syllabusSubjectMarks}   type="number" onChange={(e)=> setupdatesSyllabusMarks(e.target.value)}/>
        </div>
        <div className='syllabus-update-el'>
          <label htmlFor="">Update Syllabus Content</label>
         <textarea className='form-control' name="" id="" cols="30" rows="4" defaultValue={d.syllabusTableOfContent} onChange={(e)=> setupdatesSyllabusContent(e.target.value)}></textarea>
        </div>
        <div className='syllabus-update-btn-div'>
            <button className='btn btn-warning' id={d._id} onClick={updateSyllabusHandler}>Update</button>
            <button className='btn btn-danger' id={d._id} onClick={deleteSyllabusHandler}>Delete</button>
            <p>{updatedSyllabusMsg}</p>
        </div>
        </form>
      </div>
    </div>
            </div>
            </div>
        
        )
      })
    }
  
            </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}

export default DBnotification