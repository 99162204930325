import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import '../styles/componentPage.css'
const Banglore = () => {
  return ( 
    <>
       <Helmet> 
     <title>The Best Indigo Careers in Banglore | Bhartiya Aviation Services</title>
     <meta charSet='UTF-8'/>
       <meta name="keywords" content="Best Indigo Careers in Banglore, Airline Job  in Banglore"/>
       <meta name="description" content="Looking for an airline job in Banglore? Bhartiya Aviation Services can help you to achieve your dream job in the aviation industry. Call now at 8447-79-8447."/>
       <meta name="author" content="Bhartiya Aviation Services"/>
       <meta name="refresh" content="50"/>
       <meta property="og:image" content="/public/favicon_package_v0.16/apple-touch-icon.png"/>
       <meta http-equi="refresh" content="" url="https://bhartiyaaviation.in/indigo-careers-and-airline-job-in-banglore"/>
       <meta property="og:type" content="website"/>
<meta property="og:url" content="https://bhartiyaaviation.in/indigo-careers-and-airline-job-in-banglore"/>
<meta property="og:title" content="The Best Indigo Careers in Banglore | Bhartiya Aviation Services"/>
<meta property="og:description" content="Looking for an airline job in Banglore? Bhartiya Aviation Services can help you to achieve your dream job in the aviation industry. Call now at 8447-79-8447."/>
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>


<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://bhartiyaaviation.in/indigo-careers-and-airline-job-in-banglore"/>
<meta property="twitter:title" content="The Best Indigo Careers in Banglore | Bhartiya Aviation Services"/>
<meta property="twitter:description" content="Looking for an airline job in Banglore? Bhartiya Aviation Services can help you to achieve your dream job in the aviation industry. Call now at 8447-79-8447."/>
<meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon_package_v0.16/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon_package_v0.16/favicon-16x16.png"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" href="/public/logo192.png" />
       <link rel='canonical' href='https://bhartiyaaviation.in/indigo-careers-and-airline-job-in-banglore' />
   </Helmet>

   <div className='container-fluid  top-banner-pg-div-about-us'>
         <div className='inner-pg-wrapper-div'>
             <div className='container ot-page-banner-heading'>
                 <h1>Banglore</h1>
                 <Link to={'/'}>
                 <p>
                  Home/ banglore
                  </p>
                 </Link>
             </div>
         </div>
     </div>

     <div className='container'>
        <div className='green-field-img-div'>
            <img src="/images/banglore.png" alt="" />
        </div>
        <div className='green-field-text'>
            
            <h2 className='text-center mt-4 mb-4'><u>How Kempegowda International Airport was established?</u></h2>

            <p>Bangalore had a smaller airport for many years known as HAL Airport before the Kempegowda International Airport was built there in 2008. It was located 5 kilometers outside of the city's core. Until 2008, it served Bangalore as the main airport. HAL started its domestic activities in the late 1970s after being founded for military and defense uses. Unexpectedly popular new domestic flight options encouraged the airport's quick expansion. Beginning in the late 1990s, there were the first international flights. Royal Nepal Airlines was the first foreign airline to fly into HAL Airport from Kathmandu in 2000. A year later, Lufthansa's A340 from Germany started service. Several additional large international carriers followed suit soon after</p>

                <br/>

            <p>Bangalore, India's Silicon Valley, has become home to many industries and companies. HAL Airport, which has a single runway and little room for aircraft parking, has not been able to handle the growth of traffic. More than double its initial capacity of 3.6 million passengers annually, the airport handled eight million passengers in 2007. The airport apron could only accommodate six aircraft at once, and there was no room for growth. In March 1991, an international consortium was formed to build a new airport for Bangalore: Devanahalli Village in Karnataka State was chosen as the site for this new airport. The state government proposed that private firms help build and operate it; this proposal was approved by the central government in 1994. Finally, in 1995, AAI and the Karnataka government made the decision to form an international consortium to purchase, construct, and run the new greenfield airport in Bangalore City.</p>

            <br/>
            <p>The Kempegowda International Airport is the gateway to India’s business capital and it handles a large amount of domestic air traffic and a greater number of international travelers. It is the hub to many commercial airlines including the airline catering to business travelers, Jet Airways. The airport is located thirty kilometers north of the city, close to the Devanahalli suburb. It is owned and run by the public-private partnership Bengaluru International Airport Limited (BIAL), which opened in May 2008 as a replacement for the clogged-up HAL Airport. The airport bears Kempe Gowda I's name; he founded Bangalore.</p>
            <br/>
            <p>
            Kempegowda International Airport, the country's third busiest airport by passenger traffic, air traffic movements, and overall cargo handled, is located behind international airports at Delhi and Mumbai.
            </p>
           
            
        </div>
    </div>
    
    </>
  )
}

export default Banglore