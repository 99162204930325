import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { ExportJsonCsv } from 'react-export-json-csv';

const DBregisteredUsers = () => {


  const [regData, setRegData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [limit,setLimit] = useState(10)
  
  const [allFormsData, setAllFormsData] = useState([])
  const [csvLoading,setCSVLoading] = useState(false)

  const [totalUser,setTotalUser] = useState('')


  //!pagination
const [currentPage, setCurrentPage] =  useState(1);
// eslint-disable-next-line 
const [itemsPerpage, setItemsPerPage] = useState(11);

const indexOfLastitems =  currentPage * itemsPerpage;
const indexOfFirstItems =  indexOfLastitems -  itemsPerpage;
const currentItems = regData?.slice(indexOfFirstItems, indexOfLastitems);
// eslint-disable-next-line 
const [pageNumberLimit, setPageNumberLimit] = useState(5);
const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
const [minPageNumberLimit, setminPageNumberLimit]  = useState(0);

const pages = []

const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  }

  const handleNextBtn = () => {
    setCurrentPage(currentPage +1);
    if(currentPage +1 > maxPageNumberLimit){
        setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  }
  
  const  handlePrevBtn = () =>{
    setCurrentPage(currentPage - 1);
    if((currentPage -1)% pageNumberLimit ===0){
        setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  }

  let increamentBtn = null;
if(pages.length > maxPageNumberLimit){
  increamentBtn = <li onClick={handleNextBtn}> &hellip;  </li>
}

for (let i =0; i <= Math.ceil(regData.length/itemsPerpage); i++  ){
    pages.push(i);
}

const renderPageNumber = pages?.map((number)=> {
   if(number <  maxPageNumberLimit +1 && number > minPageNumberLimit){
    return(
        <li  key={number} id={number} onClick={handleClick} className={currentPage === number ? 'pagin-active' : 'pagin-li'}>
       {number}
       </li>
    )  
   }else{
       return null;
   }
})

//!pagination end

//!Export to Excel start 
const headers = [
  {
    key: 'userId',
    name: 'User ID',
  },
  {
    key: 'registrationnumber',
    name: 'Regitration ID',
  },
  {
    key: 'userName',
    name: 'User Name',
  },
  {
    key: 'userEmail',
    name: 'User Email',
  },
  {
    key: 'userPhone',
    name: 'User Phone',
  },
  {
    key: 'dateOBirth',
    name: 'Date Of Birth',
  },
  {
    key: 'jobprofile',
    name: 'Job Profile',
  },
  {
    key: 'userRole',
    name: 'Role',
  },
  {
    key: 'createdAt',
    name: 'Created At',
  },
  {
    key: 'updatedAt',
    name: 'Upated At',
  },
]

const datas = [
  {},
]

allFormsData?.map((a,index)=>{
    let obj ={
      userId: a._id,
      registrationnumber: a.registrationnumber,
      userName: a.name,
      userEmail: a.email,
      userPhone: a.phone,
      dateOBirth: a.password,
      jobprofile: a.jobProfile,
      userRole: a.role,
      createdAt: a.createdAt,
      updatedAt: a.updatedAt
    }
    datas.push(obj);
    return a;
});


//!Export to Excel end

const fetchAllFormsDataCSV = async()=>{
  setCSVLoading(true)
  try{
    const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/allregisteredusers?page=${1}&limit=${999999999}`)

    setAllFormsData(res.data.response)

  }catch(err){
   alert(err.response.data.message)
  }finally{
    setCSVLoading(false)
  }
}


function paginationNextHandler(){
  setPage((page)=> page +1)
  setLimit((limit)=> limit + 10)
  // if(page < 1){
   
  // }else{
  //   return
  // }
}

function paginationBackHandler(){
  setPage((page)=> page - 1)
  setLimit((limit)=> limit - 10)
}


  useEffect(()=>{
    const fetchRegUsers  = async()=>{
      setLoading(true)
      try{
        const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/allregisteredusers?page=${page}&limit=${limit}`)
        //console.log(res.data.response);
        setTotalUser(res.data.total)
        setRegData(res.data.response)
        setLoading(false)
      }catch(err){
        //console.log(err)
      }
    }
    fetchRegUsers()
  },[page, limit])



  return (
    <>
    <div className='right-side-page-component'>
      {loading ? (
      <>
      <div className='d-flex align-items-center justify-content-center flex-column'>
             <div className="loader"></div>
                    <p>Loading...</p>
            </div>
      
      </>):(
      <>
         <div className='container  reg-user-main-container'>
          <div className='text-center p-2 m-3  fw-bolder text-light' style={{backgroundColor: '#816500', color: 'white',}}>
            <h3>All Registered Users Lists</h3>
            </div>
        <div className='p-4 d-flex align-items-center justify-content-between'>
          <h6>{`Total Users: ${totalUser}`}</h6>


          {/* <ExportJsonCsv style={{padding: '8px' , borderRadius: '4px', backgroundColor: 'teal', color: 'white'}}  headers={headers} items={datas}>Export to CSV</ExportJsonCsv> */}

          {allFormsData.length === 0 ? (<button onClick={fetchAllFormsDataCSV} disabled={csvLoading} style={{
                padding: "8px",
                borderRadius: "4px",
                backgroundColor: "teal",
                color: "white",
              }}>{csvLoading ? 'Processing...': 'Load CSV Data'}</button>) : (
                <>
              <ExportJsonCsv
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  backgroundColor: "teal",
                  color: "white",
                }}
                headers={headers}
                items={datas}
                >
                Click To Download
              </ExportJsonCsv>
                </>
              )}


        </div>
       <table className="table table-bordered border-danger">
  <thead>
    <tr  style={{backgroundColor: '#816500', color: 'white',}}>
      <th scope="col">Reg No.</th>
      <th scope="col">Student Name</th>
      <th scope="col">Email Address</th>
      <th scope="col">Phone No.</th>
      <th scope="col">Profile Name</th>
      <th scope="col">Date Of Birth</th>
      <th scope="col">Date</th>
    </tr>
  </thead>
  <tbody>
    {
      currentItems?.map((d,i)=>{
        return (
          <tr key={i}>
          <th scope="row">{d?.registrationnumber}</th>
          <td>{d?.name}</td>
          <td>{d?.email}</td>
          <td>{d?.phone}</td>
          <td>{d?.jobProfile}</td>
          <td>{d?.password}</td>
          <td>{new Date(d?.createdAt).toDateString()}</td>
        </tr>
        )
      })
    }
  </tbody>
</table>
<div className='pagination-btn-div mb-4 mt-4'>
<button onClick={paginationBackHandler}  disabled={page=== 1}>Prev</button>
{/* {renderPageNumber}
{increamentBtn}  */}
{page}
<button  onClick={paginationNextHandler} >Next</button>
</div>
       </div>
      
      </>)}
    </div>
    </>
  )
}

export default DBregisteredUsers