import React, { useEffect, useState } from 'react'
import axios from 'axios';
// eslint-disable-next-line 
import { MdFileUpload,MdOutlineDeleteOutline,MdDeleteForever } from "react-icons/md";
// eslint-disable-next-line 
import {ref, uploadBytes, listAll, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import {storage} from '../../../firebase.js'

const BannerSlider = () => {



  const [bannerImg, setBannerImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imagesUrl , setImagesUrl] = useState([]);

  const [errMsg, setErrMsg] = useState('');


  const bannerUpload = async(e) =>{

    e.preventDefault()
      try{
        setLoading(true)
    if(bannerImg == null) {
      setErrMsg('please select an image')
      setLoading(false)
      return
    }
  
  
    const imageRef =  ref(storage, `bannerImages/${bannerImg.name + new Date().getTime()}`)
        const uploadImgResponse  =  await uploadBytesResumable(imageRef, bannerImg); 
  
        const uploadedImgUrl = await getDownloadURL(uploadImgResponse.ref)
        const res = await axios.post (`${process.env.REACT_APP_HOSTURL}/api/v1/bannerslider`,{
          name: bannerImg.name + new Date().getTime(),
          bannerImages : uploadedImgUrl
        })
       // console.log(res.data.data.data);
        setImagesUrl((prev)=> [ res.data.data.data, ...prev])
        setLoading(false);
        setBannerImg(null);
        setErrMsg('');
      }catch(err){
        //console.log(err)
      }
  }


  const fetchAllBannerImg = async() =>{
    try{
        const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/bannerslider`)
        //console.log(res.data.data.data);
        setImagesUrl(res.data.data.data)
        }catch(err){
     // console.log(err)
    }
  }
  
  
  const deleteBanner = async(e)=>{
    e.preventDefault();
      const id = e.currentTarget.id
      await axios.delete(`${process.env.REACT_APP_HOSTURL}/api/v1/bannerslider/${id}`)
      const delBannerArr =  imagesUrl.filter(d => d._id !== id)
      setImagesUrl(delBannerArr)
  }
  
  
  
  useEffect(()=>{
    fetchAllBannerImg()
  },[])
  
  

  return (
    <>
    <div className='container mt-5 mb-5'>
        <div className='bannr-upload-main-div'>
        <h3 style={{color: 'gray', paddingBottom: '20px'}}>Banner Upload <em className='fs-6'>{`upload imagse size(WxH)  1920 × 568`}</em> </h3>
          <div className='img-upload-div'>
          <label className='pe-4'  htmlFor="banner-up-img"><MdFileUpload style={{fontSize: '50px', color: 'rgb(88, 88, 88)', cursor: 'pointer'}}/></label>
            <input  className='upload-banner-img'  id='banner-up-img' type="file"  accept='.jpg, .png, .jpeg'  onChange={(e)=> setBannerImg(e.target.files[0])} />
            <img src={bannerImg ? URL.createObjectURL(bannerImg) : '/images/dummyimage.jpeg'} width={100} height={100}  alt=" " />
            <div  style={{display: 'flex', alignItems: 'center'}}>
              <button className='ms-5 mt-4 mb-4 btn btn-warning' onClick={bannerUpload} disabled={loading ? true :  false }  >Upload Image</button>
              <div>{loading ? (
                <>
                <div className="spinner-border text-primary ms-3" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
                </>
              ) :(null)}</div>
              </div>
              <div>
             <p>{errMsg}</p>
             </div>
          </div>
        </div>
        <div className='show-banner-images-container shadow-sm'>
          <div style={{backgroundColor: 'white', display: 'flex', flexWrap: 'wrap'}} className='p-5 shadow-sm'>
            {
              imagesUrl.map((d,i)=>{
                return(
                  <div className=' m-3 shadow' key={d._id} style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}> 
                  <img className='m-3'   src={d.bannerImages} width={100} height={100} alt="" />  
                  <MdOutlineDeleteOutline id={d._id}  onClick={deleteBanner}  style={{fontSize: '30px', color: 'tomato', cursor:'pointer'}}/>
                  </div>
                )
              })
            }
          </div>
        </div>
    </div>
</>
  )
}

export default BannerSlider