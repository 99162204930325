import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import '../styles/componentPage.css'

const Ahmedabad = () => {
  return (
    <>
       <Helmet> 
     <title>Top Airport job in Ahmedabad | Bhartiya Aviation Services</title>
     <meta charSet='UTF-8'/>
       <meta name="keywords" content="Best Indigo Careers in Ahmedabad, Top Airport job in Ahmedabad"/>
       <meta name="description" content="Find a top airport job in Ahmedabad and make your career bright with help of Bhartiya Aviation Services. Call now at 8447-58-8447"/>
       <meta name="author" content="Bhartiya Aviation Services"/>
       <meta name="refresh" content="50"/> 
       <meta property="og:image" content="/public/favicon_package_v0.16/apple-touch-icon.png"/>
       <meta http-equi="refresh" content="" url="https://bhartiyaaviation.in/indigo-careers-and-airport-job-in-ahmedabad"/>
       <meta property="og:type" content="website"/>
<meta property="og:url" content="https://bhartiyaaviation.in/indigo-careers-and-airport-job-in-ahmedabad"/>
<meta property="og:title" content="Top Airport job in Ahmedabad | Bhartiya Aviation Services"/>
<meta property="og:description" content="Find a top airport job in Ahmedabad and make your career bright with help of Bhartiya Aviation Services. Call now at 8447-58-8447"/>
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>


<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://bhartiyaaviation.in/indigo-careers-and-airport-job-in-ahmedabad"/>
<meta property="twitter:title" content="Top Airport job in Ahmedabad | Bhartiya Aviation Services"/>
<meta property="twitter:description" content="Find a top airport job in Ahmedabad and make your career bright with help of Bhartiya Aviation Services. Call now at 8447-58-8447"/>
<meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon_package_v0.16/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon_package_v0.16/favicon-16x16.png"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" href="/public/logo192.png" />
       <link rel='canonical' href='https://bhartiyaaviation.in/indigo-careers-and-airport-job-in-ahmedabad' />
   </Helmet>

   <div className='container-fluid  top-banner-pg-div-about-us'>
         <div className='inner-pg-wrapper-div'>
             <div className='container ot-page-banner-heading'>
                 <h1>Ahmedabad</h1>
                 <Link to={'/'}>
                 <p>
                  Home/ ahmedabad
                  </p>
                 </Link>
             </div>
         </div>
     </div>

     <div className='container'>
        <div className='green-field-img-div'>
            <img src="/images/ahmedabad.png" alt="" />
        </div>
        <div className='green-field-text'>
            
            <h2 className='text-center mt-4 mb-4'><u>How was Sardar Vallabhbhai Patel International Airport built?</u></h2>

            <p>Although the airport was built in 1937, international operations didn't start there until January 26, 1991. On May 23, 2000, it was designated as an international airport. A nonstop connection was established between Ahmedabad and the United Kingdom, where a sizable Gujarati community resides, in March 2004. Jet Airways joined a Boeing 747 service that was initially started by Air India to London's Heathrow Airport a few years later. But in 2008, both airlines made the decision to reduce their flying levels. After that, Air India launched a service to Frankfurt, which it maintained until closing its base there in 2010.</p>

                <br/>

            <p>The brand-new Terminal 2 was opened in 2010 to accommodate travelers from abroad. At the airport, a statue of Sardar Vallabhbhai Patel that stands 18 feet tall was also unveiled. The AAI requested bids in 2015 for the privatization of the airports in Ahmedabad, Chennai, Kolkata, and Jaipur. The next year, Air India started offering direct flights to London again. The airport's 700 kWp rooftop solar facility was inaugurated on March 21.</p>

            <br/>
            <p>Currently, the airport has four terminals: an international terminal, a terminal for secondary traffic, and a terminal for cargo. The airport's domestic and international terminals each include four aerobridges and 45 parking spaces. The landscapes of Singapore Changi Airport served as inspiration for the new terminal.</p>
            <br/>
            <p>
            A half-kilometer-long boardwalk connects the two terminals to the new terminal. The Airports Authority of India (AAI) will construct a new technological block that will improve the effectiveness of aircraft operations and offer improved flight control.
            </p>
            <br/>
            <h2 className='text-center mt-4 mb-4'><u>Sardar Vallabhbhai Patel International Airport</u></h2>
            <br/>
            <p>
            The Sardar Vallabhbhai Patel International Airport provides service to the Indian twin towns of Ahmedabad and Gandhinagar. It is 9 km north of the centre of Ahmedabad in Hansol. Sardar Vallabhbhai Patel, India's first deputy prime minister and a respected leader in the country's independence struggle, was born in Gujarat. His name is given to this airport. In Gujarat, this airport is both the busiest and largest airport; it handled around 5.67 million passengers in fiscal year 2021–2022, ranking it as the eighth busiest airport in India in terms of passenger traffic. GoAir uses it as a focus city. The government began the process of privatising the airport in 2015; due to limitations on expansion within its current location, a new Dholera International Airport is being built above it—which will be operational by 2023 or 2024."
            </p>
        </div>
    </div>
    
    </>
  )
}

export default Ahmedabad