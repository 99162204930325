import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import '../styles/componentPage.css'

const Kolkata = () => {
  return (
    <>
       <Helmet> 
     <title>Best Indigo Careers in Kolkata | Bhartiya Aviation Services</title>
     <meta charSet='UTF-8'/>
       <meta name="keywords" content="Best Indigo Careers in Kolkata, Best Airline Jobs in Kolkata"/>
       <meta name="description" content="Are you looking for the best Airline Jobs in Kolkata? then your search end here because Bhartiya Aviation Services could help you to get a job in the airline industry."/>
       <meta name="author" content="Bhartiya Aviation Services"/>
       <meta name="refresh" content="50"/>
       <meta property="og:image" content="/public/favicon_package_v0.16/apple-touch-icon.png"/>
       <meta http-equi="refresh" content="" url="https://bhartiyaaviation.in/indigo-careers-and-airline-job-in-kolkata"/>
       <meta property="og:type" content="website"/>
<meta property="og:url" content="https://bhartiyaaviation.in/indigo-careers-and-airline-job-in-kolkata"/>
<meta property="og:title" content="Best Indigo Careers in Kolkata | Bhartiya Aviation Services"/>
<meta property="og:description" content="Are you looking for the best Airline Jobs in Kolkata? then your search end here because Bhartiya Aviation Services could help you to get a job in the airline industry."/>
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>


<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://bhartiyaaviation.in/indigo-careers-and-airline-job-in-kolkata"/>
<meta property="twitter:title" content="Best Indigo Careers in Kolkata | Bhartiya Aviation Services"/>
<meta property="twitter:description" content="Are you looking for the best Airline Jobs in Kolkata? then your search end here because Bhartiya Aviation Services could help you to get a job in the airline industry."/>
<meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bhartiyaaviationservices-3e814.appspot.com/o/logo%2Fandroid-chrome-192x192.png?alt=media&token=2267900e-8049-476a-9f96-dc660e7cc9fb"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon_package_v0.16/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon_package_v0.16/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon_package_v0.16/favicon-16x16.png"/>
    <link rel="shortcut icon" href="/public/favicon_package_v0.16/apple-touch-icon.png" type="image/x-icon"/>
    <link rel="apple-touch-icon" href="/public/logo192.png" />
       <link rel='canonical' href='https://bhartiyaaviation.in/indigo-careers-and-airline-job-in-kolkata' />
   </Helmet>

   <div className='container-fluid  top-banner-pg-div-about-us'>
         <div className='inner-pg-wrapper-div'>
             <div className='container ot-page-banner-heading'>
                 <h1>Kolkata</h1>
                 <Link to={'/'}>
                 <p>
                  Home/ kolkata
                  </p>
                 </Link>
             </div>
         </div>
     </div>

     <div className='container'>
        <div className='green-field-img-div'>
            <img src="/images/kolkata.png" alt="" />
        </div>
        <div className='green-field-text'>
            
            <h2 className='text-center mt-4 mb-4'><u>How was Netaji Subhas Chandra Bose International Airport formed in Kolkata?
</u></h2>

            <p>Early in the 20th century, Calcutta Airport underwent a transformation to become Netaji Subhas Chandra Bose International Airport. The airport has long been a vital strategic halt for flights from North America and Europe to Australia and Indochina. In 1924, KLM began making scheduled stops in Calcutta as part of its Amsterdam to Batavia (Jakarta) service. This was the first time an airline made a landing in India. A Royal Air Force aircraft made a landing in Calcutta that same year as part of an air force's first ever round-the-world flight.
The Dum Dum airport was a vacant plot of land near the Royal Artillery Armory when it opened in 1928. The Bengal Flying Club was established at the Dum Dum/Calcutta airport in February 1929 by Sir Stanley Jackson, the Governor of Bengal. Other airlines started using the airport in 1930 after the airstrip was upgraded to be usable year-round. Imperial Airways brought more airlines to the airport when it started flying from London to Australia through Calcutta in 1933. Additionally, Air Orient started planning stops along its Paris to Saigon route. Amelia Earhart's solo flight across the Atlantic in 1937 marked the first time a woman had ever done it, making it one of aviation's most prestigious feats.</p>

                <br/>

            <p>World War II saw a significant contribution from Calcutta. The 7th Bombardment Group of the United States Army Air Forces flew B-24 Liberator bombers on combat flights over Burma in 1942, departing from the airfield. The airfield served as both a communications hub for the Tenth Air Force and a cargo air terminal for Air Transport Command.</p>

            <br/>
            <p>Airport Netaji Subhas Chandra Bose International is located in Kolkata, West Bengal. It is roughly 15 kilometers from the city centre. where it is situated and serves as the aviation hub for much of eastern and northeastern India. The airport has formerly gone by the names Kolkata Airport and Dum Dum Airport locally. In 1995, Netaji Subhas Chandra Bose, a well-known figure in the Indian independence fight, was given his name as the new name of one of India's first airports—which opened its doors in 1924.</p>
            <br/>
            <p>
            West Bengal's Kolkata Airport, one of the two international airports in the state, is just over 1,600 acres in size and serves as India's busiest air traffic hub in the east. The other airport is Bagdogra. In the fiscal year 2017–18, it served almost 20 million people—ranking it the fifth busiest in India in terms of passenger traffic—behind airports in Delhi, Mumbai, Bangalore, and Hyderabad. Northeast India, Bangladesh, and Bhutan all have frequent flights out of this airport. It was named Best Improved Airport in Asia-Pacific in 2014 and 2015 by Airports Council International.
            </p>
            
        </div>
    </div>
    
    </>
  )
}

export default Kolkata