import React from 'react'
import { lazy, Suspense } from 'react';
//import CareerAtAirport from '../components/pages/CareerAtAirport'

const CareerAtAirport = lazy(()=> import('../components/pages/CareerAtAirport'))

const CareerAt = () => {
  return (
    <>
    <Suspense fallback={  <div className='text-center'>
    <div className="spinner-grow" style={{width: '3rem', height: '3rem', color: 'brown'}} role="status">
    <span className="visually-hidden text-success">Loading...</span>
    
  </div>
   </div>}>
   <CareerAtAirport/>
   </Suspense>
    </>
  )
}

export default CareerAt