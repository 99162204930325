import React, { useState } from 'react'
import axios from 'axios';

import '../styles/contact.css'

const ContactComp = () => {


    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMesssage] = useState("");
    const[resmsg, setResmsg] = useState(null);
    const [loading,setLoading] = useState(false)


    const fullName  =  `${name} ${lastName}`

    const timeOut = () =>{
        setTimeout(()=>{
            setResmsg(null) 
        },5000)
    }



    const contactForm = async(e)=>{

        e.preventDefault();            
                try{
                    setLoading(true)
                    const res = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/contactform`,{
                        name: fullName,
                        email,
                        phone,
                        message
                    })

                   // console.log(res.data);

                    if(res.data.status === 201){
                        setResmsg(res.data.message)
                        setName("");
                        setLastName("")
                        setEmail("")
                        setPhone("")
                        setMesssage("")
                        setLoading(false)
                        timeOut();
                    }
                }catch(err){
                   // console.log('conatact us Error: ', err.message);
                }
            }
  return (
        <>
        <div className='cont-comp-main-div'>
            <div className='container' >
                   <div className='cont-comp-title-div'>
                        <h3>Contact Form</h3>
                   </div>
                <div className='cont-comp-inner-div'>
                    <form onSubmit={contactForm}>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-sm-6'>
                                <div className='cont-comp-inner-form-el'>
                                        <label htmlFor="">First Name *</label>
                                        <input type="text" required  value={name}   onChange={(e)=> setName(e.target.value)}/>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                <div className='cont-comp-inner-form-el'>
                                        <label htmlFor="">Last Name *</label>
                                        <input type="text" required   value={lastName}   onChange={(e)=> setLastName(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6'>
                                <div className='cont-comp-inner-form-el'>
                                        <label htmlFor="">Mobile Number *</label>
                                        <input type="number" required  value={phone}   onChange={(e)=> setPhone(e.target.value) }/>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                <div className='cont-comp-inner-form-el'>
                                        <label htmlFor="">Email *</label>
                                        <input type="email" required   value={email}  onChange={(e)=> setEmail(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className='cont-comp-textarea-div'>
                                <label htmlFor="">What's Your Query</label>
                                <textarea name="" id="" cols="30" rows="6" required value={message}  onChange={(e)=> setMesssage(e.target.value)}></textarea>
                            </div>
                            <div className='contact-form-submit-btn'>
                                <button type='submit' className='btn btn-success'>Submit</button>
                                <br/>
                                <br/>
                                {loading ? (<>
                                    <div className="spinner-border text-danger" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </>): (<>
                                    <p className='p-3 text-success fw-bold'>{resmsg}</p>
                                </>)}
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
        </>
  )
}

export default ContactComp