

import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
    name: 'basAdminDetail',
    initialState: {
        basAdminDetail: JSON.parse(localStorage.getItem("basadmininfo")) || null,
    },

    reducers:{
        adminlogin(state, action) {
                state.basAdminDetail =  action.payload
                localStorage.setItem('basadmininfo',JSON.stringify(state.basAdminDetail))
        },
        adminlogoutuser(state, action) {
            localStorage.removeItem('basadmininfo');
            state.basAdminDetail =  null
        }
    }
});


export const {adminlogin, adminlogoutuser} = adminSlice.actions;

export default adminSlice.reducer;