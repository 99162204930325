import React from 'react'
import '../styles/mobilenav.css'
import { Link } from 'react-router-dom'
import {  useLocation } from 'react-router-dom';

import {MdExpandMore,MdKeyboardArrowRight, MdMenu, MdOutlineClose,MdOutlineWatchLater,MdAirplanemodeActive,MdErrorOutline,MdPhoneInTalk,MdOutlineHome } from "react-icons/md";
// eslint-disable-next-line
import { BiEdit,BiDownload,BiIdCard,BiUserVoice } from "react-icons/bi";
import {FaRegClipboard,FaAngleDown } from "react-icons/fa";


import { useState } from 'react';



const MobileNavbar = () => {

  const router = useLocation();

 // const [isOpen, setIsClose] = useState(false);
 // eslint-disable-next-line
  const [notificationUrl, setNotificationUrl] = useState({});
  const [isMobile, setIsMobile] = useState(false);
// eslint-disable-next-line
  const [careerAndJob, setCareerAndJob] = useState(false);
  // eslint-disable-next-line
  const [insideTheAviation, setInsideTheAviation] = useState(false);
  const [trainingAndEducation, setTrainingAndEducation] = useState(false);



  return (
    <>
      <div className='mob-nav-main-div'>


      <div className='main-menu-bg container-fluid d-print-none'>
          <div className='menu-bg container'>
    <ul className='menu-ul'>
      <li id='career-drop-hov'  className='menu-ul-list'>
      <Link className={router.pathname === '/careerjob' ? 'active' : ''}  to={'#'}> Career & Jobs <MdExpandMore/> </Link>
        <div className='career-drop-div'>
            <ul id='career-drop-ul'>
              <li className='career-drop-list'><Link to={'/groundstaff'}>Ground Staff</Link></li>
              <li className='career-drop-list'><Link to={'/airportdetails'}>Airport Details</Link></li>
              <li className='career-drop-list' id='arpt-serv-right-drop-list-id'>Airport Services <MdKeyboardArrowRight/>
                <div className='arpt-serv-right-drop-div'>
                    <ul className='arpt-serv-right-drop-ul'>
                      <li className='arpt-serv-right-drop-list'><Link to={'/housekeeping'}>House keeping</Link></li>
                      <li className='arpt-serv-right-drop-list'><Link to={'/portal'} >Portal</Link></li>
                      <li className='arpt-serv-right-drop-list'><Link to={'/wheelchairstaff'}>Wheel chair staff</Link></li>
                      <li className='arpt-serv-right-drop-list'><Link to={'/cafeteria'}>Cafeteria</Link></li>
                    </ul>
                </div>
              </li>
            </ul>
        </div>
      </li>
      <span className='verticle-line'></span>
      <li className='menu-ul-list' id='inside-aviation-hov-list'>
      <Link className={router.pathname === '/insidetheaviation' ? 'active' : ''} to={'#'}>Inside The Aviation <MdExpandMore/></Link>
           <div className='inside-aviation-drop-down-div'>
              <ul className='inside-aviation-drop-down-ul'>
                <li className='inside-aviation-drop-down-list'><Link to={'/eligibilityexamforaviation'}>Eligibility & entrance exam for aviation</Link></li>
                <li className='inside-aviation-drop-down-list'><Link to={'/becamecabincrew'}>Become cabin crew</Link></li>
                <li className='inside-aviation-drop-down-list'><Link to={'/becamegroundstaff'}>Became ground staff</Link></li>
              </ul>
           </div>
      </li>
      <span className='verticle-line'></span>
      <li className='menu-ul-list' id='training-edu-hov-list'>
      <Link className={router.pathname === '/whyaviation' ? 'active' : ''}  to={'#'}>Training & Education  <MdExpandMore/></Link>
            <div className='training-edu-drodown-div'>
                <ul className='training-edu-drodown-ul'>
                  <li className='training-edu-drodown-list'><Link to={'/trainingoverview'}>Training overiew</Link></li>
                  <li className='training-edu-drodown-list'><Link to={'/professionaltraining'}>Professional trainer</Link></li>
                  <li className='training-edu-drodown-list'><Link to={'/aptitudeskills'}>Aptitudes & skills</Link></li>
                  <li className='training-edu-drodown-list'><Link to={'/aviationaftertwelfth'}>Aviation training after 12th pass</Link></li>
                </ul>
            </div>
      </li>
      <span className='verticle-line'></span>
      <li className='menu-ul-list'>
      <Link className={router.pathname === '/selectionprocess' ? 'active' : ''}  to={'/selectionprocess'}> Selection Process</Link>
            
      </li>
      <span className='verticle-line'></span>
      <li className='menu-ul-list'>
      <Link className={router.pathname === '/careeratairport' ? 'active' : ''} to={'/careeratairport'}>Career At Airport</Link>
           
      </li>
      <span className='verticle-line'></span>
      <li className='menu-ul-list'>
      <Link className={router.pathname === '/about' ? 'active' : ''}  to={'/about'}>About</Link>
            
      </li>
      <span className='verticle-line'></span>
      
      <li className='menu-ul-list'>
      <Link className={router.pathname === '/contactus' ? 'active' : ''} to={'/contactus'}> Contact Us </Link>
      </li>
    </ul>
          </div>

                {/* Mobile View Navbar */}

              <div className='isMobile-div container' >
                <button onClick={()=> setIsMobile(!isMobile)}>
                  {isMobile ? (<MdOutlineClose/>) : (<MdMenu/>)}
                </button>

                  {isMobile ? (
                    <div className='mobile-menu-list-bg-div'>
                      <div className='mobile-download-menu-section'>
                        <div className='mobile-more-notifications'>
                          <ul className='mobile-more-notifications-ul'>
                          <li className='mobile-more-notifications-li'>
                            <div className='mobile-list-div-heading-list-div' onClick={()=>setIsMobile(false)}>
                            <MdOutlineHome/>
                            <Link to={'/'}>
                            <h6>Home</h6>
                            </Link>
                            </div>
                            </li>
                            {/* <li className='mobile-more-notifications-li'>
                           <div className='mobile-list-div-heading-list-div'>
                           <BiDownload/>
                            <h6>Download Notification</h6>
                           </div>
                              <div className='mobile-notification-drop-down'>
                                <ul>
                                 <a href={notificationUrl.notification?.url[0].notificationurlhindi}> Hindi</a>
                                 <a href={notificationUrl.notification?.url[0].notificationurlenglish}>English</a>
                                 
                                </ul>
                              </div>
                            </li> */}
                            <Link to={'/examfeecheck'}> 
                            <li className='mobile-more-notifications-li'  onClick={()=>setIsMobile(false)}>
                            <div className='mobile-list-div-heading-list-div'>
                            <BiEdit/>
                            <h6>Apply now</h6>
                            </div>
                            </li>
                            </Link>
                            <li className='mobile-more-notifications-li'>
                            <div className='mobile-list-div-heading-list-div'>
                            <BiIdCard/>
                            <h6>Admit card download</h6>
                            </div>
                            </li>
                            {/* <li className='mobile-more-notifications-li'>
                            <div className='mobile-list-div-heading-list-div'>
                            <BiUserVoice/>
                            <h6>Walk in interview</h6>
                            </div>
                            </li> */}
                            <li className='mobile-more-notifications-li'>
                            <div className='mobile-list-div-heading-list-div'>
                            <FaRegClipboard/>
                            <h6>Result</h6>
                            </div>
                            </li>
                            <Link to={'/aboutus'}>
                            <li className='mobile-more-notifications-li'  onClick={()=>setIsMobile(false)} >
                            <div className='mobile-list-div-heading-list-div'>
                            <MdErrorOutline/>
                            <h6 >About Us</h6>
                            </div>
                            </li>
                            </Link>
                            <Link to={'/services'}>
                            <li className='mobile-more-notifications-li' onClick={()=>setIsMobile(false)} >
                            <div className='mobile-list-div-heading-list-div'  >
                            <MdOutlineWatchLater/>
                            <h6 >Services</h6>
                            </div>
                            </li>
                            </Link>
                            <Link to={'/careeratairport'}>
                            <li className='mobile-more-notifications-li'  onClick={()=>setIsMobile(false)} >
                            <div className='mobile-list-div-heading-list-div'>
                            <MdAirplanemodeActive/>
                            <h6 >Career at airport</h6>
                            </div>
                            </li>
                            </Link>
                            {/* <li className='mobile-more-notifications-li'>
                            <div className='mobile-list-div-heading-list-div'>
                            <FaAngleDown/>
                            <h6 className={careerAndJob ? 'active' : null}  onClick={()=> setCareerAndJob(!careerAndJob)} >Career & Jobs</h6>
                            </div>
                            {
                              careerAndJob ? (
                                <div className='career-job-dropdown-div'>
                                  <ul className='career-job-dropdown-ul'>
                                  <Link to={'/groundstaff'}>
                                      <li className='career-job-dropdown-list' onClick={()=>setIsMobile(false)}  >Ground staff</li>
                                  </Link>
                                  <Link to={'/airportdetails'}>
                                      <li className='career-job-dropdown-list' onClick={()=>setIsMobile(false)}   >Airport detail</li>
                                  </Link>
                                  <Link to={'/housekeeping'}>
                                      <li className='career-job-dropdown-list' onClick={()=>setIsMobile(false)}   >House keeping</li>
                                  </Link>
                                  <Link to={'/portal'} >
                                      <li className='career-job-dropdown-list' onClick={()=>setIsMobile(false)}   >Portal</li>
                                  </Link>
                                  <Link to={'/wheelchairstaff'}>
                                      <li className='career-job-dropdown-list' onClick={()=>setIsMobile(false)}  >Wheel chair staff</li>
                                  </Link>
                                  <Link to={'/cafeteria'}>
                                      <li className='career-job-dropdown-list' onClick={()=>setIsMobile(false)}  >Cafetaria</li>
                                  </Link>
                                  </ul>
                              </div>
                              ) : (null)
                            }
                            </li>
                            <li className='mobile-more-notifications-li'>
                            <div className='mobile-list-div-heading-list-div'>
                            <FaAngleDown/>
                            <h6 className={insideTheAviation ? 'active' : null}  onClick={()=> setInsideTheAviation(!insideTheAviation)} >Inside The Aviation</h6>
                            </div>
                            {
                              insideTheAviation ? (
                                <div className='career-job-dropdown-div'>
                                  <ul className='career-job-dropdown-ul'>
                                  <Link to={'/eligibilityexamforaviation'}>
                                      <li className='career-job-dropdown-list' onClick={()=>setIsMobile(false)}  >Eligibility entrance exam</li>
                                  </Link>
                                  <Link to={'/becamecabincrew'}>
                                      <li className='career-job-dropdown-list' onClick={()=>setIsMobile(false)}  >Became cabin crew</li>
                                  </Link>
                                  <Link to={'/becamegroundstaff'}>
                                      <li className='career-job-dropdown-list' onClick={()=>setIsMobile(false)}  >Became ground staff</li>
                                  </Link>
                                  </ul>
                              </div>
                              ) : (null)
                            }
                            </li> */}
                            <li className='mobile-more-notifications-li'>
                            <div className='mobile-list-div-heading-list-div'>
                            <FaAngleDown/>
                            <h6 className={trainingAndEducation ? 'active' : null}  onClick={()=> setTrainingAndEducation(!trainingAndEducation)} >More Information</h6>
                            </div>
                            {
                              trainingAndEducation ? (
                                <div className='career-job-dropdown-div'>
                                  <ul className='career-job-dropdown-ul'>
                                  <Link to={'/visionvalue'}>
                                      <li className='career-job-dropdown-list' onClick={()=>setIsMobile(false)}  >Vision & Values</li>
                                  </Link>
                                  <Link to={'/whyaviation'}>
                                      <li className='career-job-dropdown-list' onClick={()=>setIsMobile(false)}  >Why Aviation???</li>
                                  </Link>
                                  <Link to={'/latestnewsupdate'}>
                                      <li className='career-job-dropdown-list' onClick={()=>setIsMobile(false)}  >Latest News Of All India Airport</li>
                                  </Link>
                                  <Link to={'/imporatntnotice'}>
                                      <li className='career-job-dropdown-list' onClick={()=>setIsMobile(false)}  >Important Notice Dis/Policy</li>
                                  </Link>
                                 
                                  </ul>
                              </div>
                              ) : (null)
                            }
                            </li>
                           

                           

                            

                            <Link to={'/contactus'}>
                            <li className='mobile-more-notifications-li'  onClick={()=>setIsMobile(false)} >
                            <div className='mobile-list-div-heading-list-div'>
                            <MdPhoneInTalk/>
                            <h6 >Contact us</h6>
                            </div>
                            </li>
                            </Link>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) :(null)}
              </div>
    </div>



      </div>
    </>
  )
}

export default MobileNavbar