import { Radio } from 'antd';
import Result from './Result'
import ResultLoader from './ResultLoader';

import React, { useState } from 'react'


const ResultWrapper = () => {

    const [value4, setValue4] = useState('loader');

      const onChange4 = ({ target: { value } }) => {
        setValue4(value);
      };

    const optionsWithDisabled = [
        {
          label: 'Ground Staff / CSA',
          value: 'csa',
        },
        {
          label: 'Loader / Housekeeping',
          value: 'loader',
        }
      ];
  return (
    <>
     <div className='container text-center my-5'>
     <Radio.Group
        options={optionsWithDisabled}
        onChange={onChange4}
        value={value4}
        optionType="button"
        buttonStyle="solid"
        className='d-print-none'
      />
     </div>
       <div>
        {
        value4 === 'loader' ?  <ResultLoader/> : <Result/>
        }
      </div>
    </>
  )
}

export default ResultWrapper