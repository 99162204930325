import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
          <div className='container-fluid footer-top-menubar d-print-none'>
            <div className='container footer-menu-list'>
              <ul>
               <Link to={'/careeratairport'}> <li>Career At Airport</li></Link>
                <span></span>
             <Link to={'/aboutus'}><li>About Us</li></Link>
                <span></span>
           <Link to={'/services'}><li>Services</li></Link>
                <span></span>
              <Link to={'/latestnewsupdate'}>  <li>Latest News</li></Link>
              </ul>
            </div>
          </div>

          <div className='container footer-details-main-div d-print-none'>
            <div className='row'>
              <div className='col-lg-2'>
                <div className='footer-logo-div '>
                <img src="/images/logo.jpeg" alt="" />
                </div>
              </div>
              <div className='col-lg-10'>
                <div className='footer-bottom-menus  '>
                    <ul>
                    
                      <li><Link to={'/'}>Home</Link></li>
                      <li><Link to={'/whyaviation'}>Why Aviation </Link></li>
                      <li> <Link to={'/visionvalue'}>Values </Link></li>
                      <li> <Link to={'/contactus'}>Contact Us  </Link></li>
                      <li> <Link to={'/contactus'}>Help  </Link></li>
                      <li> <Link to={'/imporatntnotice'}>Privacy/Refund/Cancellation Policy </Link></li>
                    </ul>
                </div>
                <div className='footer-copy-right-div'>
                  <p id='footer-cpy'>Copyright &copy; {new Date().getFullYear()} All Rights Reserved <br/>BHARTIYA AVIATION SERVICES
                  </p>
                  {/* <a href="//www.dmca.com/Protection/Status.aspx?ID=6d0e3d97-2833-4670-b149-2cc10059aedf" title="DMCA.com Protection Status" className="dmca-badge"> <img src ="https://images.dmca.com/Badges/dmca_protected_sml_120m.png?ID=6d0e3d97-2833-4670-b149-2cc10059aedf"  alt="DMCA.com Protection Status" /></a> */}
                </div>
                <div className='footer-bottom-line'>
        
                </div>
              </div>
            </div>
          </div>
    </>
  )
}

export default Footer