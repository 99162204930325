import React from 'react'
import { lazy, Suspense } from 'react';

//import ServiceComp from '../components/pages/ServiceComp'

const ServiceComp = lazy(()=> import('../components/pages/ServiceComp'))

const Service = () => {
  return (
   <>
    <Suspense fallback={ <div className='text-center'>
    <div className="spinner-grow" style={{width: '3rem', height: '3rem', color: 'brown'}} role="status">
    <span className="visually-hidden text-success">Loading...</span>
    
  </div>
   </div>}>
   <ServiceComp/>
   </Suspense>
   </>
  )
}

export default Service